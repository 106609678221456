import { groupBy, uniq } from 'lodash-es'

export const departments = (people) =>
  uniq(
    people
      ?.filter((item) => item?.employeeData?.department)
      .map((item) => item?.employeeData?.department)
  )

export const groupByDepartment = (people) => {
  return groupBy(people, (emp) => {
    return emp?.employeeData?.department ? emp?.employeeData?.department : 'noDepartment'
  })
}

/**
 * returns an array of unique location names
 */
export const locations = (people) =>
  uniq(people?.filter((item) => item?.officeLocation).map((item) => item?.officeLocation))

/**
 * returns people grouped by location
 */
export const groupByLocation = (people) => {
  return groupBy(people, (emp) => {
    return emp?.officeLocation ? emp?.officeLocation : 'noLocation'
  })
}

/**
 * returns an object with department names as keys and the number of people in each department as values
 * sorted from most to least people per dept
 */
export const departmentsWithCounts = (peopleInSubtree) => {
  const departments = {}

  peopleInSubtree?.forEach((person) => {
    if (person?.employeeData?.department) {
      if (departments[person.employeeData.department]) {
        departments[person.employeeData.department] += 1
      } else {
        departments[person.employeeData.department] = 1
      }
    }
  })

  const sortedDepts = []

  Object.keys(departments).forEach((dKey) => {
    sortedDepts.push([dKey, departments[dKey]])
  })

  sortedDepts.sort((a, b) => b[1] - a[1])

  return Object.fromEntries(sortedDepts)
}

/**
 * returns an object with role names as keys and the number of people with that role as values
 * sorted from most to least people per role
 */
export const rolesWithCounts = (peopleInSubtree) => {
  const roles = {}

  peopleInSubtree?.forEach((person) => {
    if (person?.role) {
      if (roles[person.role]) {
        roles[person.role] += 1
      } else {
        roles[person.role] = 1
      }
    }
  })

  const sortedRoles = []

  Object.keys(roles).forEach((dKey) => {
    sortedRoles.push([dKey, roles[dKey]])
  })

  sortedRoles.sort((a, b) => b[1] - a[1])

  return Object.fromEntries(sortedRoles)
}
