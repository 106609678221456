import {
  addSubordinate,
  applyEditedState,
  applyPersonMoved,
  ensureMetaProperties,
  fetchPeopleHelper,
  fixedPeopleDataHelper,
  getNewCompensation,
  getNewPerson,
  isBackfill,
  isBackfilled,
  isCEO,
  isEdited,
  isMoved,
  isNewlyAdded,
  isRemoved,
  isRif,
  isRolePlanningGoal,
  isRolePlanningNode,
  markAsRemoved,
  newRoleChangeRecord,
  validatePeopleData
} from '@/lib/PersonDataProcessing'
import PersonPlanStates from '@/lib/PersonPlanStates'
import { getUsers } from '@/services/firebase-functions.service.js'
import { batchWrapper } from '@/utils/firebaseHelper.js'
import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import { arrayRemove, arrayUnion } from 'firebase/firestore'
import { each, find, groupBy, keyBy, matches, merge, remove, union, xor } from 'lodash-es'
import moment from 'moment'
import {
  departments,
  groupByDepartment,
  locations,
  groupByLocation
} from '@/services/people-processing.js'
import { bus } from '@/services/errorbus.service.js'

//each key is an object indexed by boardId
const getDefaultState = () => {
  return {
    people: {},
    peopleById: {},
    ownerInfos: {},
    isFetchingPeople: {},
    // collection of people without applying any access level restrictions
    allPeople: {}
  }
}

const M = {
  SET_OWNER_INFOS: 'setOwnerInfos',
  SET_PERSON: 'setPerson',
  SET_PEOPLE_DATA: 'setPeopleData',
  SET_ALL_PEOPLE_DATA: 'setAllPeopleData',
  REMOVE_PERSON: 'removePerson',
  UPDATE_PERSON: 'updatePerson',
  SET_IS_FETCHING_PEOPLE: 'setIsFetchingPeople',
  RESET: 'reset'
}

const state = getDefaultState()

const mutations = {
  [M.SET_OWNER_INFOS](_state, { boardId, ownerInfos }) {
    _state.ownerInfos[boardId] = ownerInfos
  },
  [M.SET_PERSON](_state, { boardId, person }) {
    remove(_state.people[boardId], (p) => p.personId === person.personId)

    if (!_state.people[boardId]) _state.people[boardId] = []
    if (!_state.peopleById[boardId]) _state.peopleById[boardId] = {}

    _state.people[boardId].push(person)
    _state.peopleById[boardId][person.personId] = person
  },
  [M.SET_PEOPLE_DATA](_state, { boardId, people }) {
    _state.people[boardId] = validatePeopleData(people)
    _state.peopleById[boardId] = keyBy(_state.people[boardId], 'personId')
  },
  [M.SET_ALL_PEOPLE_DATA](_state, { boardId, people }) {
    _state.allPeople[boardId] = validatePeopleData(people)
  },
  [M.REMOVE_PERSON](_state, { boardId, personId }) {
    // Go through everyone and make sure this person is removed from managers and subordinates list.
    each(_state.people[boardId], (person) => {
      remove(person.managers, (managerId) => managerId === personId)
      remove(person?.subordinates || [], (subordinateId) => subordinateId === personId)
    })

    // Remove this person from the _state
    remove(_state.people[boardId], (person) => person.personId === personId)

    delete _state.peopleById[boardId][personId]

    _state.people[boardId] = validatePeopleData(
      _state.people[boardId].filter((person) => person.personId !== personId)
    )
  },
  [M.UPDATE_PERSON](_state, { boardId, person }) {
    const newPeopleData = [..._state.people[boardId]]

    const personIndex = newPeopleData.findIndex((p) => p.personId === person.personId)
    newPeopleData[personIndex] = person

    _state.people[boardId] = validatePeopleData(newPeopleData)
    _state.peopleById[boardId] = keyBy(_state.people[boardId], 'personId')
  },
  [M.SET_IS_FETCHING_PEOPLE](_state, { boardId, status }) {
    _state.isFetchingPeople[boardId] = status
  },
  [M.RESET](_state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(_state, getDefaultState())
  }
}

const getters = {
  people: (_state) => (boardId) => _state.people[boardId] || [],
  allPeople: (_state) => (boardId) => _state.allPeople[boardId] || [],

  activeEmployees: (_state) => (boardId) =>
    _state.people[boardId]?.filter((person) => !isRemoved(person) && !isRolePlanningNode(person)) ||
    [],

  allActiveEmployees: (_state) => (boardId) =>
    _state.allPeople[boardId]?.filter(
      (person) => !isRemoved(person) && !isRolePlanningNode(person)
    ) || [],

  rolePlanningNodes: (_state) => (boardId) =>
    _state.people[boardId]?.filter((person) => !isRemoved(person) && isRolePlanningNode(person)) ||
    [],

  rolePlanningGoals: (_state, _getters) => (boardId) =>
    _getters.rolePlanningNodes(boardId)?.filter((node) => isRolePlanningGoal(node)) || [],

  rolesInGoal:
    (_state) =>
    ({ boardId, goalId }) =>
      _state.people[boardId]?.filter((person) => person.managers.includes(goalId)) || [],

  goalsForPerson:
    (_state, _getters) =>
    ({ boardId, personId }) =>
      _getters.rolePlanningGoals(boardId).flatMap((goal) => {
        if (goal.rolePlanning?.ownerId === personId) {
          return [goal]
        }

        const roles = _getters.rolesInGoal({ boardId, goalId: goal.personId })
        if (roles.some((role) => role.rolePlanning?.ownerId === personId)) {
          return [goal]
        }

        return []
      }),

  departmentList: (_, _getters) => (boardId) => departments(_getters.activeEmployees(boardId)),

  locationList: (_, _getters) => (boardId) => locations(_getters.activeEmployees(boardId)),

  groupPeopleByDepartment: (_, _getters) => (boardId) =>
    groupByDepartment(_getters.people(boardId)),

  groupPeopleByLocation: (_, _getters) => (boardId) => groupByLocation(_getters.people(boardId)),

  groupPeopleByManager: (_state, _getters) => (boardId) =>
    groupBy(_getters.activeEmployees(boardId), (emp) =>
      emp.managers[0] ? emp.managers[0] : 'noManager'
    ),

  isFetchingPeople: (_state) => (boardId) => _state.isFetchingPeople[boardId] ?? false,

  reportsTo:
    (_state, _getters) =>
    ({ boardId, personId }) => {
      const peopleByManager = _getters.groupPeopleByManager(boardId) || {}
      return peopleByManager[personId] || []
    },

  getCEO: (_state) => (boardId) => _state.people[boardId].find(isCEO),

  getRoots: (_state) => (boardId) => _state.people[boardId].filter(isCEO),

  teamAndManagerInSubtree:
    (_state, _getters) =>
    ({ boardId, personId }) => {
      const peopleInSubtree = _getters.everyoneInSubtree({ boardId, personId }) || []
      const peopleObj = peopleInSubtree.map((pId) =>
        _getters.personById({ boardId, personId: pId })
      )

      return [...peopleObj, _getters.personById({ boardId, personId })]
    },

  teamByManager:
    (_state, _getters) =>
    ({ boardId, managerId }) => {
      const peopleByManager = _getters.groupPeopleByManager(boardId) || {}
      return peopleByManager[managerId] || []
    },

  personById:
    (_state) =>
    ({ boardId, personId }) =>
      _state.peopleById[boardId]?.[personId] || null,

  peopleById: (_state) => (boardId) => _state.peopleById[boardId] || {},

  backfilledByWho:
    (_state) =>
    ({ boardId, personId }) =>
      _state.people[boardId].find((person) => {
        return (
          isNewlyAdded(person) &&
          isBackfill(person) &&
          !isRemoved(person) &&
          person.scenarioMetaData?.forWho === personId
        )
      }),

  backfillForWho:
    (_state) =>
    ({ boardId, personId }) => {
      return _state.people[boardId].find(
        (person) =>
          person.scenarioMetaData?.byWho === personId && isBackfilled(person) && !isRemoved(person)
      )
    },

  //TODO: change 'inScenario'
  addedInScenario: (_state, _getters) => (boardId) =>
    _getters.activeEmployees(boardId).filter(isNewlyAdded) || [],

  removed: (_state, _getters) => (boardId) => _getters.people(boardId).filter(isRemoved) || [],

  markedAsRIF: (_state, _getters) => (boardId) => _getters.people(boardId).filter(isRif) || [],

  changedInScenario: (_state, _getters) => (boardId) =>
    _getters.activeEmployees(boardId).filter((person) => isMoved(person) || isEdited(person)) || [],

  editedInScenario: (_state, _getters) => (boardId) =>
    _getters
      .activeEmployees(boardId)
      .filter((person) => isEdited(person) && person.employeeStatus !== 'Terminated') || [],

  customFields: (_, _getters) => (boardId) => {
    const res = new Set([])

    _getters
      .activeEmployees(boardId)
      ?.forEach((person) =>
        person.customFields
          ?.map((customField) => Object.keys(customField))
          .forEach((customFieldKey) => res.add(...customFieldKey))
      )

    return res
  },

  customFieldsValues: (_, _getters) => (boardId) => {
    const res = new Set([])

    _getters
      .activeEmployees(boardId)
      ?.forEach((person) =>
        person.customFields
          ?.map((customField) => Object.values(customField))
          .forEach((customFieldKey) => res.add(...customFieldKey))
      )

    return res
  },

  locations: (_, _getters) => (boardId) => {
    const res = new Set(
      _getters
        .activeEmployees(boardId)
        .flatMap((person) => [person.officeLocation ? person.officeLocation : null])
    )

    return [...res]
  },

  executives: (_, _getters) => (boardId) => {
    const ceo = _getters.getCEO(boardId)

    return _getters.reportsTo({ boardId, personId: ceo.personId }).map((p) => p.personId)
  },

  managersInSubtree:
    (_, _getters) =>
    ({ boardId, personId }) => {
      const person = _getters.personById({ boardId, personId })
      const subordinates = person?.subordinates ? person?.subordinates : []
      const managers = []

      subordinates.forEach((subordinateId) => {
        const subordinatePerson = _getters.personById({ boardId, personId: subordinateId })
        if (subordinatePerson.subordinates.length > 0) {
          managers.push(subordinatePerson.personId)
          managers.push(..._getters.managersInSubtree({ boardId, personId: subordinateId }))
        }
      })

      return managers
    },

  individualContributorsInSubtree:
    (_, _getters) =>
    ({ boardId, personId }) => {
      const person = _getters.personById({ boardId, personId })
      const subordinates = person?.subordinates ? person?.subordinates : []
      const individualContributors = []

      subordinates.forEach((subordinateId) => {
        const subordinatePerson = _getters.personById({ boardId, personId: subordinateId })

        if (subordinatePerson.subordinates.length === 0) {
          individualContributors.push(subordinateId)
        } else {
          individualContributors.push(
            ..._getters.individualContributorsInSubtree({
              boardId,
              personId: subordinateId
            })
          )
        }
      })

      return individualContributors
    },

  everyoneInSubtree:
    (_, _getters) =>
    ({ boardId, personId }) => {
      const person = _getters.personById({ boardId, personId })
      const subordinates = person?.subordinates || []
      const everyone = []

      subordinates.forEach((subordinateId) => {
        everyone.push(subordinateId)
        everyone.push(..._getters.everyoneInSubtree({ boardId, personId: subordinateId }))
      })

      return everyone
    },

  ownerInfos: (_state) => (boardId) => _state.ownerInfos[boardId]
}

const actions = {
  //TODO: remove from vuex (no dependency/effect on state)
  async fetchPersonByEmail(context, { boardId, email }) {
    try {
      const person = await firebase
        .firestore()
        .collection('people')
        .where('boardId', '==', boardId)
        .where('email', '==', email.toLowerCase())
        .limit(1)
        .get()

      if (person.docs.length > 0) return person.docs[0].data()
      return null
    } catch (e) {
      console.error('fetchPersonByEmail', e)
      Sentry?.captureException(e)
    }
  },

  /**
   * Fetch people for given boardId
   * @param boardId
   * @param force - if true, fetches people data from firestore even if it is already fetched
   * @param background - if true, doesn't set "loading" flag
   */
  async fetch({ commit, getters }, { boardId, force = true, background = false }) {
    try {
      if (!force && getters.people(boardId)?.length > 0) return false

      if (!background) commit(M.SET_IS_FETCHING_PEOPLE, { boardId, status: true })

      const peopleData = await fetchPeopleHelper({ boardId, restriction: true })
      const fixedPeopleData = await fixedPeopleDataHelper({ peopleData })

      commit(M.SET_PEOPLE_DATA, {
        boardId,
        people: fixedPeopleData
      })

      return true
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
      return false
    } finally {
      commit(M.SET_IS_FETCHING_PEOPLE, { boardId, status: false })
    }
  },

  /**
   * Fetch people for given boardId without access level restriction
   * @param boardId
   * @param force - if true, fetches people data from firestore even if it is already fetched
   * @param background - if true, doesn't set "loading" flag
   */
  async fetchWithoutRestriction({ commit, getters }, { boardId, force = true, background = true }) {
    try {
      if (!force && getters.allPeople(boardId)?.length > 0) return false

      if (!background) commit(M.SET_IS_FETCHING_PEOPLE, { boardId, status: true })

      const allPeopleData = await fetchPeopleHelper({ boardId, restriction: false })
      const allfixedPeopleData = await fixedPeopleDataHelper({ peopleData: allPeopleData })

      commit(M.SET_ALL_PEOPLE_DATA, {
        boardId,
        people: allfixedPeopleData
      })

      return true
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
      return false
    } finally {
      commit(M.SET_IS_FETCHING_PEOPLE, { boardId, status: false })
    }
  },

  async updatePersonInfo({ commit, getters }, { personObj, boardId, updatedBy }) {
    if (personObj.isNoManagerNode) return // Don't do anything. This is a virtual node.

    const originPersonObj = getters.personById({ boardId, personId: personObj.personId })
    const personId = personObj.personId

    personObj.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
    personObj.email = personObj.email ? personObj.email.trim() : ''
    personObj.updatedBy = updatedBy

    const updatedPersonObject = applyEditedState({
      oldPersonObject: originPersonObj,
      updatedPersonObject: ensureMetaProperties(personObj)
    })

    if (updatedPersonObject?.role !== originPersonObj?.role) {
      updatedPersonObject.scenarioMetaData.changes.push(
        newRoleChangeRecord(originPersonObj?.role, updatedPersonObject?.role)
      )
    }

    commit(M.UPDATE_PERSON, { boardId, person: updatedPersonObject })

    try {
      await firebase
        .firestore()
        .collection('people')
        .doc(`${boardId}_${personId}`)
        .set(updatedPersonObject, { merge: true })

      const personFirestore = await firebase
        .firestore()
        .collection('people')
        .doc(`${boardId}_${personId}`)
        .get()

      commit(M.UPDATE_PERSON, { boardId, person: personFirestore.data() })
    } catch (e) {
      bus.emit({ type: 'sync', message: e })
    }
  },

  //As opposed to updatePersonInfo, this function is used to patch the person object without any additional logic
  async patchPerson({ dispatch }, { boardId, personId, patch }) {
    dispatch('patchPeople', { boardId, patches: [{ personId, patch }] })
  },

  async patchPeople({ commit, getters, rootGetters }, { boardId, patches }) {
    const refs = []
    const dataToSet = []

    patches.forEach(({ personId, patch }) => {
      const person = getters.personById({ boardId, personId })
      if (!person) return

      if (patch.customFields) {
        patch.customFields.forEach((field) => {
          try {
            const key = Object.keys(field)[0]
            const fieldIndex = person.customFields?.findLastIndex((existingField) =>
              Object.keys(existingField).includes(key)
            )

            if (!person.customFields) person.customFields = []

            if (fieldIndex === -1) {
              person.customFields.push(field)
            } else {
              person.customFields[fieldIndex][key] = field[key]
            }
          } catch (e) {
            console.error(e, person, patch)
          }
        })

        delete patch.customFields
      }

      if (matches(patch)(person)) {
        return
      }

      const updatedPerson = merge(person, patch, {
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: rootGetters.uid
      })

      commit(M.UPDATE_PERSON, { boardId, person: updatedPerson })

      const personRef = firebase
        .firestore()
        .collection('people')
        .doc(`${updatedPerson.boardId}_${personId}`)

      refs.push(personRef)
      dataToSet.push(updatedPerson)

      //person object gets added to vuex with createdAt: serverTimestamp() function
      //until the people-fetch is called this value is persisted in the store
      //because of that all patch calls on top of the newly added person regenerate createdAt value
      //when submitted to the server
    })

    try {
      await batchWrapper(refs, 'set', dataToSet)
    } catch (e) {
      bus.emit({ type: 'sync', message: e })
    }

    //fetch new updates from the server
    refs.forEach((ref) => {
      ref.get().then((doc) => {
        commit(M.UPDATE_PERSON, { boardId, person: doc.data() })
      })
    })
  },

  async updateManager({ commit, getters }, { personId, boardId, newManagerId }) {
    const originPersonObj = {
      ...getters.personById({ boardId, personId }),
      managers: [newManagerId]
    }

    const newManager = getters.personById({ boardId, personId: newManagerId }) || {}
    const oldManager =
      find(getters.people(boardId), (person) => person?.subordinates?.includes(personId)) || {}

    if (oldManager) {
      oldManager.subordinates = xor(oldManager.subordinates, [personId])
      commit(M.UPDATE_PERSON, { boardId, person: oldManager })
    }

    newManager.subordinates = union(newManager.subordinates, [personId])

    commit(M.UPDATE_PERSON, { boardId, person: newManager })
    commit(M.UPDATE_PERSON, { boardId, person: originPersonObj })

    const db = firebase.firestore()
    const personDocRef = firebase.firestore().collection('people').doc(`${boardId}_${personId}`)

    await db
      .runTransaction(async (transaction) => {
        transaction.update(personDocRef, originPersonObj)

        if (newManagerId) {
          // Query everyone that has this person as a subordinate
          const querySnapshots = await firebase
            .firestore()
            .collection('people')
            .where('boardId', '==', boardId)
            .where('subordinates', 'array-contains', personId)
            .get()

          querySnapshots.forEach((q) => {
            // There are other managers that have the current
            // person as subordinate. Remove it.
            const previousManagerRef = firebase
              .firestore()
              .collection('people')
              .doc(`${boardId}_${q.data().personId}`)

            transaction.update(previousManagerRef, {
              subordinates: arrayRemove(personId)
            })
          })

          // Finally, update the new manager
          const newManagerRef = firebase
            .firestore()
            .collection('people')
            .doc(`${boardId}_${newManagerId}`)

          transaction.update(newManagerRef, {
            subordinates: arrayUnion(personId)
          })
        }
      })
      .catch((error) => {
        console.log('Transaction failed: ', error)
        //TODO: since changes are now applied locally, suggest refreshing the page with a toast message
      })
  },

  async updatePersonInfoAndManager(
    { commit, getters },
    { personObj, boardId, newManagerId, updatedBy }
  ) {
    if (!personObj) return
    if (personObj.isNoManagerNode) return // Don't do anything. This is a virtual node.

    const personId = personObj.personId

    const movedPerson = applyPersonMoved({
      person: personObj,
      updatedBy,
      newManagerId
    })

    const newManager = getters.personById({ boardId, personId: newManagerId }) || {}

    const oldManager =
      find(getters.people(boardId), (person) => person?.subordinates?.includes(personId)) || {}

    if (oldManager) {
      oldManager.subordinates = xor(oldManager.subordinates, [personId])
      commit(M.UPDATE_PERSON, { boardId, person: oldManager })
    }

    newManager.subordinates = union(newManager.subordinates, [personId])

    commit(M.UPDATE_PERSON, { boardId, person: newManager })
    commit(M.UPDATE_PERSON, { boardId, person: movedPerson })

    const db = firebase.firestore()
    const personDocRef = firebase.firestore().collection('people').doc(`${boardId}_${personId}`)

    await db
      .runTransaction(async (transaction) => {
        transaction.update(personDocRef, movedPerson)

        if (newManagerId) {
          // Query everyone that has this person as a subordinate
          const querySnapshots = await firebase
            .firestore()
            .collection('people')
            .where('boardId', '==', boardId)
            .where('subordinates', 'array-contains', personId)
            .get()

          querySnapshots.forEach((q) => {
            // There are other managers that have the current
            // person as subordinate. Remove it.
            const previousManagerRef = firebase
              .firestore()
              .collection('people')
              .doc(`${boardId}_${q.data().personId}`)

            transaction.update(previousManagerRef, {
              subordinates: arrayRemove(personId)
            })
          })

          // Finally, update the new manager
          const newManagerRef = firebase
            .firestore()
            .collection('people')
            .doc(`${boardId}_${newManagerId}`)

          transaction.update(newManagerRef, {
            subordinates: arrayUnion(personId)
          })
        }
      })
      .catch((error) => {
        bus.emit({ type: 'sync', message: error })
      })
  },

  //TODO: move out of vuex, no dependency or effect on the state
  addPerson(context, { personObj, boardId, createdBy }) {
    if (!boardId) throw new Error('BoardId is undefined')

    if (personObj.isNoManagerNode) return // Don't do anything. This is a virtual node.

    personObj.boardId = boardId
    personObj.createdBy = createdBy
    personObj.updatedBy = createdBy
    personObj.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    personObj.updatedAt = firebase.firestore.FieldValue.serverTimestamp()

    try {
      return firebase
        .firestore()
        .collection('people')
        .doc(`${boardId}_${personObj.personId}`)
        .set(personObj)
    } catch (e) {
      console.error('Error adding new person: ', e)
      console.error(personObj)

      if (Sentry) Sentry.captureException(e)
    }
  },

  //TODO: move out of vuex, no dependency or effect on the state
  async addPersonToManager(context, { personId, managerPersonId, boardId }) {
    if (!boardId) throw new Error('BoardId is undefined')

    try {
      const db = firebase.firestore()
      return await db
        .runTransaction(async (transaction) => {
          // First, query everyone in the board that's manager
          // of the personId
          const querySnapshots = await firebase
            .firestore()
            .collection('people')
            .where('boardId', '==', boardId)
            .where('subordinates', 'array-contains', personId)
            .get()

          querySnapshots.forEach((q) => {
            // There are other managers that have the current
            // person as subordinate. Remove it.
            // Since we do not allow multiple reporting lines
            const previousManagerRef = firebase
              .firestore()
              .collection('people')
              .doc(`${boardId}_${q.data().personId}`)

            transaction.update(previousManagerRef, {
              subordinates: arrayRemove(personId)
            })
          })

          // Now, add the person to the target manager
          const managerRef = firebase
            .firestore()
            .collection('people')
            .doc(`${boardId}_${managerPersonId}`)

          // Now, add the person to the target manager
          const personRef = firebase.firestore().collection('people').doc(`${boardId}_${personId}`)

          transaction.update(managerRef, {
            subordinates: arrayUnion(personId)
          })

          transaction.update(personRef, {
            managers: [managerPersonId]
          })
        })
        .catch((e) => {
          if (Sentry) Sentry.captureException(e)
          bus.emit({ type: 'sync', message: e })
        })
    } catch (e) {
      if (Sentry) Sentry.captureException(e)
      bus.emit({ type: 'sync', message: e })
    }
  },

  //TODO: move out of vuex, no dependency or effect on the state
  async deleteProfile(context, { personId, boardId }) {
    try {
      await firebase.firestore().collection('people').doc(`${boardId}_${personId}`).delete()
    } catch (e) {
      window.console.error('Error deleting person: ', e, { personId, boardId })
      if (Sentry) Sentry.captureException(e)
    }

    try {
      // Remove this person from its manager, if any
      const manager = find(context.state.people, (person) => {
        return person?.subordinates?.includes(personId)
      })

      if (manager && !manager.isNoManagerNode) {
        await firebase
          .firestore()
          .collection('people')
          .doc(`${boardId}_${manager.personId}`)
          .update({
            subordinates: firebase.firestore.FieldValue.arrayRemove(personId)
          })
      }
    } catch (e) {
      window.console.error('Error removing person from manager: ', e, {
        personId,
        boardId
      })
      if (Sentry) Sentry.captureException(e)
    }
  },

  async addOpenRoleWithCompensation(
    context,
    {
      newPersonId,
      managerObj,
      boardId,
      createdBy,
      updatedBy,
      role,
      status,
      name = '',
      officeLocation = '',
      compensation,
      subordinates = [],
      scenarioMetaData = {},
      department = '',
      type = null,
      rolePlanning = null,
      sourceEmployeeId = null,
      startDate = null,
      terminationDate = null,
      email = ''
    }
  ) {
    if (!boardId) throw new Error('BoardId is undefined')

    const newPersonObj = getNewPerson({
      boardId,
      newPersonId,
      role,
      name,
      createdBy,
      updatedBy,
      status,
      managerObj,
      officeLocation,
      subordinates,
      scenarioMetaData,
      department,
      type,
      rolePlanning,
      sourceEmployeeId,
      startDate,
      terminationDate,
      email
    })

    context.commit(M.SET_PERSON, { boardId, person: newPersonObj })
    context.dispatch(
      'addCompensation',
      { personId: newPersonId, boardId, ...compensation },
      { root: true }
    )

    addSubordinate({ managerObj, subordinateId: newPersonId })

    // TODO this has to be a transaction instead of a chain request
    try {
      // eslint-disable-next-line no-unreachable
      await firebase
        .firestore()
        .collection('people')
        .doc(`${boardId}_${newPersonId}`)
        .set(newPersonObj)

      if (managerObj && !managerObj.isNoManagerNode) {
        await firebase
          .firestore()
          .collection('people')
          .doc(`${boardId}_${managerObj.personId}`)
          .update({
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            subordinates: managerObj?.subordinates || []
          })
        // TODO: set person for manager too?
        // only set person for manager when managerObject is defined!
        context.commit(M.UPDATE_PERSON, { boardId, person: managerObj })
      }
    } catch (e) {
      Sentry?.captureException(e)
      bus.emit({ type: 'sync', message: e })
    }
  },

  async addOpenRole(
    context,
    {
      newPersonId,
      managerObj,
      boardId,
      createdBy,
      updatedBy,
      role,
      status,
      name = '',
      officeLocation = '',
      subordinates = [],
      scenarioMetaData = {},
      department = '',
      type = null,
      rolePlanning = null,
      sourceEmployeeId = null,
      startDate = null,
      terminationDate = null,
      email = ''
    }
  ) {
    await context.dispatch('addOpenRoleWithCompensation', {
      newPersonId,
      managerObj,
      boardId,
      createdBy,
      updatedBy,
      role,
      status,
      name,
      officeLocation,
      subordinates,
      department,
      scenarioMetaData,
      compensation: getNewCompensation({
        personId: newPersonId,
        boardId,
        defaultCurrency: context.rootGetters.preferredBaseCurrency
      }),
      type,
      rolePlanning,
      sourceEmployeeId,
      startDate,
      terminationDate,
      email
    })
  },

  async removePersonSimple(context, { boardId, personId }) {
    context.commit(M.REMOVE_PERSON, { boardId, personId })

    await firebase
      .firestore()
      .runTransaction((transaction) => {
        const toBeDeletedRef = firebase
          .firestore()
          .collection('people')
          .doc(`${boardId}_${personId}`)

        return transaction.get(toBeDeletedRef).then(async (refDoc) => {
          if (!refDoc.exists) {
            throw new Error('Document does not exist')
          }

          const person = markAsRemoved({
            doc: refDoc.data(),
            updatedBy: context.rootGetters.uid
          })

          const manager = context.getters.personById({
            boardId,
            personId: person?.managers?.[0]
          })

          if (manager && !manager.isNoManagerNode) {
            const managerRef = firebase
              .firestore()
              .collection('people')
              .doc(`${boardId}_${manager.personId}`)

            const managerRefDoc = await transaction.get(managerRef)
            const managerDoc = managerRefDoc.data()

            const subordinates = managerDoc?.subordinates || []
            remove(subordinates, (subordinate) => subordinate === personId)

            await transaction.update(managerRef, {
              subordinates,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp()
            })
          }

          await transaction.update(refDoc.ref, person)
        })
      })
      .catch((error) => {
        window.console.error('Error writing document: ', error)
        if (Sentry) Sentry.captureException(error)
        bus.emit({ type: 'sync', message: error })
      })
  },

  async removePerson(context, { managerObj, removedPersonId, boardId, updatedBy }) {
    await firebase
      .firestore()
      .runTransaction((transaction) => {
        const toBeDeletedRef = firebase
          .firestore()
          .collection('people')
          .doc(`${boardId}_${removedPersonId}`)

        return transaction.get(toBeDeletedRef).then(async (refDoc) => {
          if (!refDoc.exists) {
            throw new Error('Document does not exist')
          }

          const doc = markAsRemoved({ doc: refDoc.data(), updatedBy })
          context.commit(M.UPDATE_PERSON, { boardId, person: doc })

          if (managerObj && !managerObj.isNoManagerNode) {
            const managerRef = firebase
              .firestore()
              .collection('people')
              .doc(`${boardId}_${managerObj.personId}`)

            const managerRefDoc = await transaction.get(managerRef)
            const managerDoc = managerRefDoc.data()

            const subordinates = managerDoc?.subordinates || []
            remove(subordinates, (subordinate) => subordinate === removedPersonId)

            await transaction.update(managerRef, {
              subordinates,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp()
            })
          }
          await transaction.update(refDoc.ref, doc)
        })
      })
      .then(() => {
        context.commit(M.REMOVE_PERSON, { boardId, personId: removedPersonId })
      })
      .catch((error) => {
        window.console.error('Error writing document: ', error)
        if (Sentry) Sentry.captureException(error)
        bus.emit({ type: 'sync', message: error })
      })
  },

  /**
   * This is a simplified implementation that marks selected people as removed recursively, without removing the hierarchy data
   */
  async removePeople(context, { managerId, peopleIds, boardId, updatedBy }) {
    const batch = firebase.firestore().batch()

    const peopleRefs = peopleIds.map((personId) =>
      firebase.firestore().collection('people').doc(`${boardId}_${personId}`)
    )

    const managerRef = firebase.firestore().collection('people').doc(`${boardId}_${managerId}`)

    peopleRefs.forEach((personRef) => {
      batch.update(personRef, {
        removed: true,
        employeeStatus: 'Removed',
        employeeData: {
          terminationDate: moment().format('YYYY-MM-DD')
        },
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy,
        scenarioMetaData: {
          state: firebase.firestore.FieldValue.arrayUnion(PersonPlanStates.Removed)
        }
      })
    })

    batch.update(managerRef, {
      removed: true,
      employeeStatus: 'Removed',
      employeeData: {
        terminationDate: moment().format('YYYY-MM-DD')
      },
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedBy,
      scenarioMetaData: {
        state: firebase.firestore.FieldValue.arrayUnion(PersonPlanStates.Removed)
      }
    })

    try {
      await batch.commit()
    } catch (error) {
      bus.emit({ type: 'sync', message: error })
    }
  },

  reset({ commit }) {
    commit(M.RESET)
  },

  async fetchUserInfos(context, { boardId, userIds }) {
    try {
      const result = await getUsers(userIds)
      context.commit(M.SET_OWNER_INFOS, { boardId, ownerInfos: result })
      return result
    } catch (error) {
      console.error(error)
    }
  },

  //TODO: move out of vuex, no dependency or effect on the state
  async ignorePendingInvite(_state, { boardId, companyId, recipientEmail, inviterEmail }) {
    // Remove existing invites
    const querySnapshot = await firebase
      .firestore()
      .collection('invitations')
      .where('companyId', '==', companyId)
      .where('boardId', '==', boardId)
      .where('recipient', '==', recipientEmail)
      .where('inviterEmail', '==', inviterEmail)
      .get()
    // Mark the existing invitation as a duplicate
    // This is not a good solution as it does not cover the case where
    // multiple people have sent an invitation to a same person
    await Promise.allSettled(
      querySnapshot.docs.map((snapshot) =>
        snapshot.ref.update({
          ignore: true
        })
      )
    )
  },

  //TODO: move out of vuex, no dependency or effect on the state
  async sendInvite(
    { dispatch },
    {
      boardName,
      boardId,
      invitedBy,
      inviterEmail,
      recipientEmail,
      recipientName,
      companyId,
      accessLevel,
      isReminder,
      sendEmail = true
    }
  ) {
    try {
      const origin = window.location.origin
      // Remove existing invites
      await dispatch('ignorePendingInvite', {
        boardId,
        companyId,
        recipientEmail,
        inviterEmail
      })

      try {
        if (sendEmail) {
          const sendInvite = firebase.functions().httpsCallable('sendInvite')
          const template_id = isReminder
            ? 'd-77d1d25cb3b54734bd7589fb99e17f71'
            : 'd-fa518f0c3eef405495fe2802bb7c7933'

          await sendInvite({
            origin,
            boardName,
            invitedBy,
            recipientEmail,
            recipientName,
            template_id
          })
        }
      } catch (error) {
        console.error(error)
        Sentry?.captureException(error)
      }

      const invitationAccepted = false
      const recipient = recipientEmail
      const createdAt = firebase.firestore.FieldValue.serverTimestamp()

      await firebase.firestore().collection('invitations').doc().set({
        boardName,
        boardId,
        invitedBy,
        inviterEmail,
        recipient,
        recipientName,
        companyId,
        accessLevel,
        invitationAccepted,
        createdAt
      })

      window.mixpanel.track('invite_others')
    } catch (error) {
      console.error(error)
      Sentry?.captureException(error)
    }
  },

  //TODO: move out of vuex, no dependency or effect on the state
  async sendPlanInvite({ getters }, { boardName, boardId, invitedBy, personIds }) {
    try {
      const recipents = personIds.map((personId) => ({
        name: getters.personById({ boardId, personId }).name,
        email: getters.personById({ boardId, personId }).email
      }))

      const sendInvite = firebase.functions().httpsCallable('sendPlanInvite')

      await sendInvite({
        boardName,
        boardId,
        recipents,
        invitedBy
      })
    } catch (error) {
      console.error(error)
      Sentry?.captureException(error)
    }
  },

  /**
   * Used for public plan at the moment
   */
  set({ commit }, { boardId, people }) {
    commit(M.SET_PEOPLE_DATA, { boardId, people })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
