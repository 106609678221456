<template>
  <TransitionRoot as="template" :show="open" @after-leave="closeModal">
    <DialogUI as="div" class="fixed inset-0 z-[99] overflow-y-auto" @close="open = false">
      <div
        class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-fit sm:p-6 sm:align-middle"
          >
            <div class="mb-4 flex items-center justify-between">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Create a plan for</h3>
              <BaseButton @click="handleWorkforcePlanning">Stage workforce planning</BaseButton>
            </div>

            <div class="grid gap-5 sm:grid-cols-3 xl:grid-cols-3">
              <button
                v-for="option in OptionsDetails"
                :key="option.title"
                :disabled="disabledOption(option.type)"
                :class="[
                  'group h-[215px] w-[215px] rounded-lg border',
                  disabledOption(option.type)
                    ? 'cursor-not-allowed border-gray-300 bg-gray-50'
                    : 'cursor-pointer border-gray-200 bg-white hover:border-bluedark-100 hover:shadow-lg hover:shadow-bluedark-50'
                ]"
                @click="() => handleSelection(option.type)"
              >
                <div class="h-full w-full">
                  <div
                    :class="[
                      'flex items-center gap-4 rounded-t-lg border-b p-3 font-medium',
                      disabledOption(option.type)
                        ? 'bg-gray-75 border-gray-300 text-gray-500'
                        : 'border-gray-200 text-gray-700 group-hover:bg-bluedark-100 group-hover:text-bluedark-700'
                    ]"
                  >
                    <component
                      :is="disabledOption(option.type) ? option.disabledIcon : option.icon"
                    />
                    <span>{{ option.title }}</span>
                  </div>
                  <span
                    :class="[
                      'inline-block p-4 text-left text-base font-normal',
                      disabledOption(option.type) ? 'text-gray-400' : 'text-gray-500'
                    ]"
                  >
                    {{ option.description }}
                  </span>
                </div>
              </button>
            </div>

            <BaseButton class="mx-auto mt-8" color="blue-light" @click="open = false">
              <XIcon class="h-4 w-4 text-bluedark-700" />
              Cancel
            </BaseButton>
          </div>
        </TransitionChild>
      </div>
    </DialogUI>
  </TransitionRoot>
  <ToastDisplay />
</template>

<script setup>
import { DialogOverlay, Dialog as DialogUI, TransitionChild, TransitionRoot } from '@headlessui/vue'
import ToastDisplay from '@/components/ToastDisplay.vue'
import BaseButton from '@/components/BaseButton.vue'
import { XIcon } from '@heroicons/vue/outline'
import OptionsDetails from '@/lib/NewPlanOptionsModalText'
import { ACCESS_LEVELS_DICT } from '@/lib/Access.js'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ref, watch, computed } from 'vue'
import NewPlanOptions from '@/lib/NewPlanOptions'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
    required: true
  }
})

const emit = defineEmits(['close', 'handle-selection'])

const restictedOptions = [NewPlanOptions.Company, NewPlanOptions.Department]

const route = useRoute()
const { push } = useRouter()
const { getters } = useStore()

const boardId = computed(() => route.params.boardId)
const role = computed(() => getters.role(boardId.value))
const isAllowed = computed(
  () => role.value === ACCESS_LEVELS_DICT.ADMIN || role.value === ACCESS_LEVELS_DICT.EXECUTIVE
)

/**
 * Function to check if the option is disabled
 * @param planType type of the plan (ie company, department or team)
 * @returns true if the option is disabled
 */
const disabledOption = (planType) => !isAllowed.value && restictedOptions.includes(planType)

/**
 * Run the close modal event after leave this modal
 */
const closeModal = () => {
  emit('close')
}

/**
 * Function to pass selected option to the parent component
 * @param option selected option (ie company, department or team)
 */
const handleSelection = (option) => {
  emit('handle-selection', option)
  closeModal()
}

const open = ref(props.show)

/**
 * After a plan is created, redirect to the plan page
 */
const handleWorkforcePlanning = async () => {
  await push({ name: 'Create Workforce Plan', params: { boardId: boardId.value } })
  closeModal()
}

watch(
  () => props.show,
  () => {
    open.value = props.show
    if (!open.value) return
  }
)
</script>
