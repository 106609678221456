<template>
  <div ref="heightSource" class="relative flex max-w-[232px] flex-col items-center">
    <div
      class="org-node group relative z-10 flex h-full w-full items-center gap-2 rounded-xl bg-white p-4 shadow-md ring-1 ring-gray-300 transition-all duration-200 hover:ring-2 hover:ring-bluedark-300"
      :class="{
        'ring-3 hover:ring-3 shadow-xl': dragging === 'true',
        'shadow-3xl border border-dashed border-gray-600': draggingOver === 'true'
      }"
    >
      <div>
        <span
          class="border-b-[1px] border-transparent pt-1 text-base font-semibold"
          :class="headerStyle"
        >
          Role:
        </span>
        <input
          v-model="name"
          class="w-full border-0 border-b-[1px] border-transparent px-0 pt-1 pb-0 text-base font-semibold outline-0 ring-0 placeholder:font-normal placeholder:text-gray-400 focus:border-b-[1px] focus:border-bluedark-500 focus:outline-0 focus:ring-0"
          :class="headerStyle"
          type="text"
          placeholder="Enter role name"
        />
      </div>
      <button
        v-if="hasChildren"
        class="flex items-center justify-center rounded-2xl bg-gray-800 py-1 px-2 text-xs text-white transition-all hover:bg-gray-600"
        @click.stop="emit('toggle-collapse')"
        @mousedown.stop
      >
        <ChevronDownIcon
          class="w-4 transition-all"
          :class="{ 'rotate-180': isExpanded(personId) }"
        />
      </button>
      <div
        class="absolute top-0 left-0 z-30 flex h-6 w-full translate-y-0 scale-0 justify-center opacity-0 transition-all group-hover:-translate-y-full group-hover:scale-100 group-hover:opacity-100"
        @click.stop
        @mousedown.stop
      >
        <div
          class="overlay-buttons absolute top-0 -translate-y-5 rounded-lg bg-white drop-shadow-lg transition-opacity"
          :class="{
            'opacity-0': dragging === 'true'
          }"
        >
          <Popper content="Remove role" placement="top" hover class="top-10">
            <button
              type="button"
              class="items-center rounded-lg px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="emit('delete-user')"
            >
              <TrashIcon class="h-5 w-5" />
            </button>
          </Popper>
          <Popper content="Add child role" placement="top" hover class="top-10">
            <button
              type="button"
              class="items-center rounded-lg px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="emit('add-user')"
            >
              <UserPlus class="h-5 w-5" />
            </button>
          </Popper>
        </div>
      </div>
    </div>

    <div
      class="absolute z-0 w-[2px] bg-gray-400"
      :class="{
        'h-[230px]': hasChildren && isExpanded(personId),
        'h-[150px]': hasChildren && !isExpanded(personId),
        'h-36': !hasChildren
      }"
    ></div>

    <template v-if="isPersonAssigned">
      <div
        class="org-node group relative z-10 mt-12 flex h-full w-full flex-col rounded-xl bg-white shadow-md ring-1 ring-gray-300 transition-all duration-200 hover:ring-2 hover:ring-bluedark-300"
        :class="{
          'ring-3 hover:ring-3 shadow-xl': dragging === 'true',
          'shadow-3xl border border-dashed border-gray-600': draggingOver === 'true',
          ...cardWrapperClass
        }"
      >
        <div class="w-full">
          <div class="relative z-10 flex w-full cursor-grab flex-col gap-4 p-4 pt-8">
            <div class="flex flex-col items-start justify-start transition-colors duration-200">
              <div
                class="w-full truncate text-base font-medium text-gray-900 hover:text-blue-600"
                @click.stop="emit('toggle-collapse')"
                @mousedown.stop
              ></div>

              <div class="absolute -top-6 left-1/2 h-12 w-12 -translate-x-1/2">
                <img
                  v-if="isAvatarLoading || isAvatarError"
                  class="mx-auto h-12 w-12 rounded-full"
                  :src="`https://ui-avatars.com/api/?name=${owner.name}&background=50B5FF&color=fff`"
                />
                <img
                  v-else
                  class="mx-auto h-12 w-12 rounded-full object-cover"
                  :src="owner.avatarImg"
                />
              </div>
              <div class="flex w-full flex-col items-center text-center">
                <span class="text-base font-medium">{{ owner.name }}</span>
                <span class="text-sm font-normal text-gray-500">
                  {{ owner.role }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- overlay buttons -->
        <div
          class="absolute bottom-0 z-30 flex h-6 w-full translate-y-0 scale-0 justify-center opacity-0 transition-all group-hover:translate-y-full group-hover:scale-100 group-hover:opacity-100"
          @click.stop
          @mousedown.stop
        >
          <div
            class="overlay-buttons absolute top-5 -translate-y-2 rounded-lg bg-white drop-shadow-lg transition-opacity"
            :class="{
              'opacity-0': dragging === 'true'
            }"
          >
            <Popper content="Remove the assignment" placement="top" hover>
              <button
                class="items-center rounded-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
                :class="{
                  'opacity-0': dragging === 'true'
                }"
                @click="handleRemoveAssignment"
                @click.stop
                @mousedown.stop
              >
                <TrashIcon class="h-auto w-5" />
              </button>
            </Popper>
          </div>
        </div>
        <!-- end buttons -->
      </div>
    </template>
    <template v-else>
      <Popper content="Assign an employee" placement="top" hover>
        <button
          class="mt-12 flex h-10 w-10 transform items-center justify-center rounded-full bg-gray-100 text-xl shadow-lg transition-all hover:scale-110 hover:bg-gray-200 active:scale-125"
          @click="handleAssignPerson"
        >
          +
        </button>
      </Popper>
    </template>
  </div>
</template>

<script setup>
import UserPlus from '@/assets/Feather/UserPlus01.svg'
import TrashIcon from '@/assets/SvgIcons/TrashIcon.svg'
import useOrgNodeHelpers from '@/hooks/use-org-node-helpers.js'
import usePeopleBrowser from '@/hooks/use-people-browser'
import store from '@/store/index.js'
import { ChevronDownIcon } from '@heroicons/vue/outline'
import {
  useDebounceFn,
  useElementHover,
  useElementSize,
  useImage,
  useMagicKeys,
  useResizeObserver
} from '@vueuse/core'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import Popper from 'vue3-popper'

const props = defineProps({
  personId: {
    type: String,
    required: true
  },
  boardId: {
    type: String,
    required: true
  },
  dragging: {
    type: String,
    default: 'false'
  },
  dragTarget: {
    type: String,
    default: ''
  },
  draggingOver: {
    type: String,
    default: ''
  },
  enableHover: {
    type: String,
    default: 'true'
  },
  isExpanded: {
    type: String,
    default: 'false'
  }
})

const emit = defineEmits([
  'toggle-menu',
  'toggle-collapse',
  'add-user',
  'delete-user',
  'duplicate-user',
  'paste-user'
])

const widthSource = ref(null)
const heightSource = ref(null)
const pulseAnimation = ref(false)
const updatedName = ref(null)

const { updateWidth, updateHeight, setHovered, resetHovered, isExpanded } = useOrgNodeHelpers()
const isHovered = useElementHover(widthSource)
const { getPersonFromBrowser } = usePeopleBrowser(props.boardId)

useMagicKeys({
  passive: false,
  onEventFired(e) {
    if ((e.metaKey || e.ctrlKey) && e.key === 'v' && e.type === 'keydown' && isHovered.value) {
      e.preventDefault()
      handlePaste()
    }

    if ((e.metaKey || e.ctrlKey) && e.key === 'd' && e.type === 'keydown' && isHovered.value) {
      window.mixpanel.track('node_keyboard_duplicate')
      e.preventDefault()
      handleDuplicate()
    }
  }
})

onMounted(() => {
  const { width } = useElementSize(widthSource)

  useResizeObserver(heightSource, (entries) => {
    const entry = entries[0]
    updateHeight({ id: props.personId, height: entry?.target?.clientHeight })
  })

  watch(width, () => {
    updateWidth({ id: props.personId, width: width.value })
  })

  watch(isHovered, () => {
    if (isHovered.value) setHovered(props.personId)
    else resetHovered(props.personId)
  })
})

const node = computed(() =>
  store.getters['people/personById']({ boardId: props.boardId, personId: props.personId })
)
const isPersonAssigned = computed(() => node.value?.rolePlanning?.ownerId)
const owner = computed(() =>
  store.getters['people/personById']({
    boardId: props.boardId,
    personId: node.value?.rolePlanning?.ownerId
  })
)
const rolesInGoal = computed(() =>
  store.getters['people/rolesInGoal']({ boardId: props.boardId, goalId: node.value.managers[0] })
)
const indexInRoles = computed(() =>
  rolesInGoal.value.findIndex((role) => role.personId === props.personId)
)
const hasChildren = computed(() => node.value?.subordinates?.length > 0)

const { isLoading: isAvatarLoading, error: isAvatarError } = useImage({
  src: owner.value?.avatarImg
})

const name = computed({
  get: () => node.value?.rolePlanning?.name,
  set: (value) => {
    updatedName.value = value
    updateName()
  }
})

const updateName = useDebounceFn(() => {
  store.dispatch('people/patchPerson', {
    personId: props.personId,
    boardId: props.boardId,
    patch: {
      rolePlanning: {
        name: updatedName.value
      }
    }
  })
}, 700)

let pulseAnimationSwitch = null
const triggerPulseAnimation = () => {
  if (pulseAnimationSwitch) {
    clearTimeout(pulseAnimationSwitch)
    pulseAnimation.value = false
  }

  nextTick(() => {
    pulseAnimation.value = true
    pulseAnimationSwitch = setTimeout(() => {
      pulseAnimation.value = false
    }, 1000)
  })
}

const handlePaste = () => {
  if (!isHovered.value) return
  emit('paste-user')
  triggerPulseAnimation()
}

const handleDuplicate = () => {
  emit('duplicate-user')
}

const handleAssignPerson = async () => {
  const selectedPersonId = await getPersonFromBrowser()

  if (selectedPersonId === undefined) return

  store.dispatch('people/patchPerson', {
    personId: props.personId,
    boardId: props.boardId,
    patch: {
      rolePlanning: {
        ownerId: selectedPersonId
      }
    }
  })
}

const handleRemoveAssignment = () => {
  store.dispatch('people/patchPerson', {
    personId: props.personId,
    boardId: props.boardId,
    patch: {
      rolePlanning: {
        ownerId: null
      }
    }
  })
}

const bgVariant = computed(() => indexInRoles.value % rolesInGoal.value.length)

const cardWrapperClass = computed(() => ({
  'bg-white ring-1 ring-gray-300 hover:bg-bluedark-100 hover:ring-bluedark-300': !bgVariant.value,
  'bg-moss-25 ring-1 ring-moss-300 hover:bg-moss-100 hover:ring-moss-400': bgVariant.value === 0,
  'bg-bluedark-25 ring-bluedark-300 hover:bg-bluedark-100 hover:ring-bluedark-400':
    bgVariant.value === 1,
  'bg-rose-25 ring-rose-300 hover:bg-rose-100 hover:ring-rose-400': bgVariant.value === 2,
  'bg-orangedark-25 ring-orangedark-300 hover:bg-orangedark-100 hover:ring-orangedark-400':
    bgVariant.value === 3,
  'bg-greenlight-25 ring-greenlight-300 hover:bg-greenlight-100 hover:ring-greenlight-400':
    bgVariant.value === 4,
  'bg-blue-25 ring-blue-300 hover:bg-blue-100 hover:ring-blue-400': bgVariant.value === 5,
  'bg-orange-25 ring-orange-300 hover:bg-orange-100 hover:ring-orange-400': bgVariant.value === 6,
  'bg-pink-25 ring-pink-300 hover:bg-pink-100 hover:ring-pink-400': bgVariant.value === 7,
  'bg-bluelight-25 ring-bluelight-300 hover:bg-bluelight-100 hover:ring-bluelight-400':
    bgVariant.value === 8,
  'bg-indigo-25 ring-indigo-300 hover:bg-indigo-100 hover:ring-indigo-400': bgVariant.value === 9,
  'bg-fuchsia-25 ring-fuchsia-300 hover:bg-fuchsia-100 hover:ring-fuchsia-400':
    bgVariant.value === 10,
  'bg-green-25 ring-green-300 hover:bg-green-100 hover:ring-green-400': bgVariant.value === 11,
  'bg-cyan-25 ring-cyan-300 hover:bg-cyan-100 hover:ring-cyan-400': bgVariant.value === 12,
  'bg-purple-25 ring-purple-300 hover:bg-purple-100 hover:ring-purple-400': bgVariant.value === 13,
  'bg-violet-25 ring-violet-300 hover:bg-violet-100 hover:ring-violet-400': bgVariant.value === 14,
  'bg-yellow-25 ring-yellow-300 hover:bg-yellow-100 hover:ring-yellow-400': bgVariant.value === 15,
  'bg-teal-25 ring-teal-300 hover:bg-teal-100 hover:ring-teal-400': bgVariant.value === 16,

  pulseAnimation: pulseAnimation.value
}))

const headerStyle = computed(() => {
  return {
    'text-gray-900': !bgVariant.value,

    'text-moss-700': bgVariant.value === 0,
    'text-bluedark-700': bgVariant.value === 1,
    'text-rose-700': bgVariant.value === 2,
    'text-orangedark-700': bgVariant.value === 3,
    'text-greenlight-700': bgVariant.value === 4,
    'text-blue-700': bgVariant.value === 5,
    'text-orange-700': bgVariant.value === 6,
    'text-pink-700': bgVariant.value === 7,
    'text-bluelight-700': bgVariant.value === 8,
    'text-indigo-700': bgVariant.value === 9,
    'text-fuchsia-700': bgVariant.value === 10,
    'text-green-700': bgVariant.value === 11,
    'text-cyan-700': bgVariant.value === 12,
    'text-purple-700': bgVariant.value === 13,
    'text-violet-700': bgVariant.value === 14,
    'text-yellow-700': bgVariant.value === 15,
    'text-teal-700': bgVariant.value === 16
  }
})
</script>

<style scoped>
@import url('../index.css');

.org-node {
  font-family: 'Inter';
}

.dragging-over {
  @apply scale-110 shadow-lg shadow-bluedark-100;
}

.color-icon svg :deep(path) {
  stroke: v-bind(iconColor);
}

.white-icon :deep(path) {
  stroke: white;
}

.overlay-buttons svg :deep(path) {
  @apply stroke-gray-900;
}

:deep(.popper) {
  @apply pointer-events-none cursor-pointer whitespace-nowrap rounded-lg bg-black px-3 py-2 text-xs text-white shadow-lg !important;
}

.pulseAnimation {
  @apply animate-[pulse_0.3s_ease-in-out];
}
</style>
