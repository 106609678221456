export default {
  NewlyAdded: 'Newly added',
  Removed: 'Removed',
  RIF: 'RIF',
  Moved: 'Moved',
  Edited: 'Edited',
  RemovedManager: 'Removed manager',
  Backfilled: 'Backfilled',
  Backfill: 'Backfill'
}
