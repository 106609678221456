import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { sortBy } from 'lodash-es'

const state = {
  history: []
}

const mutations = {
  setHistory(state, historyArr) {
    state.history = historyArr
  }
}

const actions = {
  // Fetch the all history of the given squad id
  async fetchSquadsHistory(context, { squadId }) {
    const querySnapshot = await firebase
      .firestore()
      .collection('squads_history')
      .where('squadId', '==', squadId)
      .get()

    let ret = []
    querySnapshot.forEach((doc) => {
      ret.push(doc.data())
    })

    ret = sortBy(ret, (o) => {
      return -1 * o.updatedAt.seconds
    })

    context.commit('setHistory', ret)
  }
}

export default {
  state,
  mutations,
  actions,
  modules: {}
}
