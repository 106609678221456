import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

/**
 * Excute batch.commit function after devide firebase doc by 500
 *
 * Ref: https://gist.github.com/MorenoMdz/516c590f2a034bf39c55708574831da8
 */
export const batchWrapper = async (documentRefs, action, dataList) => {
  const OPERATION_COUNT_LIMIT = 500
  const batchArray = []
  batchArray.push(firebase.firestore().batch())
  let operationCounter = 0
  let batchIndex = 0

  documentRefs.forEach((target) => {
    if (action === 'delete') {
      batchArray[batchIndex].delete(target)
    }

    if (action === 'update') {
      batchArray[batchIndex].update(
        target,
        dataList[operationCounter + batchIndex * OPERATION_COUNT_LIMIT]
      )
    }
    if (action === 'set') {
      batchArray[batchIndex].set(
        target,
        dataList[operationCounter + batchIndex * OPERATION_COUNT_LIMIT],
        { merge: true }
      )
    }
    operationCounter++

    if (operationCounter >= OPERATION_COUNT_LIMIT) {
      batchArray.push(firebase.firestore().batch())
      batchIndex++
      operationCounter = 0
    }
  })

  await Promise.all(batchArray.map((batch) => batch.commit()))
}
