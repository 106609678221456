import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import idCreator from '@/lib/idCreator.js'

const state = {
  notifications: []
}

const getters = {
  getNotif: (_state) => (notifId) => _state.notifications.filter((notif) => notif.id === notifId)
}

const mutations = {
  setNotifs: (_state, notifs) => {
    _state.notifications = notifs
  }
}

const actions = {
  /**
   * Given a `userId`, fetches all notifications for said user
   * @param { number } userId
   */
  async fetchAllNotifs(ctx, { personId, boardId }) {
    console.log('Fetching notifs')

    const query = await firebase
      .firestore()
      .collection('notifications')
      .where('receiver.id', '==', personId)
      .where('boardId', '==', boardId)
      .where('hide', '==', false)
      .get()

    ctx.commit(
      'setNotifs',
      query.docs.map((doc) => {
        window.mixpanel.track(`view_notif`)
        return doc.data()
      })
    )
  },
  /**
   * Creates a new notification document in the `notifications` collection.
   * @param { Object } data
   * @param { { name: string, email: string, id: string } } data.receiver
   * @param { { name: string, email: string, id: string } } data.sender
   * @param { string } data.title
   * @param { string } data.content
   * @param { string } data.type
   * @param { string } data.templateId
   * @param { string } data.actionUrl
   * @param { string } data.svgName
   * @param { string } data.boardId
   */
  async submitNotif(ctx, data) {
    const path = `../assets/SvgIcons/${data.svgName}.svg?url`
    const url = (await import(/* @vite-ignore */ path)).default

    const notifId = idCreator.createNotificationId()
    data.notifId = notifId
    data.svgUrl = url
    data.hide = false

    await firebase.firestore().collection('notifications').doc(notifId).set(data)
  },

  submitDefaultNotifs(ctx, { boardId, displayName, email, personId }) {
    const defaultTaskNotifications = [
      {
        title: 'Bring your team onboard',
        ctaText: 'Invite Member',
        svgName: 'UserAddIcon',
        actionUrl: `/invite/${boardId}`,
        receiver: {
          name: displayName,
          email,
          id: personId
        },
        templateId: 'inviteMember',
        type: 'TASK',
        boardId,
        hide: false
      },
      {
        title: 'Check out our tutorials @',
        content: 'https://www.notion.so/agentnoon/Help-Center-5c8572eadf244d9f9c3e54c5fbec1f9a',
        ctaText: 'See Tutorials',
        svgName: 'BookIcon',
        actionUrl: 'https://www.notion.so/agentnoon/Help-Center-5c8572eadf244d9f9c3e54c5fbec1f9a',
        receiver: {
          name: displayName,
          email,
          id: personId
        },
        templateId: 'visitTutorials',
        type: 'TASK',
        boardId,
        hide: false
      }
    ]

    defaultTaskNotifications.forEach((notifData) => ctx.dispatch('submitNotif', notifData))
  },

  hideNotif(ctx, notifId) {
    const notifs = ctx.state.notifications
    const newNotifs = notifs.filter((notif) => notif.notifId !== notifId)

    const notifQuery = firebase
      .firestore()
      .collection('notifications')
      .where('notifId', '==', notifId)
    notifQuery.get().then((snapshot) => snapshot.forEach((doc) => doc.ref.update({ hide: true })))

    ctx.commit('setNotifs', newNotifs)
  }
}

export default {
  state,
  mutations,
  getters,
  actions
  // modules: {}
}
