import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { sortBy } from 'lodash-es'

const state = {
  history: []
}

const mutations = {
  setHistory(state, historyArr) {
    state.history = historyArr
  }
}

const actions = {
  // setUserAuth(context, user) {
  //   context.commit('setUser', user);
  // },

  // Fetch the all history of the given person
  // throughout the entire boards
  async fetchHistory(context, { personId }) {
    const querySnapshot = await firebase
      .firestore()
      .collection('people_history')
      .where('personId', '==', personId)
      .get()

    let ret = []
    querySnapshot.forEach((doc) => {
      ret.push(doc.data())
    })

    ret = sortBy(ret, (o) => {
      return -1 * o.updatedAt.seconds
    })

    context.commit('setHistory', ret)
  }
}

export default {
  state,
  mutations,
  actions,
  modules: {}
}
