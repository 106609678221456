<template>
  <router-link
    :to="isPremium ? '#' : path"
    class="flex h-10 w-full cursor-pointer flex-row items-center rounded-md py-2 pl-3 pr-2 transition-colors hover:bg-primary-50 hover:text-bluedark-700 active:bg-primary-100"
    :class="{
      'bg-primary-50': isSelected,
      'text-gray-500': isPremium,
      'bg-bluedark-50 text-bluedark-700': $route.fullPath === path && !isPremium
    }"
    @click="handleClick"
  >
    <div class="flex w-full flex-row items-center overflow-hidden truncate">
      <slot></slot>
      <div v-if="isPremium" class="ml-auto">
        <PopperToolip placement="top" content="Paid feature" hover>
          <div
            class="flex items-center justify-center rounded-full bg-bluedark-50 py-1 px-2 text-xs font-bold text-white"
          >
            ⭐
          </div>
        </PopperToolip>
      </div>
    </div>
  </router-link>
</template>

<script setup>
import useCalendlyModal from '@/hooks/use-calendly-modal'

const props = defineProps({
  path: {
    type: String,
    required: false,
    default: '/'
  },
  isSelected: {
    type: Boolean,
    required: false,
    default: false
  },
  isPremium: {
    type: Boolean,
    required: false,
    default: false
  }
})

const { setShow } = useCalendlyModal()

const handleClick = () => {
  if (!props.isPremium) return
  setShow(true)
}
</script>
