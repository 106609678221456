import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'

/**
 * Get default state
 */
const getDefaultState = () => {
  return {
    isLoaded: false,
    boardId: null,
    ceoPersonId: null,
    hrLeadPersonId: null,
    hiringRequestReviewers: [],
    planApprovers: null,
    performanceAccessibleByHROnly: false,
    baseCurrency: 'USD',
    nextJobCode: '0',
    isRestrictToTeamViewEnabled: false,
    forecastSheetId: null,
    revenueAttributionData: null,
    isGoogleSheetsAutoSyncOn: false,
    isPlanStrictCheckEnabled: false,
    formulas: []
  }
}

const state = getDefaultState()

const getters = {
  preferredBaseCurrency: (_state) => {
    return _state.baseCurrency
  },
  getHiringRequestReviewers: (_state) => {
    return _state.hiringRequestReviewers
  },
  loaded: (_state) => {
    return _state.isLoaded
  },
  nextJobCode: (_state) => {
    return _state.nextJobCode
  },
  ceoPersonId: (_state) => {
    return _state.ceoPersonId
  },
  forecastSheetId: (_state) => {
    return _state.forecastSheetId
  },
  revenueAttributionData: (_state) => {
    return _state.revenueAttributionData
  },
  planApprovers: (_state) => {
    return _state.planApprovers
  },
  planApproversByLevel: (_state) => (level) => {
    return _state.planApprovers?.[level]
  },
  isLevelApprover: (_state) => (uid, level) => {
    return _state.planApprovers?.[level]?.includes(uid)
  },
  isPlanStrictCheckEnabled: (_state) => {
    return _state.isPlanStrictCheckEnabled ?? false
  },
  planApproversFlat: (_state) => {
    return Object.values(_state.planApprovers || {}).flat()
  },
  formulas: (_state) => {
    return _state.formulas
  }
}

const mutations = {
  setSettings(_state, settings) {
    if (!settings) return
    _state = Object.assign(_state, settings)
  },
  resetSettings(_state) {
    Object.assign(_state, getDefaultState())
  },
  setForecastSheetId(_state, forecastSheetId) {
    _state.forecastSheetId = forecastSheetId
  },
  setPlanApprovers(_state, approvers) {
    _state.planApprovers = approvers
  },
  setPlanStrictCheck(_state, strictCheck) {
    _state.isPlanStrictCheckEnabled = strictCheck
  },
  setRevenueAttributionData(_state, revenueAttributionData) {
    _state.revenueAttributionData = revenueAttributionData
  },
  setIsLoaded(_state, isLoaded) {
    _state.isLoaded = isLoaded
  },
  addOrUpdateFormula(_state, formula) {
    const index = _state.formulas.findIndex((f) => f.name === formula.name)
    if (index === -1) {
      _state.formulas.push(formula)
    } else {
      _state.formulas[index] = formula
    }
  },
  removeFormula(_state, formulaName) {
    const index = _state.formulas.findIndex((f) => f.name === formulaName)
    if (index !== -1) {
      _state.formulas.splice(index, 1)
    }
  }
}

const actions = {
  resetSettings(context) {
    context.commit('resetSettings')
  },
  async setBaseCurrency(context, { baseCurrency, boardId }) {
    if (!boardId) throw new Error('BoardId is missing')
    try {
      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          baseCurrency,
          boardId
        },
        { merge: true }
      )
      context.dispatch('fetchSettings', { boardId })
    } catch (e) {
      console.error(e)
      throw new Error('Something weng wrong saving')
    }
  },
  async fetchSettings(context, { boardId }) {
    try {
      const doc = await firebase.firestore().collection('settings').doc(boardId).get()

      if (doc.exists) {
        context.commit('setSettings', doc.data())
      }
    } catch (e) {
      if (!e.message.includes('Missing or insufficient permissions')) console.error(e)
    } finally {
      context.commit('setIsLoaded', true)
    }
  },
  async getNextJobCode(context, boardId) {
    try {
      await firebase
        .firestore()
        .collection('settings')
        .doc(boardId)
        .set(
          {
            boardId,
            nextJobCode: firebase.firestore.FieldValue.increment(1)
          },
          { merge: true }
        )

      await context.dispatch('fetchSettings', { boardId })
      return context.getters.nextJobCode
    } catch (e) {
      console.error(e)
      return 0
    }
  },
  async setForecastSheetId(context, { forecastSheetId, boardId }) {
    try {
      context.commit('setForecastSheetId', forecastSheetId)

      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          boardId,
          forecastSheetId
        },
        { merge: true }
      )
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },
  async setPlanApprovers(context, { planApprovers, boardId }) {
    try {
      context.commit('setPlanApprovers', planApprovers)

      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          boardId,
          planApprovers
        },
        { merge: true }
      )
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong while setting approvers to board settings')
    }
  },
  async setPlanStrictCheck(context, { strickCheck, boardId }) {
    try {
      context.commit('setPlanStrictCheck', strickCheck)

      await firebase
        .firestore()
        .collection('settings')
        .doc(boardId)
        .set({ isPlanStrictCheckEnabled: strickCheck }, { merge: true })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong while setting strict check to board settings')
    }
  },
  async setRevenueAttributionData(context, { revenueAttributionData, boardId }) {
    try {
      context.commit('setRevenueAttributionData', revenueAttributionData)

      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          boardId,
          revenueAttributionData
        },
        { merge: true }
      )
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },
  async setFormula(context, { formula, boardId }) {
    try {
      context.commit('addOrUpdateFormula', formula)

      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          boardId,
          formulas: context.getters.formulas
        },
        { merge: true }
      )
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },
  async removeFormula(context, { formulaName, boardId }) {
    try {
      context.commit('removeFormula', formulaName)

      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          boardId,
          formulas: context.getters.formulas
        },
        { merge: true }
      )
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
