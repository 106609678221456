import { readonly, ref } from 'vue'

export const modes = {
  all: 'all',
  hiresLeavers: 'hiresLeavers',
  executive: 'executive',
  changes: 'changes',
  overview: 'overview'
}

const mode = ref(modes.all)

export default function useChartMode() {
  const setMode = (newMode) => {
    mode.value = newMode
  }

  return {
    mode: readonly(mode),
    setMode
  }
}
