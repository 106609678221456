import { extractNumber } from '@/services/currency.service'
import { fetchCompensations as fetchComp } from '@/services/firestore.service'
import { batchWrapper } from '@/utils/firebaseHelper.js'
import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import { keyBy } from 'lodash-es'
import { bus } from '@/services/errorbus.service.js'

const state = {
  compensations: {},
  compensationByPersonId: {}
}

const getters = {
  compensations: (_state) => (boardId) => {
    return _state.compensations[boardId].filter((compensation) => !compensation?.deletedAt)
  },
  compensationForPerson:
    (_state) =>
    ({ boardId, personId }) => {
      return _state.compensationByPersonId[boardId]?.[personId] || null
    },
  salaryNumber:
    (_state, _getters) =>
    ({ boardId, personId }) => {
      const payRate = _getters.compensationForPerson({ boardId, personId })?.payRate
      return extractNumber(payRate)
    },
  salaryHistory:
    (_state, _getters) =>
    ({ boardId, personId }) => {
      const previousRates =
        _getters.compensationForPerson({ boardId, personId })?.previousRates || []
      return previousRates?.map((salary) => extractNumber(salary.payRate))
    },
  /**
   * Returns salaryNumber and currency pair for previous compensation record
   * @param _state
   * @param _getters
   * @returns {function(*): {salaryNumber: *|number|number, currency: *}[]}
   */
  previousCompensation:
    (_state, _getters) =>
    ({ boardId, personId }) => {
      const previousRates =
        _getters.compensationForPerson({ boardId, personId })?.previousRates || []
      if (previousRates.length > 0) {
        return {
          salaryNumber: extractNumber(previousRates[0].payRate),
          currency: previousRates[0].currency
        }
      }

      return null
    }
}

const mutations = {
  setCompensations(_state, { data, boardId }) {
    _state.compensations[boardId] = data
    _state.compensationByPersonId[boardId] = keyBy(_state.compensations[boardId], 'personId')
  },
  addCompensation(_state, { data, boardId }) {
    if (!_state.compensations[boardId]) _state.compensations[boardId] = []
    if (!_state.compensationByPersonId[boardId]) _state.compensationByPersonId[boardId] = {}

    _state.compensations[boardId].push(data)
    _state.compensationByPersonId[boardId][data.personId] = data
  },
  setCompensation(_state, { data, boardId }) {
    const index = _state.compensations[boardId].findIndex(
      ({ personId }) => data.personId === personId
    )

    if (index === -1) {
      _state.compensations[boardId].push(data)
    } else {
      _state.compensations[boardId][index] = data
    }

    _state.compensationByPersonId[boardId][data.personId] = data
  }
}

const actions = {
  async fetchNew(context, { boardId, force = true }) {
    if (context.state.compensations[boardId] && !force) return false

    await context.dispatch('fetchCompensations', { boardId })
    return true
  },

  async fetchCompensations(context, { boardId }) {
    try {
      const compensations = await fetchComp(boardId)

      context.commit('setCompensations', { data: compensations, boardId })
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
    }
  },

  async addCompensation(
    context,
    { personId, boardId, payPer, payRate, payRateEffectiveDate, payType, currency }
  ) {
    if (!boardId) throw new Error('BoardId is undefined')

    const newData = {
      personId,
      boardId,
      payPer,
      payRate,
      payRateEffectiveDate,
      payType,
      currency,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }

    context.commit('addCompensation', { data: newData, boardId })

    try {
      await firebase
        .firestore()
        .collection('compensations')
        .doc(`${boardId}_${personId}`)
        .set(newData)
    } catch (e) {
      window.console.error('Error adding new compensation: ', e)
      if (Sentry) Sentry.captureException(e)
      bus.emit({ type: 'sync', message: e })
    }
  },

  async updateCompensation(
    context,
    {
      personId,
      boardId,
      payPer = null,
      payRate = null,
      payRateEffectiveDate = null,
      payType = null,
      currency = null
    }
  ) {
    if (!boardId) throw new Error('BoardId is undefined')

    const previousCompensation = context.getters.compensationForPerson({ boardId, personId })

    try {
      const newData = {
        personId,
        boardId,
        payPer,
        payRate,
        payRateEffectiveDate,
        payType,
        currency,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        previousRates: firebase.firestore.FieldValue.arrayUnion({
          payRate: previousCompensation?.payRate || null,
          payType: previousCompensation?.payType || null,
          currency: previousCompensation?.currency || null
        })
      }

      await firebase
        .firestore()
        .collection('compensations')
        .doc(`${boardId}_${personId}`)
        .set(newData, { merge: true })

      const querySnapshots = await firebase
        .firestore()
        .collection('compensations')
        .doc(`${boardId}_${personId}`)
        .get()

      const compensation = querySnapshots.data()
      context.commit('setCompensation', { data: compensation, boardId })
    } catch (e) {
      window.console.error('Error updating compensation: ', e)
      if (Sentry) Sentry.captureException(e)
      bus.emit({ type: 'sync', message: e })
    }
  },

  async updateCompensations(context, compensations) {
    const refs = []
    const data = []

    compensations.forEach((compensation) => {
      const {
        personId,
        boardId,
        payPer = null,
        payRate = null,
        payRateEffectiveDate = null,
        payType = null,
        currency = null
      } = compensation

      if (!boardId) return

      const previousCompensation = context.getters.compensationForPerson({ boardId, personId })

      const newData = {
        personId,
        boardId,
        payPer,
        payRate,
        payRateEffectiveDate,
        payType,
        currency,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        previousRates: firebase.firestore.FieldValue.arrayUnion({
          payRate: previousCompensation?.payRate || null,
          payType: previousCompensation?.payType || null,
          currency: previousCompensation?.currency || null
        })
      }

      refs.push(
        firebase
          .firestore()
          .collection('compensations')
          .doc(`${compensation.boardId}_${compensation.personId}`)
      )
      data.push(newData)
      context.commit('setCompensation', { data: newData, boardId })
    })

    try {
      await batchWrapper(refs, 'set', data)
    } catch (e) {
      bus.emit({ type: 'sync', message: e })
    }
  },

  async updateCompensationCurrency(context, { personId, boardId, currency }) {
    if (!boardId) throw new Error('BoardId is undefined')

    const previousCompensation = context.getters.compensationForPerson({ boardId, personId })
    try {
      const newData = {
        ...previousCompensation,
        currency,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        previousRates: firebase.firestore.FieldValue.arrayUnion({
          payRate: previousCompensation?.payRate || null,
          payType: previousCompensation?.payType || null,
          currency: previousCompensation?.currency || null
        })
      }

      await firebase
        .firestore()
        .collection('compensations')
        .doc(`${boardId}_${personId}`)
        .set(newData, { merge: true })

      const querySnapshots = await firebase
        .firestore()
        .collection('compensations')
        .doc(`${boardId}_${personId}`)
        .get()

      const compensation = querySnapshots.data()
      context.commit('setCompensation', { data: compensation, boardId })
    } catch (e) {
      window.console.error('Error updating compensation: ', e)
      if (Sentry) Sentry.captureException(e)
      bus.emit({ type: 'sync', message: e })
    }
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
