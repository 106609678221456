import Formula from 'fparser'

export function getTokens(formula) {
  if (!formula) {
    return []
  }

  const list = []
  let token = ''
  let inVariable = false
  for (let i = 0; i < formula.length; i++) {
    const c = formula[i]
    if (c === '[') {
      inVariable = true
      if (token) {
        list.push({ char: token })
        token = ''
      }
    } else if (c === ']') {
      inVariable = false
      list.push({ variable: token })
      token = ''
    } else if (inVariable) {
      token += c
    } else {
      token += c
    }
  }

  if (token) {
    list.push({ char: token })
  }

  return list
}

export function getVariablesInFormula(formula) {
  const tokens = getTokens(formula)

  return tokens.filter((token) => token.variable).map((token) => token.variable)
}

// eslint-disable-next-line no-unused-vars
export function mapDataToVariables({ salaryNumber, person, variables }) {
  const result = {}

  variables.forEach((variable) => {
    switch (variable) {
      case 'salary':
        result[variable] = salaryNumber || 0
        break
      default:
        break
    }
  })

  return result
}

export function evaluate({ formula, variables }) {
  try {
    const f = new Formula(formula)

    return f.evaluate(variables).toFixed(2)
  } catch (e) {
    return null
  }
}
