import { readonly, ref } from 'vue'

const show = ref(false)

export default function useCalendlyModal() {
  const setShow = (value) => {
    show.value = value
  }

  return {
    show: readonly(show),
    setShow
  }
}
