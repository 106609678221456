import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import * as Sentry from '@sentry/browser'
import 'firebase/compat/functions'
import { sortBy } from 'lodash-es'

const getDefaultState = () => {
  return {}
}

const state = getDefaultState()

const mutations = {}

const actions = {
  async fetchCommentsForRequisitions(context, { requestId }) {
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('requisition_comments')
        .where('requestId', '==', requestId)
        .get()

      const comments = []
      querySnapshot.forEach((doc) => {
        let comment = doc.data()
        comments.push(comment)
      })

      return sortBy(comments, (c) => {
        return c.createdAt.seconds
      })
    } catch (e) {
      console.error(e)
      throw 'Something weng wrong saving'
    }
  },

  async setNewRequisitionComment(state, commentObj) {
    commentObj.createdAt = firebase.firestore.FieldValue.serverTimestamp()

    await firebase
      .firestore()
      .collection('requisition_comments')
      .doc()
      .set(commentObj)
      .catch((error) => {
        window.console.error('Error adding new person: ', error)
        if (Sentry) Sentry.captureException(error)
      })
  },

  async notifyNewCommentOnRequisitionRequest(
    state,
    { requestId, commentId, commenterName, commenterPhoto, comment, subject, title, sendTo, link }
  ) {
    var notifyNewComment = firebase.functions().httpsCallable('notifyNewComment')
    await notifyNewComment({
      requestId,
      commentId,
      commenterName,
      commenterPhoto,
      comment,
      sendTo,
      link,
      subject,
      title
    })
  }
}

export default {
  state,
  mutations,
  actions,
  modules: {}
}
