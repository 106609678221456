<template>
  <nav
    class="relative z-40 flex w-60 flex-col items-center border-r border-gray-200 bg-white py-4 px-4 transition-all"
  >
    <div class="mt-4 flex h-8 w-full flex-row items-center justify-between pl-2 pr-1">
      <router-link :to="landingUrl">
        <Logo />
      </router-link>
      <div class="ml-auto flex flex-col gap-1">
        <div
          v-if="isTrial"
          class="flex items-center justify-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700"
        >
          trial
        </div>
        <div
          v-if="isFree"
          class="flex items-center justify-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700"
        >
          free
        </div>
      </div>
    </div>
    <div class="flex h-full w-full flex-col justify-between">
      <ol class="mt-6 flex h-full flex-col gap-1">
        <li v-if="show(MenuItems.Plans).value" class="group">
          <SideNavigationBaseLink
            :path="navigationConfig[MenuItems.Plans]?.url"
            :is-premium="navigationConfig[MenuItems.Plans]?.isPremium"
          >
            <div class="flex h-9 w-9 items-center" aria-hidden="true">
              <DataFlowIcon
                class="hover-icon h-6 w-6"
                :class="{ 'active-menu': isActive(MenuItems.Plans).value }"
              />
            </div>
            <span class="ml-1 text-base font-medium">
              {{ navigationConfig[MenuItems.Plans].name }}
            </span>
            <span
              class="ml-auto flex h-6 items-center justify-center rounded-full bg-[#e0e9ff] px-2 text-sm font-medium text-bluedark-700 bg-blend-multiply"
            >
              {{ plansCount }}
            </span>
          </SideNavigationBaseLink>
        </li>

        <li v-if="show(MenuItems.OrgPlan).value" class="w-full pl-8">
          <SideNavigationBaseLink
            :path="navigationConfig[MenuItems.OrgPlan]?.url"
            :is-premium="navigationConfig[MenuItems.OrgPlan]?.isPremium"
          >
            <span class="text-base font-medium">
              {{ navigationConfig[MenuItems.OrgPlan]?.name }}
            </span>
            <MainBoardPill />
          </SideNavigationBaseLink>
        </li>
        <li class="relative">
          <div
            class="pointer-events-none absolute top-0 left-0 right-0 z-50 h-8 bg-gradient-to-b from-white to-transparent transition-opacity"
            :class="{
              'opacity-0': plansScroll < 10
            }"
          ></div>
          <ol
            v-if="show(MenuItems.Plans).value && !navigationConfig[MenuItems.Plans]?.isPremium"
            ref="plansList"
            class="max-h-56 overflow-y-auto"
            @scroll="plansScroll = $event.target.scrollTop"
          >
            <li v-for="plan in topPlans" :key="plan.boardId" class="w-full pl-8">
              <SideNavigationBaseLink
                :path="getPlanUrl(plan)"
                class="relative w-full overflow-hidden truncate text-base font-medium"
              >
                <ScrollOnHover>
                  {{ plan.boardName }}
                </ScrollOnHover>
              </SideNavigationBaseLink>
            </li>
          </ol>
        </li>

        <li
          v-if="!navigationConfig[MenuItems.Plans]?.isPremium && show(MenuItems.Plans).value"
          class="w-full pl-8"
        >
          <PopperToolip
            content="New plan"
            placement="top"
            hover
            class="w-full border-0"
            open-delay="300"
            style="border-width: 0; margin: 0"
          >
            <BaseButton
              class="flex h-10 w-full cursor-pointer flex-row items-center justify-center rounded-md bg-bluedark-50 px-3 py-2 text-xl text-bluedark-700 shadow-sm transition-colors hover:bg-bluedark-100 active:bg-primary-100"
              @click="showCreatePlanModal = true"
            >
              +
            </BaseButton>
          </PopperToolip>
        </li>

        <li v-if="show(MenuItems.Directory).value" class="group">
          <SideNavigationBaseLink
            :path="navigationConfig[MenuItems.Directory]?.url"
            :is-premium="navigationConfig[MenuItems.Directory]?.isPremium"
          >
            <div class="flex h-9 w-9 items-center" aria-hidden="true">
              <ListIcon
                class="hover-icon h-6 w-6"
                :class="{ 'active-menu': isActive(MenuItems.Directory).value }"
              />
            </div>
            <span class="ml-1 text-base font-medium">
              {{ navigationConfig[MenuItems.Directory]?.name }}
            </span>
          </SideNavigationBaseLink>
        </li>

        <li v-if="show(MenuItems.Forecast).value" class="group">
          <SideNavigationBaseLink
            :path="navigationConfig[MenuItems.Forecast]?.url"
            :is-premium="navigationConfig[MenuItems.Forecast]?.isPremium"
          >
            <div class="flex h-9 w-9 items-center" aria-hidden="true">
              <ChartBarIcon
                class="hover-icon h-6 w-6"
                :class="{ 'active-menu': isActive(MenuItems.Forecast).value }"
              />
            </div>
            <span class="ml-1 text-base font-medium">
              {{ navigationConfig[MenuItems.Forecast]?.name }}
            </span>
          </SideNavigationBaseLink>
        </li>
        <li v-if="show(MenuItems.Hub).value" class="group">
          <SideNavigationBaseLink
            :path="navigationConfig[MenuItems.Hub]?.url"
            :is-premium="navigationConfig[MenuItems.Hub]?.isPremium"
          >
            <div class="flex h-9 w-9 items-center" aria-hidden="true">
              <LineChartUpIcon
                class="hover-icon h-6 w-6"
                :class="{ 'active-menu': isActive(MenuItems.Hub).value }"
              />
            </div>
            <span class="ml-1 text-base font-medium">
              {{ navigationConfig[MenuItems.Hub]?.name }}
            </span>
          </SideNavigationBaseLink>
        </li>
      </ol>

      <div
        v-if="Object.keys(navigationConfig).length > 0 && hasAdditionalFeatures"
        class="flex w-full flex-col gap-2"
      >
        <button
          class="group flex h-10 w-full cursor-pointer flex-row items-center rounded-md py-2 pl-3 pr-2 transition-colors hover:bg-primary-50 hover:text-bluedark-700 active:bg-primary-100"
          @click="showAllFeatures = !showAllFeatures"
        >
          <div class="flex h-9 w-9 items-center" aria-hidden="true">
            <DotsGridIcon class="hover-icon h-6 w-6" />
          </div>
          <span class="ml-1 text-base font-medium">All features</span>
          <ChevronDownIcon
            class="ml-auto h-4 w-4 rotate-180 transition-transform"
            :class="{
              'rotate-0': showAllFeatures
            }"
          />
        </button>

        <ul v-if="showAllFeatures" class="h-full overflow-clip">
          <li v-if="show(MenuItems.WorkforcePlan).value" class="group">
            <SideNavigationBaseLink
              :path="navigationConfig[MenuItems.WorkforcePlan]?.url"
              :is-premium="navigationConfig[MenuItems.WorkforcePlan]?.isPremium"
            >
              <div class="flex h-9 w-9 items-center" aria-hidden="true">
                <WorkforcePlanIcon
                  class="hover-icon h-6 w-6"
                  :class="{ 'active-menu': isActive(MenuItems.WorkforcePlan).value }"
                />
              </div>
              <span class="ml-1 text-base font-medium">
                {{ navigationConfig[MenuItems.WorkforcePlan]?.name }}
              </span>
            </SideNavigationBaseLink>
          </li>
          <li v-if="show(MenuItems.Headcount).value" class="group">
            <SideNavigationBaseLink
              :path="navigationConfig[MenuItems.Headcount]?.url"
              :is-premium="navigationConfig[MenuItems.Headcount]?.isPremium"
            >
              <div class="flex h-9 w-9 items-center" aria-hidden="true">
                <HeadcountIcon
                  class="hover-icon h-6 w-6"
                  :class="{ 'active-menu': isActive(MenuItems.Headcount).value }"
                />
              </div>
              <span class="ml-1 text-base font-medium">
                {{ navigationConfig[MenuItems.Headcount]?.name }}
              </span>
            </SideNavigationBaseLink>
          </li>
          <li v-if="show(MenuItems.Hiring).value" class="group">
            <SideNavigationBaseLink
              :path="navigationConfig[MenuItems.Hiring]?.url"
              :is-premium="navigationConfig[MenuItems.Hiring]?.isPremium"
            >
              <div class="flex h-9 w-9 items-center" aria-hidden="true">
                <UsersIcon
                  class="h-6 w-6 text-gray-600 group-hover:text-bluedark-700"
                  :class="{ 'text-bluedark-700': isActive(MenuItems.Hiring).value }"
                />
              </div>
              <span class="ml-1 text-base font-medium">
                {{ navigationConfig[MenuItems.Hiring]?.name }}
              </span>
            </SideNavigationBaseLink>
          </li>
          <li v-if="show(MenuItems.Analytics).value" class="group">
            <SideNavigationBaseLink
              :path="navigationConfig[MenuItems.Analytics]?.url"
              :is-premium="navigationConfig[MenuItems.Analytics]?.isPremium"
            >
              <div class="flex h-9 w-9 items-center" aria-hidden="true">
                <AnalyticsIcon
                  class="hover-icon h-6 w-6"
                  :class="{ 'active-menu': isActive(MenuItems.Analytics).value }"
                />
              </div>
              <span class="ml-1 text-base font-medium">
                {{ navigationConfig[MenuItems.Analytics]?.name }}
              </span>
            </SideNavigationBaseLink>
          </li>
          <li v-if="show(MenuItems.Compensation).value" class="group">
            <SideNavigationBaseLink
              :path="navigationConfig[MenuItems.Compensation]?.url"
              :is-premium="navigationConfig[MenuItems.Compensation]?.isPremium"
            >
              <!-- <div class="flex h-9 w-9 items-center" aria-hidden="true">
                <AnalyticsIcon class="hover-icon h-6 w-6" />
              </div> -->
              <span class="ml-1 text-base font-medium">
                {{ navigationConfig[MenuItems.Compensation]?.name }}
              </span>
            </SideNavigationBaseLink>
          </li>
          <li v-if="show(MenuItems.Benchmark).value" class="group">
            <SideNavigationBaseLink
              :path="navigationConfig[MenuItems.Benchmark]?.url"
              :is-premium="navigationConfig[MenuItems.Benchmark]?.isPremium"
            >
              <!-- <div class="flex h-9 w-9 items-center" aria-hidden="true">
                <AnalyticsIcon class="hover-icon h-6 w-6" />
              </div> -->
              <span class="ml-1 text-base font-medium">
                {{ navigationConfig[MenuItems.Benchmark].name }}
              </span>
            </SideNavigationBaseLink>
          </li>
        </ul>
      </div>
    </div>

    <hr class="my-4 mt-2 w-full" />

    <SideNavigationUserProfile />
  </nav>

  <NewScenarioModal
    :show="showNewScenarioModal"
    :board-obj="masterBoard"
    :selected-option="createScenarioOptionSelected"
    @close="closeNewScenarioModal"
  />

  <CreateNewPlanModal
    :show="showCreatePlanModal"
    @close="showCreatePlanModal = false"
    @handle-selection="handleCreateScenarioOptionClick"
  />
</template>

<script setup>
import Logo from '@/assets/Logo/Logo-Border.svg'
import AnalyticsIcon from '@/assets/SvgIcons/bar-chart-10.svg'
import HeadcountIcon from '@/assets/SvgIcons/currency-dollar-circle.svg'
import DataFlowIcon from '@/assets/SvgIcons/dataflow-04.svg'
import DotsGridIcon from '@/assets/SvgIcons/dots-grid.svg'
import ListIcon from '@/assets/SvgIcons/list.svg'
import LineChartUpIcon from '@/assets/SvgIcons/LineChartUpIcon.svg'
import WorkforcePlanIcon from '@/assets/untitled-ui/bar-line-chart.svg'
import BaseButton from '@/components/BaseButton.vue'
import CreateNewPlanModal from '@/components/CreateNewPlanModal.vue'
import NewScenarioModal from '@/components/NewScenarioModal.vue'
import ScrollOnHover from '@/components/ScrollOnHover.vue'
import SideNavigationBaseLink from '@/components/SideNavigationBaseLink.vue'
import SideNavigationUserProfile from '@/components/SideNavigationUserProfile.vue'
import useBoard from '@/hooks/use-board'
import useNavigation from '@/hooks/use-navigation'
import NewPlanOptions from '@/lib/NewPlanOptions'
import UserTier from '@/lib/UserTier'
import { MenuItems } from '@/utils/navigationData'
import { ChartBarIcon, UsersIcon } from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { computed, nextTick, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import MainBoardPill from '@/components/MainBoardPill.vue'

const { dispatch, getters } = useStore()
const { boardId } = useBoard()
const { navigationConfig } = useNavigation()
const route = useRoute()

const showAllFeatures = ref(false)
const createScenarioOptionSelected = ref(NewPlanOptions.Company)
const showNewScenarioModal = ref(false)
const showCreatePlanModal = ref(false)
const plansList = ref(null)
const plansScroll = ref(plansList.value?.scrollTop || 0)

const isTrial = computed(() => getters.tier === UserTier.Trial)
const isFree = computed(() => getters.tier === UserTier.Free)
const landingUrl = computed(() => `/${boardId.value}`)
const user = computed(() => getters.user)
const plans = computed(() => getters.plans(boardId.value))
const plansCount = computed(() => plans.value.length)
const topPlans = computed(() => {
  const result = [...plans.value]
  result.sort((a, b) => a.updatedAt.seconds - b.createdAt.seconds)

  return result //.slice(-10)
})
const show = (menuItem) => computed(() => navigationConfig.value[menuItem]?.show || false)
const masterBoard = computed(() =>
  getters.mainBoards.find((board) => board.boardId === boardId.value)
)

const hasAdditionalFeatures = computed(() => {
  const {
    [MenuItems.Hiring]: hiring,
    [MenuItems.Analytics]: analytics,
    [MenuItems.Headcount]: headcount,
    [MenuItems.Benchmark]: benchmark,
    [MenuItems.Compensation]: compensation
  } = navigationConfig.value

  return hiring.show || analytics.show || headcount.show || benchmark.show || compensation.show
})

const getPlanUrl = (plan) => `/plan/${boardId.value}/${plan.boardId}`

const handleCreateScenarioOptionClick = (option) => {
  createScenarioOptionSelected.value = option
  showNewScenarioModal.value = true
}

/**
 * Fetch all boards data again when close this modal
 */
const closeNewScenarioModal = async () => {
  showNewScenarioModal.value = false
  await dispatch('fetchAllBoards', user.value.uid)
}

const isActive = (menuItem) =>
  computed(
    () =>
      route.fullPath === navigationConfig.value[menuItem]?.url &&
      !navigationConfig[menuItem]?.isPremium
  )

watch(
  topPlans,
  () => {
    nextTick(() => {
      //scroll plansList element to the bottom
      if (plansList.value) plansList.value.scrollTop = plansList.value.scrollHeight
    })
  },
  {
    immediate: true
  }
)
</script>

<style scoped>
.hover-icon :deep(path) {
  @apply stroke-gray-600 group-hover:stroke-bluedark-700;
}

.active-menu :deep(path) {
  @apply stroke-bluedark-700;
}
</style>
