<template>
  <div
    ref="widthSource"
    class="org-node group relative flex h-full max-w-[232px] flex-col rounded-xl bg-white shadow-md ring-1 ring-gray-300 transition-all transition-all duration-200 hover:ring-2 hover:ring-bluedark-300"
    :class="{
      ...(isRemovedManager ? {} : cardWrapperClass),
      'bg-error-25 ring-error-500 hover:bg-error-50 hover:ring-error-700': isRemovedManager,
      'ring-3 hover:ring-3 shadow-xl': dragging === 'true',
      'shadow-3xl border border-dashed border-gray-600': draggingOver === 'true'
    }"
    :style="{
      opacity: opacity
    }"
    data-intercom-target="avatar-card"
  >
    <div
      :class="{
        'blur-sm': isRemovedManager
      }"
    >
      <div v-if="!isNoManager && !hideImages" class="absolute w-full -translate-y-1/2">
        <img
          v-if="avatarUrl && isFieldEnabled(Fields.name)"
          class="mx-auto h-12 w-12 rounded-full object-cover"
          :src="avatarUrl"
          :onerror="`this.src='https://ui-avatars.com/api/?name=${name}&background=50B5FF&color=fff'`"
        />
      </div>
      <template v-if="isRif">
        <transition
          enter-active-class="transform transition-all"
          enter-from-class="opacity-0 scale-50"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="transition-all transform"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-50"
        >
          <div
            class="font-xs absolute -top-3 right-6 flex items-center rounded-full bg-orangedark-700 px-2 py-1 font-medium text-white"
          >
            <UserMinusIcon class="white-icon mr-1 h-3 w-3 stroke-white" />
            RIF
          </div>
        </transition>
      </template>
      <template v-else-if="mode === modes.hiresLeavers || mode === modes.changes">
        <div
          v-if="isPersonLeaving"
          class="font-xs absolute -top-3 right-4 rounded-full bg-orangedark-700 px-2 py-1 font-medium text-white"
        >
          Leaving
        </div>
        <div
          v-else-if="isNewHire"
          class="font-xs absolute -top-3 right-4 rounded-full bg-green-700 px-2 py-1 font-medium text-white"
        >
          New hire
        </div>
        <div
          v-else-if="isEdited && mode === modes.changes"
          class="font-xs absolute -top-3 right-4 rounded-full bg-yellow-700 px-2 py-1 font-medium text-yellow-50"
        >
          Changed
        </div>
      </template>
      <div class="w-full overflow-hidden">
        <div
          ref="heightSource"
          class="relative z-10 flex w-full cursor-grab flex-col gap-4 px-4 pb-4"
          :class="{
            'pt-4': !isFieldEnabled(Fields.name),
            'pt-8': isFieldEnabled(Fields.name)
          }"
        >
          <div class="w-full">
            <div
              class="flex flex-col items-center justify-center transition-colors duration-200"
              :class="{
                ...(isRemovedManager ? {} : cardBg),
                'bg-error-25 group-hover:bg-error-50': isRemovedManager
              }"
            >
              <div
                v-if="isFieldEnabled(Fields.name)"
                class="min-h-fit w-full cursor-pointer truncate text-center text-base font-normal text-gray-900 hover:text-blue-600"
                @click="emit('toggle-profile')"
              >
                {{ name }}
              </div>

              <div
                class="min-h-fit w-full text-center"
                :class="{
                  'text-sm font-light text-gray-500': isFieldEnabled(Fields.name),
                  'text-base font-normal text-gray-700': !isFieldEnabled(Fields.name)
                }"
              >
                {{ role }}
              </div>

              <div
                v-if="!isNoManager && !isRemovedManager"
                class="-ml-1 mt-1.5 flex w-full flex-wrap items-center justify-center transition-all"
              >
                <transition
                  enter-active-class="transform transition-all"
                  enter-from-class="opacity-0 scale-50"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition-all transform"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-50"
                >
                  <div
                    v-if="department && isFieldEnabled(Fields.department)"
                    class="ml-1 mt-1 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
                    :class="pillStyle"
                  >
                    {{ department }}
                  </div>
                </transition>

                <transition
                  enter-active-class="transform transition-all"
                  enter-from-class="opacity-0 scale-50"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition-all transform"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-50"
                >
                  <div
                    v-if="officeLocation && isFieldEnabled(Fields.location)"
                    class="ml-1 mt-1 flex max-h-6 flex-row items-center gap-1 truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
                    :class="pillStyle"
                  >
                    <LocationMarkerIcon class="color-icon w-3" />
                    {{ officeLocation }}
                  </div>
                </transition>

                <transition
                  enter-active-class="transform transition-all"
                  enter-from-class="opacity-0 scale-50"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition-all transform"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-50"
                >
                  <div
                    v-if="isFieldEnabled(Fields.email) && person?.email"
                    class="ml-1 mt-1 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
                    :class="pillStyle"
                  >
                    {{ person.email }}
                  </div>
                </transition>

                <transition
                  enter-active-class="transform transition-all"
                  enter-from-class="opacity-0 scale-50"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition-all transform"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-50"
                >
                  <div
                    v-if="isFieldEnabled(Fields.salary) && localiseCurrency(salaryNumber)"
                    class="ml-1 mt-1 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
                    :class="pillStyle"
                  >
                    {{ salaryNumber === 0 ? 'N/A' : localiseCurrency(salaryNumber) }}
                  </div>
                </transition>

                <template v-for="field in selectedFields" :key="field">
                  <transition
                    enter-active-class="transform transition-all"
                    enter-from-class="opacity-0 scale-50"
                    enter-to-class="opacity-100 scale-100"
                    leave-active-class="transition-all transform"
                    leave-from-class="opacity-100 scale-100"
                    leave-to-class="opacity-0 scale-50"
                  >
                    <div
                      v-if="customFields[field]"
                      class="ml-1 mt-1 flex flex-row items-center truncate rounded-full px-2.5 py-1 text-xs font-normal text-indigo-700 mix-blend-multiply transition-colors duration-300"
                      :class="pillStyle"
                    >
                      {{ customFields[field] }}
                    </div>
                  </transition>
                </template>

                <template v-for="{ formula, name: formulaName } in formulas" :key="formulaName">
                  <transition
                    enter-active-class="transform transition-all"
                    enter-from-class="opacity-0 scale-50"
                    enter-to-class="opacity-100 scale-100"
                    leave-active-class="transition-all transform"
                    leave-from-class="opacity-100 scale-100"
                    leave-to-class="opacity-0 scale-50"
                  >
                    <Popper :content="formulaName" placement="top" hover>
                      <div
                        v-if="isFieldEnabled(`formula_${formulaName}`)"
                        class="ml-1 mt-1 flex flex-row items-center truncate rounded-full px-2.5 py-1 text-xs font-normal text-indigo-700 mix-blend-multiply transition-colors duration-300"
                        :class="pillStyle"
                      >
                        {{ formulaResult(formula).value || 'No data' }}
                      </div>
                    </Popper>
                  </transition>
                </template>

                <transition
                  enter-active-class="transform transition-all"
                  enter-from-class="opacity-0 scale-50"
                  enter-to-class="opacity-100 scale-100"
                  leave-active-class="transition-all transform"
                  leave-from-class="opacity-100 scale-100"
                  leave-to-class="opacity-0 scale-50"
                >
                  <input
                    v-if="isNewHire && isFieldEnabled(Fields.plannedHireDate)"
                    id="plannedHireDateInput"
                    :value="startDate"
                    class="mt-2 ml-2 w-fit rounded-full border-0 py-1 pl-2 font-inter text-xs transition-all placeholder:text-white"
                    :class="pillStyle"
                    placeholder="Hire Date (YYYY-MM)"
                    @change="handleHireDateChange"
                    @keydown.enter="handleHireDateChange"
                    @click.stop
                    @keypress="blockInvalidDateInputInString"
                  />
                </transition>

                <div
                  v-if="scenarioState.length > 0"
                  class="mt-1 flex w-full flex-wrap items-center justify-center gap-2 space-x-2"
                >
                  <span
                    v-for="status in scenarioState.filter((s) => s !== PersonPlanStates.RIF)"
                    :key="status"
                    :class="[
                      'inline-flex items-center rounded-full border px-2.5 py-0.5 font-inter text-xs font-medium',
                      status === PersonPlanStates.NewlyAdded &&
                        'border-purple-300 bg-purple-100 text-purple-700',
                      status === PersonPlanStates.Removed &&
                        'border-error-500 bg-error-50 text-error-700',
                      status === PersonPlanStates.Edited &&
                        'border-primary-300 bg-primary-100 text-primary-700',
                      status === PersonPlanStates.Moved &&
                        'border-success-500 bg-success-50 text-success-700',
                      status === PersonPlanStates.Backfilled && 'bg-blue-50 text-blue-700'
                    ]"
                  >
                    <SmallUserPlusIcon
                      v-if="status === PersonPlanStates.NewlyAdded"
                      class="mr-1 h-3 w-3 stroke-purple-700"
                    />
                    <SmallUserPlusIcon
                      v-if="status === PersonPlanStates.Removed"
                      class="mr-1 h-3 w-3 stroke-error-700"
                    />
                    <PencilUnderlineIcon
                      v-if="status === PersonPlanStates.Edited"
                      class="mr-1 h-3 w-3 stroke-primary-700"
                    />
                    <MoveIcon
                      v-if="status === PersonPlanStates.Moved"
                      class="mr-1 h-3 w-3 stroke-success-700"
                    />
                    {{ status }}
                    {{ status === PersonPlanStates.Backfilled ? `by: ${backfilledByWho}` : '' }}
                  </span>
                </div>
              </div>

              <button
                v-if="!isRemovedManager && spanOfControl > 0 && mode !== modes.executive"
                class="mt-2 flex items-center justify-center rounded-2xl bg-gray-800 py-1 px-2 text-xs text-white transition-all hover:bg-gray-600"
                @click.stop="emit('toggle-collapse')"
                @mousedown.stop
              >
                {{ activeSubordinatesCount }} / {{ spanOfControl }}
                <ChevronDownIcon
                  class="w-4 transition-all"
                  :class="{
                    'rotate-180': isExpanded === 'true'
                  }"
                />
              </button>
            </div>
          </div>

          <div
            v-if="mode === modes.executive && isExecutive"
            class="flex w-full flex-col items-center border-t pt-2"
          >
            <div
              v-if="(managersCount > 0) & (individualContributorCount > 0)"
              class="min-h-6 mt-2 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
              :class="pillStyle"
            >
              {{ managersCount }} : {{ individualContributorCount }}
            </div>

            <div
              v-if="managersCount > 0"
              class="min-h-6 mt-2 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
              :class="pillStyle"
            >
              {{ managersCount }} {{ managersCount === 1 ? 'Manager' : 'Managers' }}
              <span v-if="managerCost > 0">({{ localiseCurrency(managerCost) }}</span>
              / year)
            </div>

            <div
              v-if="individualContributorCount > 0"
              class="min-h-6 mt-2 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
              :class="pillStyle"
            >
              {{ individualContributorCount }}
              {{ individualContributorCount === 1 ? 'IC' : 'ICs' }}

              <span v-if="individualContributorCost > 0">
                ({{ localiseCurrency(individualContributorCost) }} / year)
              </span>
            </div>

            <!-- <div
              v-if="spanOfControl > 0"
              class="min-h-6 mt-2 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
              :class="pillStyle"
            >
              {{ spanOfControl }} {{ spanOfControl === 1 ? 'Employee' : 'Employees' }}
            </div>

            <div
              v-if="teamBurn"
              class="min-h-6 mt-2 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
              :class="pillStyle"
            >
              {{ localiseCurrency(teamBurn) }} / year
            </div> -->
          </div>

          <div
            v-if="mode === modes.executive && isCEO"
            class="flex w-full flex-col items-center border-t pt-2"
          >
            <div
              v-if="managersCount > 0"
              class="min-h-6 mt-2 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
              :class="pillStyle"
            >
              {{ managersCount }} {{ managersCount === 1 ? 'Manager' : 'Managers' }}
              <span v-if="managerCost > 0">({{ localiseCurrency(managerCost) }}</span>
              / year)
            </div>

            <div
              v-if="individualContributorCount > 0"
              class="min-h-6 mt-2 max-h-6 max-w-fit items-center truncate rounded-full px-2.5 py-1 text-xs font-normal mix-blend-multiply transition-colors duration-300"
              :class="pillStyle"
            >
              {{ individualContributorCount }}
              {{ individualContributorCount === 1 ? 'IC' : 'ICs' }}

              <span v-if="individualContributorCost > 0">
                ({{ localiseCurrency(individualContributorCost) }} / year)
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- overlay buttons -->
      <div
        v-if="!isRemovedManager && !isNoManager && showHover"
        class="absolute top-0 z-30 flex h-6 w-full translate-y-0 scale-0 justify-center opacity-0 transition-all group-hover:-translate-y-full group-hover:scale-100 group-hover:opacity-100"
        @click.stop
        @mousedown.stop
      >
        <div
          class="overlay-buttons absolute top-0 -translate-y-5 rounded-lg bg-white drop-shadow-lg transition-opacity"
          :class="{
            'opacity-0': dragging === 'true'
          }"
        >
          <Popper content="Edit" placement="top" hover class="top-10">
            <button
              type="button"
              class="items-center rounded-l-lg px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="emit('edit-user')"
            >
              <EditIcon class="h-5 w-5" />
            </button>
          </Popper>
          <Popper
            v-if="!isBackfilled"
            :content="isRif ? 'Remove Reduction In Force' : 'Mark as Reduction in Force'"
            placement="top"
            hover
            class="top-10"
          >
            <button
              type="button"
              class="items-center px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="emit('mark-as-rif')"
            >
              <UserPlus v-if="isRif" class="h-5 w-5" />
              <UserMinusIcon v-else class="h-5 w-5" />
            </button>
          </Popper>
          <Popper content="Delete" placement="top" hover class="top-10">
            <button
              type="button"
              class="items-center rounded-r-lg px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="emit('delete-user')"
            >
              <TrashIcon class="h-5 w-5" />
            </button>
          </Popper>
          <Popper
            :content="`Duplicate (${getShortcutWithCmdOrCtrl('d')})`"
            placement="top"
            hover
            class="top-10"
          >
            <button
              type="button"
              class="items-center rounded-r-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="handleDuplicate"
            >
              <CopyIcon class="icon h-5 w-5" />
            </button>
          </Popper>
        </div>
      </div>
      <div
        v-if="!isRemovedManager && !isNoManager && showHover"
        class="absolute bottom-0 z-30 flex h-6 w-full translate-y-0 scale-0 justify-center opacity-0 transition-all group-hover:translate-y-full group-hover:scale-100 group-hover:opacity-100"
        @click.stop
        @mousedown.stop
      >
        <div
          class="overlay-buttons absolute top-5 -translate-y-2 rounded-lg bg-white drop-shadow-lg transition-opacity"
          :class="{
            'opacity-0': dragging === 'true'
          }"
        >
          <Popper content="Add direct report" placement="top" hover>
            <button
              class="items-center rounded-l-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              :class="{
                'opacity-0': dragging === 'true'
              }"
              @click="emit('add-user')"
              @click.stop
              @mousedown.stop
            >
              <UserPlus class="h-auto w-5" />
            </button>
          </Popper>
          <Popper
            :content="isBackfilled ? 'Remove backfill' : 'Add backfill'"
            placement="top"
            hover
          >
            <button
              class="items-center rounded-r-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              :class="{
                'opacity-0': dragging === 'true'
              }"
              @click="emit('add-remove-backfill')"
              @click.stop
              @mousedown.stop
            >
              <ReplaceIcon class="h-auto w-5" />
            </button>
          </Popper>
        </div>
      </div>
      <!-- end buttons -->
    </div>
    <template v-if="isRemovedManager">
      <div
        class="absolute flex h-full w-full flex-col items-center justify-center space-y-4 rounded-xl bg-error-25 bg-opacity-70 ring-1 ring-error-500 transition-all duration-300 hover:ring-2 hover:ring-error-700"
      >
        <span
          class="rounded-full bg-error-100 px-2 py-1 font-medium text-error-700 bg-blend-multiply"
        >
          Missing manager
        </span>
        <div class="flex gap-2">
          <BaseButton
            v-if="mode !== modes.executive"
            class="h-9 w-11"
            color="white"
            @click.stop="emit('undo-delete-manager')"
          >
            <Popper content="Undo delete" placement="top" hover class="top-10">
              <RefreshIcon class="h-auto w-5 stroke-bluedark-700" />
            </Popper>
          </BaseButton>
          <BaseButton
            v-if="mode !== modes.executive"
            class="h-9 w-11"
            color="white"
            @click.stop="emit('replace-user')"
          >
            <Popper content="Add a new manager" placement="top" hover class="top-10">
              <PlusCircleIcon class="h-auto w-5" />
            </Popper>
          </BaseButton>
          <BaseButton
            v-if="mode !== modes.executive"
            class="h-9 w-11"
            color="white"
            @click.stop="emit('remove-team')"
          >
            <Popper content="Remove entire team" placement="top" hover class="top-10">
              <UserRemoveIcon class="h-auto w-5 text-bluedark-700" />
            </Popper>
          </BaseButton>
        </div>
        <button
          v-if="spanOfControl > 0 && mode !== modes.executive"
          class="flex items-center justify-center rounded-2xl bg-gray-800 py-1 px-2 text-xs text-white transition-all hover:bg-gray-600"
          @click.stop="emit('toggle-collapse')"
          @mousedown.stop
        >
          {{ activeSubordinatesCount }} / {{ spanOfControl }}
          <ChevronDownIcon
            class="w-4 transition-all"
            :class="{
              'rotate-180': isExpanded === 'true'
            }"
          />
        </button>
      </div>
    </template>
    <div
      class="absolute bottom-0 z-40 flex h-6 h-fit w-full justify-center transition-all"
      :class="{
        'translate-y-0 scale-0 opacity-0': !dragging || dragging === 'false',
        'translate-y-full scale-100 opacity-100': dragging === 'true'
      }"
      @click.stop
      @mousedown.stop
    >
      <div
        class="flex h-auto translate-y-2 rounded-lg bg-white px-3 py-2 font-medium text-gray-700 shadow-lg transition-all"
      >
        <p v-if="dragTarget && dragging === 'true'" class="text-xs">
          Drop here to set
          <span class="font-bold">{{ potentialManager }}</span>
          as manager
        </p>
        <p v-else>Drag over a card</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import CopyIcon from '@/assets/Feather/Copy.svg'
import EditIcon from '@/assets/Feather/Edit.svg'
import PlusCircleIcon from '@/assets/Feather/plus-circle.svg'
import UserMinusIcon from '@/assets/Feather/user-minus.svg'
import UserPlus from '@/assets/Feather/UserPlus01.svg'
import MoveIcon from '@/assets/SvgIcons/MoveIcon.svg'
import PencilUnderlineIcon from '@/assets/SvgIcons/PencilUnderlineIcon.svg'
import RefreshIcon from '@/assets/SvgIcons/Refresh.svg'
import ReplaceIcon from '@/assets/SvgIcons/ReplaceIcon.svg'
import SmallUserPlusIcon from '@/assets/SvgIcons/SmallUserPlusIcon.svg'
import TrashIcon from '@/assets/SvgIcons/TrashIcon.svg'
import BaseButton from '@/components/BaseButton.vue'
import useChartMode, { modes } from '@/hooks/use-chart-mode.js'
import useCompensation from '@/hooks/use-compensation.js'
import useOrgChartColors from '@/hooks/use-org-chart-colors.js'
import useOrgNodeHelpers from '@/hooks/use-org-node-helpers.js'
import useScenarioFields from '@/hooks/use-scenario-fields.js'
import { getShortcutWithCmdOrCtrl } from '@/lib/KeyboardShortcuts'
import {
  getCustomFieldsDict,
  isLeaving,
  isRif as isRifImpl,
  isBackfilled as isBackfilledImpl,
  isRemovedManager as isRemovedManagerImpl,
  isEdited as isEditedImpl
} from '@/lib/PersonDataProcessing.js'
import PersonPlanStates from '@/lib/PersonPlanStates'
import { Fields } from '@/lib/PlanFields'
import { localiseCurrency } from '@/services/currency.service.js'
import store from '@/store/index.js'
import { LocationMarkerIcon, UserRemoveIcon } from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { useElementHover, useElementSize, useMagicKeys, useResizeObserver } from '@vueuse/core'
import { DateTime } from 'luxon'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import Popper from 'vue3-popper'
import { evaluate, getVariablesInFormula, mapDataToVariables } from '@/services/formula.service.js'
import { blockInvalidDateInputInString } from '@/services/date.service'

const props = defineProps({
  boardId: {
    type: String,
    required: true
  },
  personId: {
    type: String,
    required: true
  },
  opacity: {
    type: Number,
    default: 1
  },
  isExpanded: {
    type: [Boolean, String],
    default: false
  },
  enableMenuButton: {
    type: Boolean,
    default: false
  },
  activeSubordinatesCount: {
    type: Number,
    default: 0
  },
  spanOfControl: {
    type: Number,
    default: 0
  },
  enableHover: {
    type: String,
    default: 'true'
  },
  dragging: {
    type: String,
    default: 'false'
  },
  dragTarget: {
    type: String,
    default: ''
  },
  draggingOver: {
    type: String,
    default: ''
  }
})

const emit = defineEmits([
  'toggle-menu',
  'toggle-profile',
  'toggle-collapse',
  'add-user',
  'add-remove-backfill',
  'add-user-from-template',
  'edit-user',
  'delete-user',
  'paste-user',
  'mark-as-rif',
  'replace-user',
  'undo-delete-manager',
  'duplicate-user',
  'hire-date-change'
])

const widthSource = ref(null)
const heightSource = ref(null)
const pulseAnimation = ref(false)
const highlightAnimation = ref(false)

const { isSelected: isFieldEnabled, selectedFields } = useScenarioFields()
const { updateWidth, updateHeight, setHovered, resetHovered, hideImages, highlightedNode } =
  useOrgNodeHelpers()
const isHovered = useElementHover(widthSource)
const { backgroundColorVariant } = useOrgChartColors()
const { mode } = useChartMode()
const { totalFor, currentSalaryInBaseCurrency } = useCompensation()

useMagicKeys({
  passive: false,
  onEventFired(e) {
    if ((e.metaKey || e.ctrlKey) && e.key === 'v' && e.type === 'keydown' && isHovered.value) {
      e.preventDefault()
      handlePaste()
    }

    if ((e.metaKey || e.ctrlKey) && e.key === 'd' && e.type === 'keydown' && isHovered.value) {
      window.mixpanel.track('node_keyboard_duplicate')
      e.preventDefault()
      handleDuplicate()
    }
  }
})

const bgVariant = computed(() => backgroundColorVariant(props.personId))

onMounted(() => {
  const { width } = useElementSize(widthSource)

  useResizeObserver(heightSource, (entries) => {
    const entry = entries[0]
    updateHeight({ id: props.personId, height: entry?.target?.clientHeight })
  })

  watch(width, () => {
    updateWidth({ id: props.personId, width: width.value })
  })

  watch(isHovered, () => {
    if (isHovered.value) setHovered(props.personId)
    else resetHovered(props.personId)
  })
})

const showHover = computed(() => props.enableHover === 'true')
const person = computed(() => personById(props.personId).value)
const isPersonLeaving = computed(() => isLeaving(person.value))
const customFields = computed(() => getCustomFieldsDict(person.value?.customFields))
const isExecutive = computed(() =>
  store.getters['people/executives'](props.boardId).includes(person.value?.personId)
)
const isNewHire = computed(
  () =>
    person.value?.employeeData?.startDate &&
    DateTime.fromFormat(person.value?.employeeData?.startDate, 'yyyy-MM-dd').diffNow('days').days >
      0
)

const name = computed(() => {
  return person.value?.name || ''
})

const avatarUrl = computed(() => {
  return person.value?.avatarImg || ''
})

const role = computed(() => {
  return person.value?.role || ''
})

const department = computed(() => {
  return person.value?.employeeData?.department || ''
})

const officeLocation = computed(() => {
  return person.value?.officeLocation || ''
})

const isNoManager = computed(() => {
  return person.value?.isNoManagerNode || false
})

const isCEO = computed(() => {
  const roots = store.getters['people/getRoots'](props.boardId)
  return roots.find((root) => root.personId === props.personId) ? true : false
})
const isRif = computed(() => isRifImpl(person.value))

const isBackfilled = computed(() => isBackfilledImpl(person.value))
const backfilledByWho = computed(
  () =>
    store.getters['people/personById']({
      boardId: props.boardId,
      personId: person.value?.scenarioMetaData?.byWho
    })?.name
)
const isRemovedManager = computed(() => isRemovedManagerImpl(person.value))
const isEdited = computed(() => isEditedImpl(person.value))

const personById = (personId) =>
  computed(() => store.getters['people/personById']({ personId, boardId: props.boardId }))

const formulas = computed(() => store.getters.formulas)

const formulaResult = (formula) =>
  computed(() => {
    const variables = getVariablesInFormula(formula)
    return evaluate({
      formula,
      variables: mapDataToVariables({
        salaryNumber: salaryNumber.value,
        person: person.value,
        variables
      })
    })
  })

// "showHover" is false in Org Chart view. Treat it as temporary fix until we move all charts to be "plans" and we'll
// need more robust context for Org Node context
const scenarioState = computed(
  () => (showHover.value && person.value?.scenarioMetaData?.state) || []
)

const managersInSubtree = computed(() =>
  store.getters['people/managersInSubtree']({
    boardId: props.boardId,
    personId: props.personId
  })
)

const managersCount = computed(() => managersInSubtree.value.length)

const managerCost = computed(() =>
  totalFor({
    peopleIds: managersInSubtree.value,
    boardId: props.boardId
  })
)

const individualContributorsInSubtree = computed(() =>
  store.getters['people/individualContributorsInSubtree']({
    boardId: props.boardId,
    personId: props.personId
  })
)

const individualContributorCount = computed(() => individualContributorsInSubtree.value.length)

const individualContributorCost = computed(() =>
  totalFor({
    peopleIds: individualContributorsInSubtree.value,
    boardId: props.boardId
  })
)

const salaryNumber = currentSalaryInBaseCurrency({
  personId: props.personId,
  boardId: props.boardId
})

const potentialManager = computed(() => {
  const personData = personById(props.dragTarget).value

  const showRole = personData.name === 'Planned role' || !isFieldEnabled(Fields.name)
  const nameOrRole = showRole ? personData.role : personData.name

  return personData?.scenarioMetaData?.state?.includes(PersonPlanStates.RemovedManager)
    ? 'Missing manager'
    : nameOrRole
})

const startDate = computed(() =>
  !isEmpty(person.value?.employeeData?.startDate)
    ? moment(person.value?.employeeData?.startDate).format('YYYY-MM')
    : ''
)

const pillStyle = computed(() => {
  return {
    'bg-indigo-50 text-indigo-700 mix-blend-multiply bg-blend-multiply': !bgVariant.value,

    'bg-moss-700 text-moss-50 group-hover:text-moss-100': bgVariant.value === 0,
    'bg-bluedark-700 text-bluedark-50 group-hover:text-bluedark-100': bgVariant.value === 1,
    'bg-rose-700 text-rose-50 group-hover:text-rose-100': bgVariant.value === 2,
    'bg-orangedark-700 text-orangedark-50 group-hover:text-orangedark-100': bgVariant.value === 3,
    'bg-greenlight-700 text-greenlight-50 group-hover:text-greenlight-100': bgVariant.value === 4,
    'bg-blue-700 text-blue-50 group-hover:text-blue-100': bgVariant.value === 5,
    'bg-orange-700 text-orange-50 group-hover:text-orange-100': bgVariant.value === 6,
    'bg-pink-700 text-pink-50 group-hover:text-pink-100': bgVariant.value === 7,
    'bg-bluelight-700 text-bluelight-50 group-hover:text-bluelight-100': bgVariant.value === 8,
    'bg-indigo-700 text-indigo-50 group-hover:text-indigo-100': bgVariant.value === 9,
    'bg-fuchsia-700 text-fuchsia-50 group-hover:text-fuchsia-100': bgVariant.value === 10,
    'bg-green-700 text-green-50 group-hover:text-green-100': bgVariant.value === 11,
    'bg-cyan-700 text-cyan-50 group-hover:text-cyan-100': bgVariant.value === 12,
    'bg-purple-700 text-purple-50 group-hover:text-purple-100': bgVariant.value === 13,
    'bg-violet-700 text-violet-50 group-hover:text-violet-100': bgVariant.value === 14,
    'bg-yellow-700 text-yellow-50 group-hover:text-yellow-100': bgVariant.value === 15,
    'bg-teal-700 text-teal-50 group-hover:text-teal-100': bgVariant.value === 16,
    'edited bg-yellow-700 text-yellow-50 group-hover:text-yellow-100':
      !isNewHire.value &&
      !isPersonLeaving.value &&
      !isRif.value &&
      isEdited.value &&
      mode.value === modes.changes,
    'new-hire bg-green-700 text-green-50 group-hover:text-green-100':
      isNewHire.value && (mode.value === modes.hiresLeavers || mode.value === modes.changes),
    'leaving bg-orangedark-700 text-orangedark-50 group-hover:text-orangedark-100':
      isPersonLeaving.value && (mode.value === modes.hiresLeavers || mode.value === modes.changes)
  }
})

const cardWrapperClass = computed(() => ({
  'bg-white ring-1 ring-gray-300 hover:bg-bluedark-100 hover:ring-bluedark-300': !bgVariant.value,
  'bg-moss-25 ring-1 ring-moss-300 hover:bg-moss-100 hover:ring-moss-400': bgVariant.value === 0,
  'bg-bluedark-25 ring-bluedark-300 hover:bg-bluedark-100 hover:ring-bluedark-400':
    bgVariant.value === 1,
  'bg-rose-25 ring-rose-300 hover:bg-rose-100 hover:ring-rose-400': bgVariant.value === 2,
  'bg-orangedark-25 ring-orangedark-300 hover:bg-orangedark-100 hover:ring-orangedark-400':
    bgVariant.value === 3,
  'bg-greenlight-25 ring-greenlight-300 hover:bg-greenlight-100 hover:ring-greenlight-400':
    bgVariant.value === 4,
  'bg-blue-25 ring-blue-300 hover:bg-blue-100 hover:ring-blue-400': bgVariant.value === 5,
  'bg-orange-25 ring-orange-300 hover:bg-orange-100 hover:ring-orange-400': bgVariant.value === 6,
  'bg-pink-25 ring-pink-300 hover:bg-pink-100 hover:ring-pink-400': bgVariant.value === 7,
  'bg-bluelight-25 ring-bluelight-300 hover:bg-bluelight-100 hover:ring-bluelight-400':
    bgVariant.value === 8,
  'bg-indigo-25 ring-indigo-300 hover:bg-indigo-100 hover:ring-indigo-400': bgVariant.value === 9,
  'bg-fuchsia-25 ring-fuchsia-300 hover:bg-fuchsia-100 hover:ring-fuchsia-400':
    bgVariant.value === 10,
  'bg-green-25 ring-green-300 hover:bg-green-100 hover:ring-green-400': bgVariant.value === 11,
  'bg-cyan-25 ring-cyan-300 hover:bg-cyan-100 hover:ring-cyan-400': bgVariant.value === 12,
  'bg-purple-25 ring-purple-300 hover:bg-purple-100 hover:ring-purple-400': bgVariant.value === 13,
  'bg-violet-25 ring-violet-300 hover:bg-violet-100 hover:ring-violet-400': bgVariant.value === 14,
  'bg-yellow-25 ring-yellow-300 hover:bg-yellow-100 hover:ring-yellow-400': bgVariant.value === 15,
  'bg-teal-25 ring-teal-300 hover:bg-teal-100 hover:ring-teal-400': bgVariant.value === 16,
  'edited bg-yellow-25 ring-yellow-300 hover:bg-yellow-100 hover:ring-yellow-400':
    !isNewHire.value &&
    !isPersonLeaving.value &&
    !isRif.value &&
    isEdited.value &&
    mode.value === modes.changes,
  'new-hire bg-green-25 ring-green-300 hover:bg-green-100 hover:ring-green-400':
    isNewHire.value && (mode.value === modes.hiresLeavers || mode.value === modes.changes),
  'leaver bg-orangedark-25 ring-orangedark-300 hover:bg-orangedark-100 hover:ring-orangedark-400':
    (isPersonLeaving.value &&
      (mode.value === modes.hiresLeavers || mode.value === modes.changes)) ||
    isRif.value,

  pulseAnimation: pulseAnimation.value,
  highlightAnimation: highlightAnimation.value
}))

const cardBg = computed(() => ({
  'bg-white group-hover:bg-bluedark-100': !bgVariant.value,

  'bg-moss-25 group-hover:bg-moss-100': bgVariant.value === 0,
  'bg-bluedark-25 group-hover:bg-bluedark-100': bgVariant.value === 1,
  'bg-rose-25 group-hover:bg-rose-100': bgVariant.value === 2,
  'bg-orangedark-25 group-hover:bg-orangedark-100': bgVariant.value === 3,
  'bg-greenlight-25 group-hover:bg-greenlight-100': bgVariant.value === 4,
  'bg-blue-25 group-hover:bg-blue-100': bgVariant.value === 5,
  'bg-orange-25 group-hover:bg-orange-100': bgVariant.value === 6,
  'bg-pink-25 group-hover:bg-pink-100': bgVariant.value === 7,
  'bg-bluelight-25 group-hover:bg-bluelight-100': bgVariant.value === 8,
  'bg-indigo-25 group-hover:bg-indigo-100': bgVariant.value === 9,
  'bg-fuchsia-25 group-hover:bg-fuchsia-100': bgVariant.value === 10,
  'bg-green-25 group-hover:bg-green-100': bgVariant.value === 11,
  'bg-cyan-25 group-hover:bg-cyan-100': bgVariant.value === 12,
  'bg-purple-25 group-hover:bg-purple-100': bgVariant.value === 13,
  'bg-violet-25 group-hover:bg-violet-100': bgVariant.value === 14,
  'bg-yellow-25 group-hover:bg-yellow-100': bgVariant.value === 15,
  'bg-teal-25 group-hover:bg-teal-100': bgVariant.value === 16,
  'edited bg-yellow-25 group-hover:bg-yellow-100':
    !isNewHire.value &&
    !isPersonLeaving.value &&
    !isRif.value &&
    isEdited.value &&
    mode.value === modes.changes,
  'new-hire bg-green-25 group-hover:bg-green-100':
    isNewHire.value && (mode.value === modes.hiresLeavers || mode.value === modes.changes),
  'leaving bg-orangedark-25 group-hover:bg-orangedark-100':
    (isPersonLeaving.value &&
      (mode.value === modes.hiresLeavers || mode.value === modes.changes)) ||
    isRif.value
}))

const iconColor = computed(() => ({
  'stroke-white': !bgVariant.value,
  'stroke-moss-25': bgVariant.value === 0,
  'stroke-bluedark-25 ': bgVariant.value === 1,
  'stroke-rose-25 ': bgVariant.value === 2,
  'stroke-orangedark-25 ': bgVariant.value === 3,
  'stroke-greenlight-25 ': bgVariant.value === 4,
  'stroke-blue-25 ': bgVariant.value === 5,
  'stroke-orange-25 ': bgVariant.value === 6,
  'stroke-pink-25 ': bgVariant.value === 7,
  'stroke-bluelight-25 ': bgVariant.value === 8,
  'stroke-indigo-25 ': bgVariant.value === 9,
  'stroke-fuchsia-25 ': bgVariant.value === 10,
  'stroke-green-25 ': bgVariant.value === 11,
  'stroke-cyan-25 ': bgVariant.value === 12,
  'stroke-purple-25 ': bgVariant.value === 13,
  'stroke-violet-25 ': bgVariant.value === 14,
  'stroke-yellow-25 ': bgVariant.value === 15,
  'stroke-teal-25 ': bgVariant.value === 16
}))

let pulseAnimationSwitch = null
let highlightAnimationSwitch = null
const triggerPulseAnimation = () => {
  if (pulseAnimationSwitch) {
    clearTimeout(pulseAnimationSwitch)
    pulseAnimation.value = false
  }

  nextTick(() => {
    pulseAnimation.value = true
    pulseAnimationSwitch = setTimeout(() => {
      pulseAnimation.value = false
    }, 1000)
  })
}

const triggerHighlightAnimation = () => {
  if (highlightAnimationSwitch) {
    clearTimeout(highlightAnimationSwitch)
    highlightAnimation.value = false
  }

  nextTick(() => {
    highlightAnimation.value = true
    highlightAnimationSwitch = setTimeout(() => {
      highlightAnimation.value = false
    }, 1000)
  })
}

/**
 * Handle hire date change
 * @param {Event} event
 */
const handleHireDateChange = (event) => {
  emit('hire-date-change', event.target.value)
}

const handlePaste = () => {
  if (!isHovered.value) return
  emit('paste-user')
  triggerPulseAnimation()
}

const handleDuplicate = () => {
  emit('duplicate-user')
}

watch(highlightedNode, () => {
  if (highlightedNode.value === props.personId) {
    triggerHighlightAnimation()
  }
})
</script>

<style scoped>
@import url('../index.css');

.org-node {
  font-family: 'Inter';
}

.dragging-over {
  @apply scale-110 shadow-lg shadow-bluedark-100;
}

.color-icon svg :deep(path) {
  stroke: v-bind(iconColor);
}

.white-icon :deep(path) {
  stroke: white;
}

.overlay-buttons svg :deep(path) {
  @apply stroke-gray-900;
}

:deep(.popper) {
  @apply pointer-events-none cursor-pointer whitespace-nowrap rounded-lg bg-black px-3 py-2 text-xs text-white shadow-lg !important;
}

.pulseAnimation {
  @apply animate-[pulse_0.3s_ease-in-out];
}

.highlightAnimation {
  @apply ring-4 ring-success-400;
}
</style>
