import store from '@/store'
import { computed, readonly, ref, watchEffect } from 'vue'
import { useRoute } from 'vue-router'

/**
 * Hook that encapsulates data related to a board
 */
export default function useBoard() {
  const route = useRoute()

  const boards = computed(() => store.getters.mainBoards)
  const user = computed(() => store.getters.user)

  const defaultBoardLocalStorageKey = computed(() => `an-${user.value?.uid}`)

  const defaultBoard = ref(null)

  watchEffect(() => {
    defaultBoard.value = localStorage.getItem(defaultBoardLocalStorageKey.value)
  })

  const boardId = computed(() => {
    if (route.params.boardId && isBoardInOrg(route.params.boardId)) {
      return route.params.boardId
    }

    return boards.value?.[0]?.boardId || ''
  })

  const board = computed(() => boards.value.find((b) => b.boardId === boardId.value))

  const currentBoard = computed(() => {
    if (route.params.boardId && isBoardInOrg(route.params.boardId)) return route.params.boardId

    if (
      defaultBoard.value &&
      defaultBoard.value !== 'undefined' &&
      isBoardInOrg(defaultBoard.value)
    )
      return defaultBoard.value

    return boardId.value
  })

  const changeCurrentBoard = (boardId) => {
    defaultBoard.value = boardId
    localStorage.setItem(defaultBoardLocalStorageKey.value, boardId)
  }

  const isBoardInOrg = (boardId) => boards.value.find((b) => b.boardId === boardId)

  return {
    boards,
    boardId,
    board,
    defaultBoard: readonly(defaultBoard),
    currentBoard,
    changeCurrentBoard
  }
}
