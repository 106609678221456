import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import { createRequisitionRequestId } from '@/lib/idCreator'
import * as Sentry from '@sentry/browser'
import { filter, includes, map, sortBy } from 'lodash-es'

const getDefaultState = () => {
  return {
    requests: [],
    queriedRequisition: null,
    isWaiting: false,
    statsTotal: {
      requests: 0,
      approved: 0,
      pending: 0,
      rejected: 0,
      fillRate: 0
    }
  }
}

const state = getDefaultState()

const mutations = {
  setRequests(_state, requests) {
    _state.requests = sortBy(requests, (request) => {
      if (request.hiringPriority === 'High') {
        return 1
      }
      if (request.hiringPriority === 'Medium') {
        return 2
      }
      if (request.hiringPriority === 'Low') {
        return 4
      }
      return 5
    })
    _state.statsTotal.requests = requests.length
    _state.statsTotal.approved = filter(requests, (request) => {
      return (
        request?.sequentialApproval?.length ===
        filter(request.sequentialApproval, (approver) => approver.status === 'Approved').length
      )
    }).length
    _state.statsTotal.rejected = filter(requests, (request) => {
      return (
        request?.sequentialApproval?.length ===
        filter(request.sequentialApproval, (approver) => approver.status === 'Rejected').length
      )
    }).length
    _state.statsTotal.pending =
      _state.statsTotal.requests - (_state.statsTotal.approved + _state.statsTotal.rejected)
  },
  setRequest(_state, request) {
    _state.queriedRequisition = request
  },
  setIsWaiting(_state, value) {
    _state.isWaiting = value
  }
}

const actions = {
  createRequestId() {
    const requestId = createRequisitionRequestId()
    return requestId
  },
  async submitRequest(context, { requestId, createdBy, creatorPersonId, data }) {
    context.commit('setIsWaiting', true)
    data.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    data.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
    data.createdBy = createdBy
    data.creatorPersonId = creatorPersonId
    data.currency = {
      code: data.currency.code,
      name: data.currency.name,
      symbol: data.currency.symbol
    }
    map(data.sequentialApproval, (approver) => {
      approver.status = 'Pending'
      return approver
    })
    data.requestId = requestId
    try {
      await firebase.firestore().collection('requisitions').doc(requestId).set(data)
    } catch (e) {
      console.error(e)
      context.commit('setIsWaiting', false)
      Sentry?.captureException(e)
    }
    context.commit('setIsWaiting', false)
  },
  async updateRequest(context, { requestId, data }) {
    context.commit('setIsWaiting', true)
    data.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
    data.currency = {
      code: data.currency.code,
      name: data.currency.name,
      symbol: data.currency.symbol
    }
    map(data.sequentialApproval, (approver) => {
      approver.status = 'Pending'
      return approver
    })
    data.requestId = requestId
    try {
      await firebase.firestore().collection('requisitions').doc(requestId).update(data)
    } catch (e) {
      console.error(e)
      context.commit('setIsWaiting', false)
      Sentry?.captureException(e)
    }
    context.commit('setIsWaiting', false)
  },
  /*async sendEmailForApproval(context, data) {
    try {
      var notifyNewRequisitionRequest = firebase
        .functions()
        .httpsCallable('notifyRequisitionRequest')
      await notifyNewRequisitionRequest(data)
    } catch (e) {
      console.error(e)
      Sentry.captureMessage(e)
      throw 'Something went wrong sending'
    }
  },*/
  async fetchRequisitionRequests(context, data) {
    context.commit('setIsWaiting', true)
    const requests = []
    await firebase
      .firestore()
      .collection('requisitions')
      .where('boardId', '==', data.boardId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const request = doc.data()
          requests.push(request)
        })
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
      })
    context.commit('setRequests', requests)
    context.commit('setIsWaiting', false)
  },

  async fetchRequisitionRequestsByExecutives(context, data) {
    context.commit('setIsWaiting', true)
    const requests = []
    await firebase
      .firestore()
      .collection('requisitions')
      .where('boardId', '==', data.boardId)
      .where('executiveResponsible', '==', data.currentUserId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const request = doc.data()
          requests.push(request)
        })
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
      })
    context.commit('setRequests', requests)
    context.commit('setIsWaiting', false)
  },

  async fetchRequisitionRequestsByUsers(context, data) {
    context.commit('setIsWaiting', true)
    const requests = []
    await firebase
      .firestore()
      .collection('requisitions')
      .where('boardId', '==', data.boardId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const request = doc.data()
          requests.push(request)
        })
      })
      .catch((error) => {
        console.log('Error getting documents: ', error)
      })
    const filteredRequests = filter(requests, (request) => {
      const approvers = map(request.sequentialApproval, (approval) => approval.id)
      return request.reportsTo === data.currentUserId || includes(approvers, data.currentUserId)
    })
    context.commit('setRequests', filteredRequests)
    context.commit('setIsWaiting', false)
  },

  async fetchRequisitionRequestForPersonId(context, { personId, boardId }) {
    const querySnapshot = await firebase
      .firestore()
      .collection('requisitions')
      .where('boardId', '==', boardId)
      .where('newPersonId', '==', personId)
      .limit(1)
      .get()

    if (querySnapshot.docs.length > 0) context.commit('setRequest', querySnapshot.docs[0].data())
  },

  async fetchRequestByUid(context, { boardId, uid }) {
    const querySnapshot = await firebase
      .firestore()
      .collection('requisitions')
      .where('boardId', '==', boardId)
      .where('createdBy', '==', uid)
      .get()

    const requests = []
    querySnapshot.forEach((doc) => {
      const request = doc.data()
      requests.push(request)
    })
    context.commit('setRequests', requests)
  },

  async fetchRequest(context, data) {
    context.commit('setIsWaiting', true)
    const requestId = data.requestId
    const doc = await firebase.firestore().collection('requisitions').doc(requestId).get()
    const request = doc.data()
    context.commit('setRequest', request)
    context.commit('setIsWaiting', false)
  },

  async approveRequest(context, { requestId, personId, data }) {
    if (!personId) throw new Error('reviewerPersonId cannot be null')
    const idx = data.sequentialApproval.findIndex((approver) => approver.id === personId)
    if (data.sequentialApproval[idx]) data.sequentialApproval[idx].status = 'Approved'
    await firebase.firestore().collection('requisitions').doc(requestId).update({
      sequentialApproval: data.sequentialApproval,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    })
    context.commit('setRequest', data)
  },

  async rejectRequest(context, { requestId, personId, data }) {
    if (!personId) throw new Error('reviewerPersonId cannot be null')
    const idx = data.sequentialApproval.findIndex((approver) => approver.id === personId)
    if (data.sequentialApproval[idx]) data.sequentialApproval[idx].status = 'Rejected'
    await firebase.firestore().collection('requisitions').doc(requestId).update({
      sequentialApproval: data.sequentialApproval,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    })
    context.commit('setRequest', data)
  }
}

export default {
  state,
  mutations,
  actions,
  modules: {}
}
