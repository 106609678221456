import { Fields } from '@/lib/PlanFields'
import { ref } from 'vue'

const defaultFields = [
  Fields.name,
  Fields.department,
  Fields.location,
  Fields.email,
  Fields.salaryNewRole,
  Fields.plannedHireDate
]
const restrictedFields = [Fields.salary]
const defaultSelectedFields = [
  Fields.name,
  Fields.department,
  Fields.location,
  Fields.salaryNewRole
]
const selectedFields = ref([...defaultSelectedFields])

/**
 * Shared state of selected card fields to be displayed
 */
export default function useScenarioFields() {
  /**
   * Toggle given field on 'selected' list
   */
  const toggleField = (field) => {
    selectedFields.value.includes(field)
      ? selectedFields.value.splice(selectedFields.value.indexOf(field), 1)
      : selectedFields.value.push(field)
  }

  /**
   * Check whether field is currently selected
   */
  const isSelected = (field) => {
    return selectedFields.value.includes(field)
  }

  /**
   * Reset state to default
   */
  const reset = () => {
    selectedFields.value = [...defaultSelectedFields]
  }

  /**
   * Set selected fields.
   * @param {Array} fields - Array of fields to set as selected.
   * @returns {void}
   */
  const setSelectedFields = (fields) => {
    selectedFields.value = [...new Set([...selectedFields.value, ...fields])]
  }

  return {
    defaultFields,
    isSelected,
    selectedFields,
    setSelectedFields,
    toggleField,
    reset,
    restrictedFields
  }
}
