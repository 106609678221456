import { getCustomFieldsDict } from '@/lib/PersonDataProcessing.js'
import { computed, readonly, ref } from 'vue'
import store from '../store'

const totalVariants = 17

/**
 * Get configuration for color highlighting
 */
const getHighlightGroupMap = ({
  modeName,
  targetEmployees,
  squadCollisionCounts,
  squads,
  customFieldName
}) => {
  let highlightMap = []
  switch (modeName) {
    case 'department':
      highlightMap = targetEmployees.reduce((prev, cur) => {
        const key = cur.employeeData?.department
        prev[key] = prev[key] || []
        prev[key].push(cur.personId)
        return prev
      }, {})

      break
    case 'squad':
      highlightMap = squads.reduce((prev, cur) => {
        const key = cur.name
        prev[key] = prev[key] || []
        prev[key] = [...new Set([...prev[key], cur.dri, ...cur.team])]
        return prev
      }, {})
      break

    case 'role':
      highlightMap = targetEmployees.reduce((prev, cur) => {
        const key = cur.role
        prev[key] = prev[key] || []
        prev[key].push(cur.personId)
        return prev
      }, {})
      break

    case 'openroles':
      highlightMap = targetEmployees.reduce((prev, cur) => {
        const key = cur.employeeStatus
        prev[key] = prev[key] || []
        prev[key].push(cur.personId)
        return prev
      }, {})
      break

    case 'location':
      highlightMap = targetEmployees.reduce((prev, cur) => {
        const key = cur.officeLocation
        prev[key] = prev[key] || []
        prev[key].push(cur.personId)
        return prev
      }, {})
      break

    case 'collisions':
      highlightMap = squadCollisionCounts
      break

    case 'custom-field':
      highlightMap = targetEmployees.reduce((prev, cur) => {
        let key = '_empty'
        if (customFieldName)
          key = getCustomFieldsDict(cur.customFields)[customFieldName] || '_empty'

        prev[key] = prev[key] || []
        prev[key].push(cur.personId)
        return prev
      }, {})
      break

    default:
      break
  }

  return Object.keys(highlightMap).map((key, index) => ({
    name: key,
    people: highlightMap[key],
    color: index % totalVariants
  }))
}

const highlightMode = ref(null)
const highlightModeSecondary = ref(null)
const activeEmployees = ref([])

export default function useOrgChartColors() {
  const squads = computed(() => store.getters.squads)
  const squadCollisionCounts = computed(() => store.getters.squadCollisionCounts)

  const colorMap = computed(() =>
    getHighlightGroupMap({
      modeName: highlightMode.value,
      targetEmployees: activeEmployees.value,
      squadCollisionCounts: squadCollisionCounts.value,
      squads: squads.value,
      customFieldName: highlightModeSecondary.value
    })
  )

  const peopleColorMap = computed(() =>
    colorMap.value.reduce((prev, cur) => {
      cur.people.forEach((personId) => {
        prev[personId] = cur.color
      })
      return prev
    }, {})
  )

  /**
   * Get background color variant from generated map
   */
  const backgroundColorVariant = (personId) => peopleColorMap.value[personId]

  // Searches current color map for an object with a matching name
  // The current color map's objects will change based on the hightlightMode, you need to account for all the possible modes yourself when using this function
  const getColorVariantByProperty = (property) => {
    return colorMap.value.find((prop) => prop.name === property)
  }

  /**
   * update selected highlight mode
   * @param {string} mode highlight mode
   * @param {string} secondary highlight secondary mode
   */
  const setNewHighlightMode = ({ mode, secondary = null }) => {
    highlightMode.value = mode
    highlightModeSecondary.value = secondary
  }

  const setPeople = (people) => (activeEmployees.value = people)

  return {
    setPeople,
    backgroundColorVariant,
    setNewHighlightMode,
    getColorVariantByProperty,
    highlightMode: readonly(highlightMode),
    highlightModeSecondary: readonly(highlightModeSecondary)
  }
}
