import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'

/**
 * Returns an array of Objects with user profiles matching provided uid's
 * @param {Array} userIds - array of uid values
 */
export const getUsers = async (userIds) => {
  const query = userIds.map((uid) => ({ uid }))

  const getUsers = firebase.functions().httpsCallable('getUsers')
  const ret = await getUsers({ userIds: query })
  return ret.data
}

export const writeToGoogleSheet = async (docId, data) => {
  const updateDoc = firebase.functions().httpsCallable('googleSheetIntegration-writeToGoogleSheet')
  try {
    await updateDoc({ data, docId })
  } catch (e) {
    console.log('err caught in updateDoc')
  }
}

export const readFromGoogleSheet = async (docId) => {
  const readDoc = firebase.functions().httpsCallable('googleSheetIntegration-readFromGoogleSheet')
  try {
    const res = await readDoc({ docId })
    return res
  } catch (e) {
    console.log('err caught in updateDoc')
  }
}

export const copyGoogleSheet = async ({ sourceId, title, userEmail }) => {
  const copyFile = firebase.functions().httpsCallable('googleSheetIntegration-copyFile')

  try {
    const ret = await copyFile({ sourceId, title, userEmail })
    return ret
  } catch (e) {
    console.log('err caught in googleSheetIntegration-copyFile', e)
  }
}

export const givePermission = async ({ userEmail, docId }) => {
  try {
    const givePermissionForUser = firebase
      .functions()
      .httpsCallable('googleSheetIntegration-givePermissionForUser')

    await givePermissionForUser({ userEmail, docId })
  } catch (e) {
    console.log('err caught in givePermission', e)
  }
}

export const removePermission = async ({ userEmail, docId }) => {
  try {
    const removePermission = firebase
      .functions()
      .httpsCallable('googleSheetIntegration-removePermissionForUser')

    await removePermission({ userEmail, docId })
  } catch (e) {
    console.log('err caught in givePermission', e)
  }
}
