import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { compact } from 'lodash-es'

export async function fetchCompensations(boardId) {
  try {
    const compFetcher = firebase.functions().httpsCallable('compensations-fetch')
    const ret = await compFetcher({ boardId })

    const comps = ret.data

    return comps
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return []
  }
}

// Call backend to clone a board
// srcBoardId: (Required) the target board to clone
// newBoardName: (Required) the name of the new board
// newBoardDescription: the description of the new board
// peopleList: an array of people to clone. If empty, all people from target board will be cloned.
// boardProperties: Additional board properties for the new board
// clonePeople: Whether to clone people or not. If false, it creates an empty board
export async function cloneBoard({
  srcBoardId,
  newBoardName,
  newBoardDescription,
  peopleList,
  boardProperties,
  clonePeople = true
}) {
  try {
    const _peopleList = compact(peopleList)
    const cloner = firebase.functions().httpsCallable('boards-clone')
    const ret = await cloner({
      srcBoardId,
      newBoardName,
      newBoardDescription,
      peopleList: _peopleList,
      boardProperties,
      clonePeople
    })

    return ret.data.boardId
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    return []
  }
}

export async function fetchPersonHistory({ boardId, personId }) {
  //limit to 100 newest entries and sort by updatedAt
  const querySnapshots = await firebase
    .firestore()
    .collection('people_history')
    .where('boardId', '==', boardId)
    .where('personId', '==', personId)
    .orderBy('updatedAt', 'desc') // Sort the results by the updatedAt field in descending order
    .limit(100) // Limit the results to the 100 newest entries
    .get()

  const history = querySnapshots.docs.map((doc) => ({ ...doc.data(), id: doc.id }))

  return history
}
