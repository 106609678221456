<template>
  <TransitionRoot :show="show" as="template" appear @after-leave="handleAfterLeave">
    <Dialog as="div" class="relative z-50" @close="handleBrowserClosed">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            ref="dialog"
            class="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          >
            <Combobox v-slot="{ activeOption }" v-model="selectedOption">
              <div class="relative">
                <ZoomInIcon
                  class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <ComboboxInput
                  class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  @change="query = $event.target.value"
                />
              </div>

              <ComboboxOptions
                v-if="query === '' || filteredPeople.length > 0"
                class="flex max-h-96 divide-x divide-gray-100"
                as="div"
                static
              >
                <div class="min-w-0 flex-1 flex-col overflow-hidden px-6 py-4">
                  <h2 v-if="query === ''" class="mt-2 mb-4 text-xs font-semibold text-gray-500">
                    All employees
                  </h2>
                  <div class="-mx-2 h-full pb-10 text-sm text-gray-700">
                    <RecycleScroller
                      v-slot="{ item: person }"
                      class="h-full"
                      :items="query === '' ? people : filteredPeople"
                      key-field="personId"
                      :item-size="40"
                    >
                      <ComboboxOption
                        v-slot="{ active, selected }"
                        :value="person"
                        class="h-full"
                        as="div"
                      >
                        <div
                          :class="[
                            'group flex cursor-default select-none items-center rounded-md p-2',
                            active && 'bg-gray-100 text-gray-900',
                            selected && 'bg-blue-100'
                          ]"
                        >
                          <div class="h-6 w-6">
                            <img
                              :src="person.avatarImg"
                              alt=""
                              class="h-full w-full rounded-full object-cover"
                              @error="
                                $event.target.src =
                                  'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/open_role.png?alt=media&token=5a831181-2239-482a-93e2-0e795a4af0e5'
                              "
                            />
                          </div>
                          <span class="ml-3 flex-auto truncate">{{ person.name }}</span>
                          <ChevronRightIcon
                            v-if="active"
                            class="ml-3 h-5 w-5 flex-none text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </ComboboxOption>
                    </RecycleScroller>
                  </div>
                </div>

                <div
                  v-if="selectedOption || activeOption"
                  class="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex"
                >
                  <div class="flex-none p-6 text-center">
                    <div class="mx-auto h-16 w-16 rounded-full">
                      <img
                        :src="(selectedOption || activeOption).avatarImg"
                        alt=""
                        class="h-full w-full rounded-full object-cover"
                        @error="
                          $event.target.src =
                            'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/open_role.png?alt=media&token=5a831181-2239-482a-93e2-0e795a4af0e5'
                        "
                      />
                    </div>
                    <h2
                      :key="(selectedOption || activeOption).personId"
                      class="mt-3 font-semibold text-gray-900"
                    >
                      {{ (selectedOption || activeOption).name }}
                    </h2>
                    <p class="text-sm leading-6 text-gray-500">
                      {{ (selectedOption || activeOption).role }}
                    </p>
                  </div>
                  <div class="flex flex-auto flex-col justify-between p-6">
                    <dl class="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                      <dt
                        v-if="(selectedOption || activeOption).employeeData.department"
                        class="col-end-1 font-semibold text-gray-900"
                      >
                        Department
                      </dt>
                      <dd v-if="(selectedOption || activeOption).employeeData.department">
                        {{ (selectedOption || activeOption).employeeData.department }}
                      </dd>
                      <dt
                        v-if="(selectedOption || activeOption).officeLocation"
                        class="col-end-1 font-semibold text-gray-900"
                      >
                        Location
                      </dt>
                      <dd v-if="(selectedOption || activeOption).officeLocation" class="truncate">
                        {{ (selectedOption || activeOption).officeLocation }}
                      </dd>
                      <dt
                        v-if="
                          goalsForPerson((selectedOption || activeOption).personId).value &&
                          goalsForPerson((selectedOption || activeOption).personId).value.length > 0
                        "
                        class="col-end-1 font-semibold text-gray-900"
                      >
                        Assigned to
                      </dt>
                      <dd class="flex flex-wrap gap-1">
                        <div
                          v-for="goal in goalsForPerson((selectedOption || activeOption).personId)
                            .value"
                          :key="goal.personId"
                          class="truncate rounded-full bg-bluedark-600 p-1 px-2 text-center text-xs text-white hover:bg-bluedark-500"
                        >
                          {{ goal.rolePlanning.name || 'Unnamed goal' }}
                        </div>
                      </dd>
                    </dl>
                    <button
                      v-if="mode === 'add'"
                      class="mt-6 w-full rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      @click="handleAdd((selectedOption || activeOption).personId)"
                    >
                      Add
                    </button>
                    <button
                      v-if="mode === 'goto'"
                      class="mt-6 w-full rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      @click="handleGoTo((selectedOption || activeOption).personId)"
                    >
                      Show in Org Chart
                    </button>
                  </div>
                </div>
              </ComboboxOptions>

              <div
                v-if="query !== '' && filteredPeople.length === 0"
                class="py-14 px-6 text-center text-sm sm:px-14"
              >
                <UsersIcon class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                <p class="mt-4 font-semibold text-gray-900">No people found</p>
                <p class="mt-2 text-gray-500">
                  We couldn’t find anything with that term. Please try again.
                </p>
              </div>
            </Combobox>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import usePeopleBrowser from '@/hooks/use-people-browser'
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { ChevronRightIcon, UsersIcon } from '@heroicons/vue/outline'
import { ZoomInIcon } from '@heroicons/vue/solid'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
// import { useMagicKeys } from '@vueuse/core'

const props = defineProps({
  boardId: {
    type: String,
    required: true
  },
  mode: {
    type: String,
    required: false,
    default: 'add',
    validator: (value) => ['add', 'goto'].includes(value)
  }
})

const { getters } = useStore()
const { show, personSelected, selectionCanceled, setPersonToGoTo, setShow } = usePeopleBrowser(
  props.boardId
)
const { push } = useRouter()
// const { escape } = useMagicKeys()

const dialog = ref(null)

const people = computed(() =>
  getters['people/activeEmployees'](props.boardId).filter(
    (employee) => employee.name && employee.personId
  )
)

const goalsForPerson = (personId) =>
  computed(() => getters['people/goalsForPerson']({ boardId: props.boardId, personId }))

const query = ref('')
const selectedOption = ref(null)

const filteredPeople = computed(() =>
  query.value === ''
    ? []
    : people.value.filter((person) => {
        return person.name.toLowerCase().includes(query.value.toLowerCase())
      })
)

const handleAdd = (personId) => {
  personSelected(personId)
}

const handleGoTo = async (personId) => {
  setPersonToGoTo(personId)
  await push({ name: 'Board', params: { boardId: props.boardId } })
  setShow(false)
}

const handleBrowserClosed = () => {
  selectionCanceled()
  setShow(false)
}

const handleAfterLeave = () => {
  query.value = ''
  selectedOption.value = null
}

// watch(escape, () => {
//   setShow(false)
// })
//
// onClickOutside(dialog, () => setShow(false))
</script>
