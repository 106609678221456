import { isLeaving } from '@/lib/PersonDataProcessing'
import { extractNumber } from '@/services/currency.service'
import store from '@/store/index.js'
import { Cashify } from 'cashify'
import numeral from 'numeral'
import { computed } from 'vue'

const previousCompensation = (compensationForPerson) => {
  const previousRates = compensationForPerson?.previousRates || []
  if (previousRates.length > 0) {
    return {
      salaryNumber: extractNumber(previousRates[0].payRate),
      currency: previousRates[0].currency
    }
  }

  return null
}

/**
 * A hook encapsulating compensation logic.
 */
export default function useCompensation() {
  const forexRates = computed(() => store.getters.forexRates)
  const isForexReady = computed(() => store.getters.isForexReady)
  const preferredBaseCurrency = computed(() => store.getters.preferredBaseCurrency)

  const compensationForPerson = ({ boardId, personId }) =>
    computed(() => store.getters.compensationForPerson({ boardId, personId }))

  const previousCompensationForPerson = ({ boardId, personId }) =>
    computed(() => previousCompensation(compensationForPerson({ boardId, personId }).value))

  const personById = ({ boardId, personId }) =>
    computed(() => store.getters['people/personById']({ boardId, personId }))

  const cashify = computed(
    () =>
      new Cashify({
        base: forexRates.value.base,
        rates: forexRates.value.rates
      })
  )

  const exchangeToBaseCurrency = (salary) => {
    const extractedCurrency = String(salary)
      .replace(/[^A-Z]+/gi, '')
      .trim()

    const currency = extractedCurrency.length ? extractedCurrency : preferredBaseCurrency.value
    const pay = extractNumber(salary)

    if (currency && pay && isForexReady.value) {
      const convertedSalary = cashify.value.convert(pay, {
        from: currency,
        to: preferredBaseCurrency.value
      })

      return numeral(convertedSalary).value()
    }

    return numeral(pay).value()
  }

  const convert = ({ salaryNumber, fromCurrency, toCurrency }) => {
    if (salaryNumber && fromCurrency && toCurrency && isForexReady.value) {
      try {
        const convertedSalary = cashify.value.convert(salaryNumber, {
          from: fromCurrency,
          to: toCurrency
        })
        return numeral(convertedSalary).value()
      } catch (e) {
        console.error(e)
        return salaryNumber
      }
    }

    return numeral(salaryNumber).value()
  }

  /**
   * Returns the total compensation of provided list of people
   *
   * @param {Array} peopleIds
   * @return {Number} total compensation
   */
  const totalFor = ({ boardId, peopleIds = [] }) => {
    return peopleIds
      .map((personId) => {
        const person = personById({ boardId, personId }).value
        return isLeaving(person)
          ? 0
          : compensationForPerson({ boardId, personId }).value?.payRate ?? 0
      })
      .map(exchangeToBaseCurrency)
      .reduce((prev, cur) => (isNaN(cur) ? prev : prev + cur), 0)
  }

  const salaryNumberInBaseCurrency = ({ salaryNumber, currency }) => {
    const compensationCurrency = currency || preferredBaseCurrency.value

    if (compensationCurrency !== preferredBaseCurrency.value) {
      return (
        convert({
          salaryNumber,
          fromCurrency: compensationCurrency,
          toCurrency: preferredBaseCurrency.value
        }) || 0
      )
    } else {
      return salaryNumber
    }
  }

  const currentSalaryInBaseCurrency = ({ boardId, personId }) =>
    computed(() => {
      const compensation = compensationForPerson({ boardId, personId }).value
      const salaryNumber = extractNumber(compensation?.payRate || 0) || 0

      const extractedCurrency =
        compensation?.currency ||
        String(compensation?.payRate)
          .replace(/[^A-Z]+/gi, '')
          .trim()

      const compensationCurrency = extractedCurrency || preferredBaseCurrency.value

      return salaryNumberInBaseCurrency({ currency: compensationCurrency, salaryNumber })
    })

  const previousSalaryInBaseCurrency = ({ boardId, personId }) =>
    computed(() => {
      const previousCompensation = previousCompensationForPerson({ boardId, personId }).value

      return previousCompensation
        ? salaryNumberInBaseCurrency({
            salaryNumber: previousCompensation?.salaryNumber || 0,
            currency: previousCompensation?.currency
          })
        : null
    })

  return {
    convert,
    totalFor,
    currentSalaryInBaseCurrency,
    previousSalaryInBaseCurrency,
    salaryNumberInBaseCurrency,
    previousCompensationForPerson
  }
}
