import { baseHeight, baseWidth } from '@/lib/OrgNodeData.js'
import { computed, readonly, ref } from 'vue'

const nodeWidths = ref({})
const nodeHeights = ref({})
const widthCondition = ref(1)
const hoveredNode = ref(null)
const copiedId = ref(null)
const expandedNodes = ref([])
const hideImages = ref(false)
const highlightedNode = ref(null)

const maxWidth = computed(() => {
  let max = 0

  Object.keys(nodeWidths.value).forEach((node) => {
    if (nodeWidths.value[node] > max) max = nodeWidths.value[node]
  })

  return max || baseWidth
})

const maxHeight = computed(() => {
  let max = 0

  Object.keys(nodeHeights.value).forEach((node) => {
    if (nodeHeights.value[node] > max) max = nodeHeights.value[node]
  })

  return max || baseHeight
})

/**
 * Shared state of visible nodes to help set a common width/height when content size changes
 */
export default function useOrgNodeHelpers() {
  /**
   * Update width of a card with given id
   * @param id
   * @param width
   */
  const updateWidth = ({ id, width }) => {
    if (!width) return

    nodeWidths.value[id] = width
  }

  /**
   * Update height of a card with given id
   * @param id
   * @param width
   */
  const updateHeight = ({ id, height }) => {
    if (!height) return

    nodeHeights.value[id] = height
  }

  /**
   * Reset initial state
   */
  const reset = () => {
    nodeWidths.value = {}
    nodeHeights.value = {}
    widthCondition.value = 1
    copiedId.value = null
    hoveredNode.value = null
  }

  const setHovered = (id) => {
    hoveredNode.value = id
  }

  const resetHovered = (id) => {
    if (id !== hoveredNode.value) return

    hoveredNode.value = null
  }

  const setCopiedId = (id) => {
    copiedId.value = id
  }

  const addExpanded = (id) => {
    if (expandedNodes.value.includes(id)) return

    expandedNodes.value.push(id)
  }

  const removeExpanded = (id) => {
    expandedNodes.value = expandedNodes.value.filter((node) => node !== id)
  }

  const isExpanded = (id) => {
    return expandedNodes.value.includes(id)
  }

  const setHideImages = (value) => {
    hideImages.value = value
  }

  const triggerHighlight = (id) => {
    highlightedNode.value = id
  }

  return {
    addExpanded,
    copiedId: readonly(copiedId),
    hideImages: readonly(hideImages),
    highlightedNode: readonly(highlightedNode),
    hoveredNode: readonly(hoveredNode),
    isExpanded,
    maxHeight,
    maxWidth,
    removeExpanded,
    reset,
    resetHovered,
    setCopiedId,
    setHideImages,
    setHovered,
    triggerHighlight,
    updateHeight,
    updateWidth,
    widthCondition
  }
}
