import { ACCESS_LEVELS_DICT, userHasRouteAccess } from '@/lib/Access.js'
import store from '@/store/index.js'
import { FeatureAccess, FeatureTiers, MenuItems } from '@/utils/navigationData.js'
import firebase from 'firebase/compat/app'
import { createRouter, createWebHistory } from 'vue-router'

const isProduction = process.env.NODE_ENV === 'production'
const baseUrl = isProduction ? '/' : '/'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue'),

    beforeEnter: (to, from, next) => {
      let alreadyTriggered = false
      firebase.auth().onAuthStateChanged((user) => {
        if (alreadyTriggered) return
        alreadyTriggered = true
        if (user !== null) {
          //weee check?

          return next({ name: 'Landing' })
        }

        next()
      })
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('@/views/SignUpView.vue'),
    props: true
  },
  {
    path: '/hub/:boardId',
    name: 'Hub',
    component: () => import('@/views/hub/HubLayout.vue'),
    meta: {
      requiresAuth: true,
      title: 'Hub',
      permittedRoles: FeatureAccess[MenuItems.Hub],
      tiers: FeatureTiers[MenuItems.Hub]
    }
  },
  {
    path: '/:boardId?',
    name: 'Landing',
    component: () => import('@/views/LandingPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/board/:boardId',
    name: 'Board',
    component: () => import('@/views/OrgChartV2.vue'),
    meta: {
      requiresAuth: true,
      title: 'Org Chart',
      blockedRoles: [ACCESS_LEVELS_DICT.NO_ACCESS],
      tiers: FeatureTiers[MenuItems.OrgPlan]
    }
  },
  {
    path: '/overview/:boardId',
    name: 'Org Overview',
    component: () => import('@/views/TacticalView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Org Chart',
      blockedRoles: [ACCESS_LEVELS_DICT.NO_ACCESS],
      tiers: FeatureTiers[MenuItems.OrgPlan]
    }
  },
  {
    path: '/import/:boardId',
    name: 'ImportData',
    component: () => import('@/views/ImportData.vue'),
    meta: {
      requiresAuth: true,
      title: 'Data Import',
      tiers: FeatureTiers[MenuItems.OrgPlan]
    }
  },
  {
    path: '/formulas/:boardId',
    name: 'Formulas',
    component: () => import('@/views/FormulasView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Formulas',
      tiers: FeatureTiers[MenuItems.Directory],
      permittedRoles: FeatureAccess[MenuItems.Directory]
    }
  },
  {
    path: '/import-salary-benchmark/:boardId',
    name: 'ImportSalaryBenchmarkData',
    component: () => import('@/views/ImportSalaryBenchmark.vue'),
    meta: {
      requiresAuth: true,
      title: 'Salary Benchmark Import',
      tiers: FeatureTiers[MenuItems.OrgPlan]
    }
  },
  {
    path: '/hiring-requests/:boardId/view/:requestId',
    name: 'hiringRequestDetail',
    component: () => import('@/views/HiringRequestDetail.vue'),
    meta: {
      requiresAuth: true,
      title: 'Hiring Request',
      permittedRoles: FeatureAccess[MenuItems.Hiring],
      tiers: FeatureTiers[MenuItems.Hiring]
    }
  },
  {
    path: '/hiring-requests/:boardId/create',
    name: 'CreateHiringRequest',
    component: () => import('@/views/CreateHiringRequest.vue'),
    meta: {
      requiresAuth: true,
      title: 'Create Hiring Request',
      permittedRoles: FeatureAccess[MenuItems.Hiring],
      tiers: FeatureTiers[MenuItems.Hiring]
    }
  },
  {
    path: '/hiring-requests/:boardId/edit/:requestId',
    name: 'EditHiringRequest',
    component: () => import('@/views/EditHiringRequest.vue'),
    meta: {
      requiresAuth: true,
      permittedRoles: FeatureAccess[MenuItems.Hiring],
      tiers: FeatureTiers[MenuItems.Hiring]
    }
  },
  {
    path: '/hiring-requests/:boardId',
    name: 'HiringRequests',
    component: () => import('@/views/HiringRequests.vue'),
    meta: {
      requiresAuth: true,
      permittedRoles: FeatureAccess[MenuItems.Hiring],
      tiers: FeatureTiers[MenuItems.Hiring]
    }
  },
  {
    path: '/compensation-benchmark/:boardId',
    name: 'Compensation',
    component: () => import('@/views/CompensationBenchmark.vue'),
    meta: {
      requiresAuth: true,
      title: 'Compensation Benchmark',
      permittedRoles: FeatureAccess[MenuItems.Compensation],
      tiers: FeatureTiers[MenuItems.Compensation]
    }
  },
  {
    path: '/benchmark/:boardId',
    name: 'Benchmark',
    component: () => import('@/views/BenchmarkView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Benchmark List',
      permittedRoles: FeatureAccess[MenuItems.Benchmark],
      tiers: FeatureTiers[MenuItems.Benchmark]
    }
  },
  {
    path: '/table/:boardId',
    name: 'Table',
    component: () => import('@/views/OrgTable.vue'),
    meta: {
      requiresAuth: true,
      title: 'Directory',
      permittedRoles: FeatureAccess[MenuItems.Directory],
      tiers: FeatureTiers[MenuItems.Directory]
    }
  },
  {
    path: '/plans/:boardId',
    name: 'Plan',
    component: () => import('@/views/OrgScenario.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Plans',
      permittedRoles: FeatureAccess[MenuItems.Plans],
      tiers: FeatureTiers[MenuItems.Plans]
    }
  },
  {
    path: '/plan/:boardId/:planId',
    name: 'Plan View',
    component: () => import('@/views/PlanView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Plans',
      permittedRoles: FeatureAccess[MenuItems.Plans],
      tiers: FeatureTiers[MenuItems.Plans]
    }
  },
  {
    path: '/forecast/:boardId',
    name: 'Forecast',
    component: () => import('@/views/ForecastView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Forecast',
      permittedRoles: FeatureAccess[MenuItems.Forecast],
      tiers: FeatureTiers[MenuItems.Forecast]
    }
  },
  {
    path: '/invite-access/:boardId',
    name: 'InviteAccessUsers',
    component: () => import('@/views/InviteAndManageUsers.vue'),
    meta: {
      requiresAuth: true,
      title: 'Access and Invite',
      permittedRoles: FeatureAccess[MenuItems.Access],
      tiers: FeatureTiers[MenuItems.Access]
    }
  },
  {
    path: '/plan/edit/:boardId',
    name: 'PlanEditor2',
    component: () => import('@/views/OrgScenarioEditor2.vue'),
    meta: {
      requiresAuth: true,
      title: 'Plan',
      permittedRoles: FeatureAccess[MenuItems.Plans],
      tiers: FeatureTiers[MenuItems.Plans]
    },
    beforeEnter: (to, from, next) => {
      to.meta.title = to.params.title || 'Plan'
      return next()
    }
  },
  {
    path: '/plan/compare/:boardId',
    name: 'PlanCompare',
    component: () => import('@/views/PlanCompare.vue'),
    meta: {
      requiresAuth: true,
      title: 'Plan',
      permittedRoles: FeatureAccess[MenuItems.Plans],
      tiers: FeatureTiers[MenuItems.Plans]
    }
  },
  {
    path: '/headcount-plan/:boardId',
    name: 'HeadcountPlan',
    component: () => import('@/views/HeadcountPlan.vue'),
    meta: {
      requiresAuth: true,
      title: 'Headcount Plan',
      permittedRoles: FeatureAccess[MenuItems.Headcount],
      tiers: FeatureTiers[MenuItems.Plans]
    }
  },
  {
    path: '/workforce-plan/:boardId',
    name: 'Workforce Plan',
    component: () => import('@/views/WorkforcePlanList.vue'),
    meta: {
      requiresAuth: true,
      title: 'Workforce Planning',
      permittedRoles: FeatureAccess[MenuItems.WorkforcePlan],
      tiers: FeatureTiers[MenuItems.WorkforcePlan]
    }
  },
  {
    path: '/workforce-plan/:boardId/create',
    name: 'Create Workforce Plan',
    component: () => import('@/views/WorkforcePlanCreate.vue'),
    meta: {
      requiresAuth: true,
      title: 'Workforce Plan',
      permittedRoles: FeatureAccess[MenuItems.WorkforcePlan],
      tiers: FeatureTiers[MenuItems.WorkforcePlan]
    }
  },
  {
    path: '/workforce-plan/:boardId/track/:workforcePlanId',
    name: 'Workforce Plan Track',
    component: () => import('@/views/WorkforcePlanTrack.vue'),
    meta: {
      requiresAuth: true,
      title: 'Workforce Plan',
      permittedRoles: FeatureAccess[MenuItems.WorkforcePlan],
      tiers: FeatureTiers[MenuItems.WorkforcePlan]
    }
  },
  {
    path: '/hiring-plan/:boardId',
    name: 'HiringPlan',
    component: () => import('@/views/HiringPlan.vue'),
    meta: {
      requiresAuth: true,
      title: 'Hiring Plans',
      permittedRoles: FeatureAccess[MenuItems.Hiring],
      tiers: FeatureTiers[MenuItems.Hiring]
    }
  },
  {
    path: '/hiring-plan/:boardId/details/:planId',
    name: 'HiringPlanDetails',
    component: () => import('@/views/HiringPlanDetails.vue'),
    meta: {
      requiresAuth: true,
      title: 'Hiring Plan Details',
      permittedRoles: FeatureAccess[MenuItems.Hiring],
      tiers: FeatureTiers[MenuItems.Hiring]
    }
  },
  {
    path: '/analytics/:boardId',
    name: 'Analytics',
    component: () => import('@/views/AnalyticsView.vue'),
    meta: {
      title: 'Analytics',
      permittedRoles: FeatureAccess[MenuItems.Analytics],
      tiers: FeatureTiers[MenuItems.Analytics]
    }
  },
  {
    path: '/verify',
    name: 'VerifyEmail',
    component: () => import('@/views/VerifyEmail.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/weeeHome',
    name: 'WeeeHome',
    component: () => import('@/views/WeeeHome.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/budget/:boardId',
    name: 'Budget',
    component: () => import('@/views/BudgetView.vue'),
    meta: {
      title: 'Budget Vs Actual'
    }
  },
  {
    path: '/budget/:boardId/engineering',
    name: 'Engineering View',
    component: () => import('@/components/EngineeringOverview.vue')
  },
  {
    path: '/start',
    name: 'Start',
    component: () => import('@/components/SelfServeIntro.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/oops',
    name: 'No Access',
    component: () => import('@/views/InsufficientAccess.vue'),
    meta: {
      title: 'Access denied'
    }
  },
  {
    path: '/share/:planId',
    name: 'SharedPlan',
    component: () => import('@/views/SharedPlan.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import('@/views/PageNotFound.vue')
  },
  {
    path: '/planned-changes-impact/:boardId/:planId',
    name: 'Impact of Planned Changes',
    component: () => import('@/views/analytics/ImpactOfPlannedChanges.vue'),
    meta: {
      requiresAuth: true,
      title: 'Impact of Planned Changes'
      // permittedRoles: FeatureAccess[MenuItems.ImpactOfPlannedChanges],
      // tiers: FeatureTiers[MenuItems.ImpactOfPlannedChanges]
    }
  }
]

const router = createRouter({
  history: createWebHistory(baseUrl),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.fullPath.substr(0, 2) === '/#') {
    const path = to.fullPath.substr(2)
    next(path)
    return
  }
  if (to.fullPath.includes('/v2/')) {
    const path = to.fullPath.replace('/v2/', '/')
    next(path)
    return
  }

  if (to.meta.requiresAuth) {
    if (
      store.getters.isLoggedIn &&
      !store.getters.user?.emailVerified &&
      to.name !== 'VerifyEmail'
    ) {
      next({ name: 'VerifyEmail' })
      return
    }
  }

  next()
})

// Check authentication requirement and route appropriately
// Use afterEach to allow dynamic titles in routes which can be set with `beforeEnter`
router.afterEach((to) => {
  window.mixpanel.track(`route_${to.name}`)

  window.logGA4Event('screen_view', {
    page: `${to.name}`
  })

  document.title = to.meta?.title || 'Agentnoon'
})

router.beforeResolve((to, from, next) => {
  // If the route doesn't have any permitted or blocked roles, we don't need to check access
  if (
    to.meta.blockedRoles === undefined &&
    to.meta.permittedRoles === undefined &&
    to.meta.tiers === undefined
  ) {
    return next()
  }

  const userRole = store.getters.role(to.params.boardId)
  const userTier = store.getters.tier

  if (
    userRole &&
    userHasRouteAccess({
      role: userRole,
      tier: userTier,
      permittedRoles: to.meta.permittedRoles,
      blockedRoles: to.meta.blockedRoles,
      permittedTiers: to.meta.tiers
    })
  ) {
    return next()
  }

  // Flag to stop `onAuthStateChanged` from running multiple times
  let alreadyTriggered = false
  const unSub = firebase.auth().onAuthStateChanged(async (user) => {
    if (alreadyTriggered) return
    // If the user object is unavailable - happens when user refreshes page - fetch board and get users role
    alreadyTriggered = true

    let userRole = null
    let newClaims = null
    try {
      const currBoard = await firebase.firestore().collection('board').doc(to.params.boardId).get()
      const userAccessObj = currBoard
        .data()
        .accessLevels.find((accessObj) => accessObj.uid === user.uid)

      userRole = userAccessObj.accessLevel
      newClaims = await store.dispatch('updateClaims')
    } catch (e) {
      // If the fetch returns an error - eg. user doesn't have access to board -, redirect them to /oops
      console.log(e)
      // Ensure we always unsubscribe from the auth state listener
      return next('/oops')
    } finally {
      unSub()
    }

    const userHasAccess = userHasRouteAccess({
      role: userRole,
      tier: newClaims.tier || userTier,
      permittedRoles: to.meta.permittedRoles,
      blockedRoles: to.meta.blockedRoles,
      permittedTiers: to.meta.tiers
    })

    if (!userHasAccess) return next('/oops')

    return next()
  })
})

export default router
