import { createStore } from 'vuex'

import user from './user.module'
import people from './people.module.js'
import board from './board.module'
import proposals from './proposals.module'
import personHistory from './personHistory.module'
import project from './project.module'
import bambooHR from './bambooHR.module'
import orgHistory from './orgHistory.module'
import settings from './settings.module'
import requisition from './requisition.module'
import requisitionComments from './requisition.comments.module'
import requisitionHistory from './requisition.history.module'
import plans from './plans.module'
import squads from './squads.module'
import performance from './performance.module'
import squadsHistory from './squadsHistory.module'
import importData from './importData.module'
import syncScenario from './syncScenario.module'
import compensations from './compensations.module'
import salaryBenchmark from './salaryBenchmark.module'
import forex from './forex.module'
import headcountPlan from './headcountPlan.module'
import hiringPlan from './hiringPlan.module'
import performanceReview from './performanceReview.module'
import notifications from './notifications.module'
import salaryCalculatorCompBenchmark from './salaryCalculatorCompBenchmark.module'
import salaryCalculatorPermissions from './salaryCalculatorPermissions.module'
import benchmarkData from './benchmarkData.module'
import planLogs from './planLogs.module'
import impactPlannedChanges from './impactPlannedChanges.module'
import workforceHubCharts from './workforceHubCharts.module'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    people,
    user,
    board,
    proposals,
    personHistory,
    project,
    bambooHR,
    orgHistory,
    requisition,
    settings,
    requisitionComments,
    requisitionHistory,
    plans,
    squads,
    performance,
    squadsHistory,
    importData,
    syncScenario,
    compensations,
    salaryBenchmark,
    forex,
    headcountPlan,
    hiringPlan,
    performanceReview,
    notifications,
    salaryCalculatorCompBenchmark,
    salaryCalculatorPermissions,
    benchmarkData,
    planLogs,
    impactPlannedChanges,
    workforceHubCharts
  }
})
