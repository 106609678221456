import useAppMode from '@/hooks/use-app-mode'
import UserTier from '@/lib/UserTier.js'
import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { chunk, find, groupBy, has, map, some } from 'lodash-es'

const state = {
  // Firebase user object
  userObj: null,
  userMetaData: null,
  invitations: null,
  planInvitations: null,
  userPlanInvites: null,
  backupOriginalUser: null,
  photoURL: '',
  colorCodeMode: 'Employee Status',
  claims: null,
  signInError: null,
  isCreatingAccount: false,
  isAppLoading: false
}

const mutations = {
  setIsAppLoading(state, status) {
    state.isAppLoading = status
  },
  setUser(state, _user) {
    if (_user) {
      state.userObj = _user
      state.photoURL = _user.photoURL
    }
  },
  setUserMetaData(state, metaData) {
    state.userMetaData = metaData
  },
  setInvitations(state, invitations) {
    state.invitations = invitations
  },
  setUserPlanInvites(state, invitations) {
    state.userPlanInvites = invitations
  },
  setPlanInvitations(state, invitations) {
    state.planInvitations = invitations
  },
  updatePlanInvitations(state, { planId, invitation }) {
    if (has(state.planInvitations, planId)) {
      state.planInvitations[planId].push(invitation)
      return
    }
    state.planInvitations[planId] = [invitation]
  },
  RemovePlanInvite(state, { planId, recipient }) {
    state.planInvitations[planId] = state.planInvitations[planId].filter(
      (plan) => plan.recipient !== recipient
    )
  },
  setClaims(state, claims) {
    state.claims = claims
  },
  setSignInError(state, error) {
    state.signInError = error
  },
  setCreatingAccount(state, isCreatingAccount) {
    state.isCreatingAccount = isCreatingAccount
  }
}

const getters = {
  /**
   * Returns true if the current user is admin
   * @param {String} boardId Passed from the calling component.
   * @returns
   */
  isAppLoading: (state) => {
    return state.isAppLoading
  },
  isAdmin: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    if (!targetBoard) return false
    if (targetBoard.accessLevels) {
      return some(targetBoard.accessLevels, (access) => {
        return access.uid === state.userObj.uid && access.accessLevel === 'admin'
      })
    }
    return false
  },

  isExecutive: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    if (!targetBoard) return false
    if (targetBoard.accessLevels) {
      return some(targetBoard.accessLevels, (access) => {
        return access.uid === state.userObj.uid && access.accessLevel === 'executive'
      })
    }
    return false
  },

  isFinance: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    if (!targetBoard) return false
    if (targetBoard.accessLevels) {
      return some(targetBoard.accessLevels, (access) => {
        return access.uid === state.userObj.uid && access.accessLevel === 'finance'
      })
    }
    return false
  },

  isHR: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    if (!targetBoard) return false
    if (targetBoard.accessLevels) {
      return some(targetBoard.accessLevels, (access) => {
        return access.uid === state.userObj.uid && access.accessLevel === 'hr'
      })
    }
    return false
  },

  isManager: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    if (!targetBoard) return false
    if (targetBoard.accessLevels) {
      return some(targetBoard.accessLevels, (access) => {
        return access.uid === state.userObj.uid && access.accessLevel === 'manager'
      })
    }
    return false
  },

  tier: (_state) => {
    //default to "paid" if claims are undefined for account created with no tier
    if (!_state.claims) return UserTier.Paid

    return _state.claims.tier ?? UserTier.Paid
  },

  role: (state, getters, rootState) => (boardId) => {
    const targetBoard = find(rootState.board.boards, (board) => {
      return board.boardId === boardId
    })
    return find(targetBoard?.accessLevels, (access) => {
      return access.uid === getters.uid
    })?.accessLevel
  },

  user: (state) => {
    return state.userObj
  },

  uid: (state) => {
    return state.userObj?.uid || null
  },

  myEmail: (state) => {
    return state.userObj.email
  },

  /**
   * Look up the people list, find my profile, return my personId
   */
  myPersonId: (_, getters, rootState, rootGetters) => (boardId) => {
    const matchingPerson = find(
      rootGetters['people/people'](boardId),
      (person) => person.email === getters.myEmail && person.boardId === boardId
    )

    if (matchingPerson) return matchingPerson.personId
    return null
  },

  /**
   * Return photoURL from user object
   * @returns {string|*}
   */
  userPic: (_state) => {
    if (_state.user) return _state.user.photoURL
    return ''
  },

  isLoggedIn: (_state) => {
    return _state.userObj !== null
  },

  hasPendingInvitations: (_state) => {
    return _state.invitations?.length > 0
  },
  hasUserPlanInvites: (_state) => {
    return _state.userPlanInvites?.length > 0
  },
  pendingPlanInvitationsByPlanId: (_state) => (planId) => {
    return _state.planInvitations?.[planId] || []
  },

  signInError: (_state) => {
    return _state.signInError
  },

  isCreatingAccount: (_state) => {
    return _state.isCreatingAccount
  }
}

const actions = {
  changeIsAppLoading({ commit }, status) {
    commit('setIsAppLoading', status)
  },
  async googleSignIn({ getters, dispatch, commit }) {
    commit('setSignInError', null)

    try {
      const provider = new firebase.auth.GoogleAuthProvider()
      provider.setCustomParameters({
        prompt: 'select_account'
      })
      firebase.auth().useDeviceLanguage()

      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

      let result = null
      result = await firebase.auth().signInWithPopup(provider)

      await dispatch('setUserAuth', result.user)
      await getters.user.getIdToken(true)
      await dispatch('updateClaims')

      if (result.additionalUserInfo.isNewUser) {
        window.mixpanel.track('signup', { provider: 'google' })
      }

      return true
    } catch (error) {
      console.log('sign in error', error)
      if (Sentry) Sentry.captureException(error)

      commit('setSignInError', error)
      return false
    }
  },
  async microsoftSignIn({ getters, dispatch, commit }) {
    commit('setSignInError', null)

    try {
      const provider = new firebase.auth.OAuthProvider('microsoft.com')
      firebase.auth().useDeviceLanguage()

      const result = await firebase.auth().signInWithPopup(provider)

      await dispatch('setUserAuth', result.user)
      await getters.user.getIdToken(true)
      await dispatch('updateClaims')

      if (result.additionalUserInfo.isNewUser) {
        window.mixpanel.track('signup', { provider: 'microsoft' })
      }

      return true
    } catch (error) {
      console.log('sign in error', error)
      if (Sentry) Sentry.captureException(error)

      commit('setSignInError', error)
      return false
    }
  },
  logout(context) {
    return firebase
      .auth()
      .signOut()
      .then(
        () => {
          // Sign-out successful.
          context.commit('setUser', null)
          console.log('logout success')
          return
        },
        (error) => {
          // An error happened.
          console.error(error)
          if (Sentry) Sentry.captureException(error)
          return
        }
      )
  },
  async setUserAuth(context, user) {
    context.commit('setUser', user)
    const tokenResult = await user.getIdTokenResult()
    context.commit('setClaims', tokenResult.claims)
  },
  async updateClaims(context) {
    const tokenResult = await context.getters.user.getIdTokenResult(true)
    context.commit('setClaims', tokenResult.claims)

    return tokenResult.claims
  },
  async fetchUserMetaData(context, uid) {
    try {
      const ret = await firebase.firestore().collection('users').doc(uid).get()
      context.commit('setUserMetaData', ret.data())
    } catch (e) {
      // console.error(e)
      // console.error('fetchUserMetaData failed');
    }
  },
  async setUserMetaData(context, data) {
    context.commit('setUserMetaData', data)
    await firebase.firestore().collection('users').doc(data.uid).set(data)
  },
  async fetchPendingInvitations(context, { email }) {
    const querySnapshot = await firebase
      .firestore()
      .collection('invitations')
      .where('recipient', '==', email)
      .where('invitationAccepted', '==', false)
      .get()

    const invitations = []
    querySnapshot.forEach((doc) => {
      if (!doc.data().ignore) {
        invitations.push({ ...doc.data(), id: doc.id })
      }
    })
    context.commit('setInvitations', invitations)
  },
  async fetchPendingInvitationsForBoard(context, { boardId }) {
    const querySnapshot = await firebase
      .firestore()
      .collection('invitations')
      .where('boardId', '==', boardId)
      .where('invitationAccepted', '==', false)
      .get()

    const pendingInvitaitons = map(querySnapshot.docs, (doc) => {
      const ret = doc.data()
      ret.recipient = ret.recipient.toLowerCase().trim()
      return ret
    })

    return pendingInvitaitons
  },
  async acceptInvitation(context, { companyId, invitationObjectId }) {
    try {
      await firebase.firestore().collection('invitations').doc(invitationObjectId).update({
        invitationAccepted: true,
        invitationAcceptedAt: firebase.firestore.FieldValue.serverTimestamp()
      })

      const inviteAccepted = firebase.functions().httpsCallable('inviteAccepted')
      // TODO catch errors
      await inviteAccepted({ companyId, invitationObjectId })
      await context.dispatch('fetchAllBoards', context.state.userObj.uid)
    } catch (e) {
      console.error(e)
    }
  },
  async bypassVerification(context, { uid }) {
    const { isDev } = useAppMode()

    if (!isDev.value) throw new Error('Can only bypass verification in development')

    const bypassVerification = firebase.functions().httpsCallable('bypassVerification')

    return await bypassVerification({ uid })
  },
  async fetchPersonFromMainBoard(context, { boardId }) {
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('people')
        .where('email', '==', context.getters.myEmail)
        .where('boardId', '==', boardId)
        .get()

      const people = []
      querySnapshot.forEach((doc) => {
        people.push(doc.data())
      })
      return people[0] || null
    } catch (err) {
      console.error('fetchPersonFromMainBoard err', err)
    }
  },
  setCreatingAccount(state, isCreatingAccount) {
    state.commit('setCreatingAccount', isCreatingAccount)
  },
  async acceptPlanInvitationForOutsiders(context, email) {
    try {
      const inviteAccepted = firebase.functions().httpsCallable('inviteAcceptedForOutsiders')
      await inviteAccepted({
        email
      })

      // fetch all the boards again to show updated boards and plans
      await context.dispatch('fetchAllBoards', context.state.userObj.uid)
      return
    } catch (err) {
      console.error('error occured while accepting plan invitations', err)
    }
  },
  async deletePlanInvitationsForOutsiders(context, { planBoardId, boardId }) {
    if (!planBoardId) throw new Error('BoardId is undefined')
    try {
      const snapshot = await firebase
        .firestore()
        .collection('plan_invitations')
        .where('planBoardId', '==', planBoardId)
        .where('boardId', '==', boardId)
        .get()
      // Batch request is capped at 500 transactions
      const batches = chunk(snapshot.docs, 500)
      const commitBatchPromises = []

      batches.forEach((batch) => {
        const writeBatch = firebase.firestore().batch()
        batch.forEach((doc) => writeBatch.delete(doc.ref))
        commitBatchPromises.push(writeBatch.commit())
      })

      await Promise.all(commitBatchPromises)
    } catch (e) {
      window.console.error('Error deleting plan invites from firebase: ', e)
      if (Sentry) Sentry.captureException(e)
    }
  },
  async sendPlanInviteToOutsiders(
    context,
    {
      planName,
      planBoardId,
      boardId,
      invitedBy,
      inviterEmail,
      recipient,
      recipientName,
      accessLevel
    }
  ) {
    try {
      const visited = false
      const createdAt = firebase.firestore.FieldValue.serverTimestamp()

      const invitation = {
        planName,
        planBoardId,
        boardId,
        invitedBy,
        inviterEmail,
        recipient,
        accessLevel,
        visited
      }

      context.commit('updatePlanInvitations', { planId: planBoardId, invitation })

      const origin = window.location.origin
      const sendEmail = firebase.functions().httpsCallable('sendPlanInviteEmailToOutsiders')

      await sendEmail({
        origin,
        planName,
        invitedBy,
        recipient,
        recipientName
      })

      await firebase
        .firestore()
        .collection('plan_invitations')
        .doc()
        .set({
          ...invitation,
          createdAt
        })
    } catch (err) {
      console.error('failed to send invite to an outsider', err)
      if (Sentry) Sentry.captureException(err)
    }
  },
  async removePlanInviteForOutsiders(context, { planBoardId, recipient }) {
    try {
      context.commit('RemovePlanInvite', { planId: planBoardId, recipient })
      const docs = await firebase
        .firestore()
        .collection('plan_invitations')
        .where('planBoardId', '==', planBoardId)
        .where('recipient', '==', recipient)
        .get()

      docs.forEach((doc) => {
        doc.ref.delete()
      })
    } catch (err) {
      console.error('failed to remove invite for an outsider', err)
      if (Sentry) Sentry.captureException(err)
    }
  },
  async fetchPendingPlanInvitations(context, { email }) {
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('plan_invitations')
        .where('recipient', '==', email)
        .where('visited', '==', false)
        .get()

      const invitations = []
      querySnapshot.forEach((doc) => {
        if (!doc.data().ignore) {
          invitations.push({ ...doc.data(), id: doc.id })
        }
      })

      // only set state if quering for the logged in user
      if (email === context.getters.myEmail) context.commit('setUserPlanInvites', invitations)
      return invitations
    } catch (err) {
      console.error('failed to fetch invites for logged in user', err)
      if (Sentry) Sentry.captureException(err)
      return []
    }
  },
  async fetchPendingPlanInvitationsForBoard(context, boardId) {
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('plan_invitations')
        .where('boardId', '==', boardId)
        .where('visited', '==', false)
        .get()

      const planInvitations = []
      querySnapshot.forEach((doc) => {
        planInvitations.push({ ...doc.data(), id: doc.id })
      })
      const invitationByPlanId = groupBy(planInvitations, 'planBoardId')
      context.commit('setPlanInvitations', invitationByPlanId)
    } catch (err) {
      console.error('failed to fetch invites for the current board', err)
      if (Sentry) Sentry.captureException(err)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {}
}
