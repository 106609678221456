import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import {
  headcountFirestoreCollection,
  hiringFirestoreCollection,
  hiringRequestsFirestoreCollection
} from '../lib/HeadcountPlanCollections'

const Mutations = {
  setIsLoading: 'setIsLoading',
  setIsCreating: 'setIsCreating',
  setHiringPlan: 'setHiringPlan',
  setHeadcountPlanId: 'setHeadcountPlanId',
  setHiringRequests: 'setHiringRequests',
  addHiringRequest: 'addHiringRequest',
  removeHiringRequest: 'removeHiringRequest',
  updateHiringRequest: 'updateHiringRequest',
  setHiringPlans: 'setHiringPlans'
}

/**
 * generate default state for the module
 */
const getDefaultState = () => {
  return {
    isLoading: false,
    isCreating: false,
    hiringPlan: null,
    headcountPlanId: null,
    hiringRequests: [],
    hiringPlans: []
  }
}

const state = getDefaultState()

const mutations = {
  [Mutations.setIsLoading](_state, isLoading) {
    _state.isLoading = isLoading
  },
  [Mutations.setIsCreating](_state, isCreating) {
    _state.isCreating = isCreating
  },
  [Mutations.setHiringPlan](_state, hiringPlan) {
    _state.hiringPlan = hiringPlan
  },
  [Mutations.setHiringPlans](_state, hiringPlans) {
    _state.hiringPlans = hiringPlans
  },
  [Mutations.setHeadcountPlanId](_state, headcountPlanId) {
    _state.headcountPlanId = headcountPlanId
  },
  [Mutations.setHiringRequests](_state, hiringRequests) {
    _state.hiringRequests = hiringRequests
  },
  [Mutations.addHiringRequest](_state, hiringRequest) {
    _state.hiringRequests.push(hiringRequest)
  },
  [Mutations.removeHiringRequest](_state, { hiringRequestId }) {
    _state.hiringRequests = _state.hiringRequests.filter(
      (hiringRequest) => hiringRequest.id !== hiringRequestId
    )
  },
  [Mutations.updateHiringRequest](_state, hiringRequest) {
    const index = _state.hiringRequests.findIndex((req) => req.id === hiringRequest.id)

    if (index > -1) {
      Object.assign(_state.hiringRequests[index], hiringRequest)
    }
  }
}

const actions = {
  async fetchHeadcountPlan(context, { boardId, personId }) {
    context.commit(Mutations.setIsLoading, true)

    try {
      const response = await firebase
        .firestore()
        .collection(headcountFirestoreCollection)
        .where('boardId', '==', boardId)
        .where('managers', 'array-contains', personId)
        .get()

      if (response.docs[0]?.id) {
        context.commit(Mutations.setHeadcountPlanId, response.docs[0].id)
      }
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  },
  async fetchHiringPlan(context, { personId, headcountPlanId }) {
    context.commit(Mutations.setIsLoading, true)

    try {
      const response = await firebase
        .firestore()
        .collection(hiringFirestoreCollection)
        .where('ownerId', '==', personId)
        .where('headcountPlanId', '==', headcountPlanId)
        .get()

      const plan = response.docs[0]?.data()

      if (plan) {
        context.commit(Mutations.setHiringPlan, {
          ...plan,
          id: response.docs[0].id
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  },
  async fetchHiringPlans(context, { personId, boardId }) {
    context.commit(Mutations.setIsLoading, true)

    try {
      const response = await firebase
        .firestore()
        .collection(hiringFirestoreCollection)
        .where('ownerId', '==', personId)
        .where('boardId', '==', boardId)
        .get()

      const plans = response.docs.map((doc) => ({ ...doc?.data(), id: doc?.id }))

      if (plans?.length) {
        context.commit(Mutations.setHiringPlans, plans)
      }
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  },
  async fetchHiringPlanForScenario(context, { personId, scenarioId }) {
    context.commit(Mutations.setIsLoading, true)

    try {
      const response = await firebase
        .firestore()
        .collection(hiringFirestoreCollection)
        .where('ownerId', '==', personId)
        .where('fromScenarioId', '==', scenarioId)
        .get()

      const plan = response.docs[0]?.data()

      if (plan) {
        context.commit(Mutations.setHiringPlan, {
          ...plan,
          id: response.docs[0].id
        })
        return
      }
      // if plan not found, reset data
      context.commit(Mutations.setHiringPlan, null)
      context.commit(Mutations.setHiringRequests, [])
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  },
  async fetchHiringRequests(context, { hiringPlanId }) {
    context.commit(Mutations.setIsLoading, true)

    try {
      const response = await firebase
        .firestore()
        .collection(hiringRequestsFirestoreCollection)
        .where('hiringPlanId', '==', hiringPlanId)
        .get()

      const requests = response.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }))

      if (requests) {
        context.commit(Mutations.setHiringRequests, requests)
        return
      }
      context.commit(Mutations.setHiringRequests, [])
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  },
  async createPlan(context, { headcountPlanId, ownerId }) {
    context.commit(Mutations.setIsCreating, true)

    try {
      const response = await firebase.firestore().collection(hiringFirestoreCollection).add({
        headcountPlanId,
        ownerId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })

      const plan = await response.get()
      context.commit(Mutations.setHiringPlan, { ...plan.data(), id: plan.id })
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsCreating, false)
    }
  },
  async createPlanFromScenario(context, { ownerId, boardId, fromScenarioId, name }) {
    context.commit(Mutations.setIsCreating, true)

    try {
      const response = await firebase.firestore().collection(hiringFirestoreCollection).add({
        headcountPlanId: null,
        ownerId,
        boardId,
        fromScenarioId,
        name,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })

      const plan = await response.get()
      context.commit(Mutations.setHiringPlan, { ...plan.data(), id: plan.id })
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsCreating, false)
    }
  },
  async createHiringRequest(context, { hiringPlanId, ownerId, boardId, data }) {
    context.commit(Mutations.setIsCreating, true)

    try {
      const response = await firebase
        .firestore()
        .collection(hiringRequestsFirestoreCollection)
        .add({
          hiringPlanId,
          ownerId,
          boardId,
          ...data,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        })

      const plan = await response.get()
      context.commit(Mutations.addHiringRequest, {
        ...plan.data(),
        id: plan.id
      })
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsCreating, false)
    }
  },
  async deleteHiringRequest(context, { id }) {
    context.commit(Mutations.setIsLoading, true)

    try {
      await firebase.firestore().collection(hiringRequestsFirestoreCollection).doc(id).delete()

      context.commit(Mutations.removeHiringRequest, { hiringRequestId: id })
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  },
  async updateHiringRequest(context, data) {
    context.commit(Mutations.setIsLoading, true)

    try {
      await firebase
        .firestore()
        .collection(hiringRequestsFirestoreCollection)
        .doc(data.id)
        .update(data)

      context.commit(Mutations.updateHiringRequest, data)
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  }
}

const getters = {
  isLoading: (_state) => _state.isLoading,
  isCreating: (_state) => _state.isCreating,
  headcountPlanId: (_state) => _state.headcountPlanId,
  hiringPlan: (_state) => _state.hiringPlan,
  all: (_state) => _state.hiringPlans,
  hiringRequests: (_state) =>
    _state.hiringRequests.sort((a, b) =>
      `${a.jobCode}`.localeCompare(`${b.jobCode}`, 'en', { numeric: true })
    )
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {}
}
