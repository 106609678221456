import { ACCESS_LEVELS_DICT } from '@/lib/Access.js'
import idCreator from '@/lib/idCreator.js'
import { cloneBoard } from '@/services/firestore.service'
import { batchWrapper } from '@/utils/firebaseHelper.js'
import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { cloneDeep, each, find, keyBy, union, unionBy, uniqBy } from 'lodash-es'
/**
 * Return the default state for this module
 *
 * @return {Object} state
 */
const getDefaultState = () => {
  return {
    boards: [],
    fetchCount: 0
  }
}

const state = getDefaultState()

const mutations = {
  setBoards: (_state, boards) => {
    _state.boards = boards
    _state.fetchCount++
  },

  reset(_state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(_state, getDefaultState())
  },

  updateBoard(_state, { boardId, key, value }) {
    const clone = cloneDeep(_state.boards)
    each(clone, (obj) => {
      if (obj.boardId === boardId) {
        obj[key] = value
      }
    })
    _state.boards = clone
  },

  addBoard(_state, board) {
    _state.boards.push(board)
  }
}

const getters = {
  getBoard: (_state) => (boardId) => _state.boards.find((board) => board.boardId === boardId),
  getBudget: (_state, _getters) => (boardId) => _getters.getBoard(boardId)?.budget,
  getAccessLevel:
    (_, _getters) =>
    ({ boardId, uid }) => {
      const board = _getters.getBoard(boardId)
      const levelRecord = board?.accessLevels?.find((level) => level.uid === uid)

      return levelRecord?.accessLevel || ACCESS_LEVELS_DICT.NO_ACCESS
    },
  boards: (_state) => _state.boards,
  isSampleData: (_state, _getters) => (boardId) => _getters.getBoard(boardId)?.sample,
  mainBoards: (_state) => _state.boards.filter((board) => board.masterBranch),
  plans: (_state) => (boardId) =>
    _state.boards.filter(
      (board) => !board.masterBranch && board.originalBoardId === boardId && !board?.deletedAt
    ),
  defaultBoard: (_, _getters) => _getters.mainBoards[0],
  accessLevels: (_, _getters) => (boardId) => _getters.getBoard(boardId)?.accessLevels,
  accessLevelsByUid: (_, _getters) => (boardId) => keyBy(_getters.accessLevels(boardId), 'uid')
}

const actions = {
  async checkBoardAccess(context, { boardId }) {
    try {
      await firebase.firestore().collection('board').doc(boardId).get()
      return true
    } catch (e) {
      // Access not possible
      return false
    }
  },
  async fetchAllBoards(context, userId) {
    const querySnapshot = await firebase
      .firestore()
      .collection('board')
      .where('owners', 'array-contains', userId)
      .where('companyId', '!=', null)
      .get()

    const boards = []

    querySnapshot.forEach((doc) => {
      const data = cloneDeep(doc.data())
      if (!data.companyId || data.companyId === 'undefined' || data.companyId === '') {
        data.companyName = null
        data.companyId = null
      }

      if (!data.accessLevels) {
        data.accessLevels = []
      }

      const uniqAccessLevels = uniqBy(data.accessLevels, 'uid')
      if (uniqAccessLevels.length !== data.accessLevels.length) {
        data.accessLevels = uniqAccessLevels
      }

      boards.push(data)
    })

    context.commit('setBoards', boards)
  },
  async updateAccessLevel(context, { companyId, myUid, targetUid, newAccessLevel, boardId }) {
    const querySnapshot = await firebase
      .firestore()
      .collection('board')
      .where('owners', 'array-contains', myUid)
      .where('companyId', '==', companyId)
      .where('boardId', '==', boardId)
      .get()

    const batch = firebase.firestore().batch()

    querySnapshot.forEach((doc) => {
      if (doc.data().owners.includes(targetUid)) {
        let accessLevels = doc.data().accessLevels
        let owners = doc.data().owners

        let target = {}
        if (newAccessLevel === ACCESS_LEVELS_DICT.NO_ACCESS) {
          // remove this person from the list
          accessLevels = accessLevels.filter((acl) => acl.uid !== targetUid)
          owners = owners.filter((uid) => uid !== targetUid)

          batch.update(doc.ref, {
            accessLevels,
            owners: firebase.firestore.FieldValue.arrayRemove(targetUid),
            updatedBy: myUid,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          })
        } else {
          target = find(accessLevels, { uid: targetUid })
          target.accessLevel = newAccessLevel

          batch.update(doc.ref, {
            accessLevels,
            owners,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedBy: myUid
          })
        }
      }
    })
    await batch.commit()
  },

  // Only used for creating sample board
  clone(context, { sourceId, name = 'New plan' }) {
    return cloneBoard({
      srcBoardId: sourceId,
      newBoardName: name,
      boardProperties: {
        sample: true
      }
    })
  },
  reset({ commit }) {
    commit('reset')
  },
  async createNewBoard(
    context,
    { accessArray, ownersArray, boardName, boardDescription, companyName, boardId }
  ) {
    const newBoardId = boardId || idCreator.createBoardId()

    const newBoardObj = {
      accessLevels: accessArray,
      boardId: newBoardId,
      boardName: boardName || companyName,
      boardDescription: boardDescription || '',
      companyId: idCreator.createCompanyId(),
      companyName,
      masterBranch: true,
      originalBoardId: newBoardId,
      owners: ownersArray,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedBy: context.rootGetters.uid
    }

    context.commit('addBoard', newBoardObj)
    await firebase.firestore().collection('board').doc(newBoardId).set(newBoardObj)

    return newBoardObj
  },
  updateBoard(context, { boardId, key, value }) {
    context.commit('updateBoard', { boardId, key, value })
  },
  submitBoard(context, { boardId }) {
    const targetBoard = find(context.state.boards, (board) => {
      return board.boardId === boardId
    })

    try {
      if (!targetBoard) return
      const ref = firebase.firestore().collection('board').doc(boardId)
      const _board = cloneDeep(targetBoard)
      _board.boardId = boardId
      if (!_board.createdAt) _board.createdAt = firebase.firestore.FieldValue.serverTimestamp()
      _board.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
      _board.updatedBy = context.rootGetters.uid
      ref.set(_board)
    } catch (err) {
      console.log('err in submitBoard', err)
    }
  },
  async deleteClonedBoard(context, { boardId }) {
    const targetBoard = context.state.boards.find(
      (board) => board.boardId === boardId && !board.masterBranch
    )

    if (!targetBoard) return

    // Safeguard.
    // It's important to not delete the wrong data :)
    //   - Do not allow delete if boardId is not set
    //   - Do not allow delete if this is the master branch
    // There is an additional safeguard in Firestore rules
    // to remove the single point of failiure.
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('people')
        .where('boardId', '==', boardId)
        .get()

      await batchWrapper(
        querySnapshot.docs.map((doc) => doc.ref),
        'delete'
      )
    } catch (e) {
      console.log('error in people deletion')
      Sentry.captureException(e)
    }

    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('squads')
        .where('boardId', '==', boardId)
        .get()
      await batchWrapper(
        querySnapshot.docs.map((doc) => doc.ref),
        'delete'
      )
    } catch (e) {
      console.log('error in squads deletion')
    }

    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('compensations')
        .where('boardId', '==', boardId)
        .get()
      await batchWrapper(
        querySnapshot.docs.map((doc) => doc.ref),
        'delete'
      )
    } catch (e) {
      console.log('error in compensations deletion')
    }

    // delete associated comments/logs
    try {
      await context.dispatch(
        'planLogs/deletePlanLogs',
        { planBoardId: targetBoard.boardId, boardId: targetBoard.originalBoardId },
        { root: true }
      )
    } catch (e) {
      console.log('error in deleting plan logs')
    }

    //delete associated pending invites for that plan
    try {
      await context.dispatch(
        'deletePlanInvitationsForOutsiders',
        { planBoardId: targetBoard.boardId, boardId: targetBoard.originalBoardId },
        { root: true }
      )
    } catch (e) {
      console.log('error in deleting pending plan invites')
    }

    try {
      await firebase.firestore().collection('board').doc(boardId).delete()
    } catch (e) {
      console.log('error in board deletion')
    }
  },
  updateBudget(context, { boardId, budget }) {
    context.commit('updateBoard', { boardId, key: 'budget', value: budget })
    firebase
      .firestore()
      .collection('board')
      .doc(`${boardId}`)
      .update({ budget, updatedBy: context.rootGetters.uid })
  },
  async addCollaborators(
    { commit, getters, rootGetters },
    { boardId, employees, setAsAdmin = false }
  ) {
    try {
      const ref = firebase.firestore().collection('board').doc(boardId)

      const board = getters.getBoard(boardId)
      const localCollaborators = board?.collaborators || []
      const localOwners = board?.owners || []
      const localAccessLevels = board?.accessLevels || []

      // Get updated board data. In case it was updated by another user
      const currentBoard = await ref.get()
      const currentAccessLevels = currentBoard.data.accessLevels || []
      const currentCollaborators = currentBoard.data.collaborators || []
      const currentOwners = currentBoard.data.owners || []

      const personIds = employees.map((employee) => employee.personId)
      const userIds = employees.map((employee) => employee.userId)
      // for submitted plans add collaborators as admin
      const accessLevels = employees.map((employee) => ({
        uid: employee.userId,
        accessLevel: setAsAdmin ? ACCESS_LEVELS_DICT.ADMIN : employee.accessLevel
      }))

      const updatedAccessLevels = unionBy(
        accessLevels,
        currentAccessLevels,
        localAccessLevels,
        'uid'
      )
      const updatedCollaborators = union(currentCollaborators, localCollaborators, personIds)
      const updatedOwners = union(currentOwners, localOwners, userIds)

      try {
        const ret = await ref.update({
          collaborators: updatedCollaborators,
          owners: updatedOwners,
          accessLevels: updatedAccessLevels,
          updatedBy: rootGetters.uid,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        })

        console.log(ret)
      } catch (e) {
        console.error(e)
      }

      commit('updateBoard', {
        boardId,
        key: 'collaborators',
        value: updatedCollaborators
      })

      commit('updateBoard', {
        boardId,
        key: 'owners',
        value: updatedOwners
      })

      commit('updateBoard', {
        boardId,
        key: 'accessLevels',
        value: updatedAccessLevels
      })
    } catch (e) {
      console.log('error while adding collaborator', e)
      Sentry.captureException(e)
    }
  },
  async addOwners({ commit, getters, rootGetters }, { boardId, userIds }) {
    try {
      const ref = firebase.firestore().collection('board').doc(boardId)

      const board = getters.getBoard(boardId)
      const localOwners = board?.owners || []
      const localAccessLevels = board?.accessLevels || []

      // Get updated board data. In case it was updated  by another user
      const currentBoard = await ref.get()
      const currentAccessLevels = currentBoard.data.accessLevels || []
      const currentOwners = currentBoard.data.owners || []

      // for submitted plans add collaborators as admin
      const accessLevels = userIds.map((userId) => ({
        uid: userId,
        accessLevel: ACCESS_LEVELS_DICT.ADMIN
      }))

      const updatedAccessLevels = unionBy(
        accessLevels,
        currentAccessLevels,
        localAccessLevels,
        'uid'
      )
      const updatedOwners = union(currentOwners, localOwners, userIds)

      try {
        const ret = await ref.set(
          {
            boardId,
            owners: updatedOwners,
            accessLevels: updatedAccessLevels,
            updatedBy: rootGetters.uid,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          },
          { merge: true }
        )

        console.log(ret)
      } catch (e) {
        console.error(e)
      }

      commit('updateBoard', {
        boardId,
        key: 'owners',
        value: updatedOwners
      })

      commit('updateBoard', {
        boardId,
        key: 'accessLevels',
        value: updatedAccessLevels
      })
    } catch (e) {
      console.log('error while adding collaborator', e)
      Sentry.captureException(e)
    }
  },
  async removeCollaborators({ commit, getters, rootGetters }, { boardId, employees }) {
    const ref = firebase.firestore().collection('board').doc(boardId)

    try {
      const personIds = employees.map((employee) => employee.personId)
      const userIds = employees.map((employee) => employee.userId)
      const accessLevels = employees.map((employee) => ({
        uid: employee.userId,
        accessLevel: employee.accessLevel
      }))

      await ref.update({
        collaborators: firebase.firestore.FieldValue.arrayRemove(...personIds),
        owners: firebase.firestore.FieldValue.arrayRemove(...userIds),
        accessLevels: firebase.firestore.FieldValue.arrayRemove(...accessLevels),
        updatedBy: rootGetters.uid,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })

      const board = getters.getBoard(boardId)
      const localCollaborators =
        board?.collaborators.filter((collaborator) => !personIds.includes(collaborator)) || []
      const localOwners = board?.owners.filter((owner) => !userIds.includes(owner)) || []

      commit('updateBoard', {
        boardId,
        key: 'collaborators',
        value: localCollaborators
      })

      commit('updateBoard', {
        boardId,
        key: 'owners',
        value: localOwners
      })
    } catch (e) {
      console.log(e)
    }
  },
  async toggleSyncWithOrg({ commit, rootGetters }, { boardId, isSyncEnabled }) {
    try {
      commit('updateBoard', { boardId, key: 'isSyncEnabled', value: isSyncEnabled })
      await firebase.firestore().collection('board').doc(boardId).update({
        isSyncEnabled,
        updatedBy: rootGetters.uid,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
    } catch (err) {
      Sentry.captureException(err)
    }
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
