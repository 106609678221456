export const Fields = {
  department: 'department',
  location: 'location',
  email: 'email',
  salary: 'salary',
  salaryNewRole: 'salary-newrole',
  name: 'name',
  plannedHireDate: 'planned-hire-date'
}

export const FieldsLabels = {
  [Fields.name]: 'Name',
  [Fields.department]: 'Department',
  [Fields.location]: 'Location',
  [Fields.email]: 'Email',
  [Fields.salary]: 'Salary',
  [Fields.salaryNewRole]: 'Salary (new role)',
  [Fields.plannedHireDate]: 'Hire date (new role)'
}
