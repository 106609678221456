<template>
  <div>
    <div
      aria-live="assertive"
      class="pointer-events-none fixed inset-0 z-50 flex items-start px-4 py-6 sm:p-6"
    >
      <div class="flex w-full flex-col items-center space-y-4">
        <transition-group
          enter-active-class="transform ease-out duration-300 transition-all"
          enter-from-class="translate-y-2 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition-all ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-for="messageObject in topMessages"
            :key="messageObject.id"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div :class="['p-4', messageObject?.options?.bg || '']">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <!-- <InformationCircleIcon class="h-6 w-6 text-bluedark-400" aria-hidden="true" /> -->
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-normal text-gray-900">{{ messageObject.message }}</p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    class="inline-flex rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    @click="handleClose(messageObject.id)"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <div
      aria-live="assertive"
      class="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:p-6"
    >
      <div class="flex w-full flex-col items-center space-y-4">
        <transition-group
          enter-active-class="transform ease-out duration-300 transition-all"
          enter-from-class="translate-y-2 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition-all ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-for="messageObject in bottomMessages"
            :key="messageObject.id"
            class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
            :class="{
              'bg-error-100 ring-error-500 ring-opacity-100':
                messageObject.options?.type === 'error'
            }"
          >
            <div :class="['p-4', messageObject?.options?.bg || '']">
              <div class="flex items-start">
                <div class="flex-shrink-0"></div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p class="text-sm font-normal text-gray-900">{{ messageObject.message }}</p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                  <button
                    type="button"
                    class="inline-flex rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    @click="handleClose(messageObject.id)"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon class="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script setup>
import useToast from '@/hooks/use-toast.js'
import { XIcon } from '@heroicons/vue/solid'
import { computed } from 'vue'

const { messages, removeMessage } = useToast()

const topMessages = computed(() =>
  messages.value.filter((message) => message.options?.position === 'top')
)
const bottomMessages = computed(() =>
  messages.value.filter(
    (message) => message.options?.position === 'bottom' || !message.options?.position
  )
)

const handleClose = (messageId) => {
  removeMessage(messageId)
}
</script>
