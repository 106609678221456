import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import * as Sentry from '@sentry/browser'

const state = {
  base: [],
  rates: [],
  ratesReady: false
}

const getters = {
  forexRates: (_state) => {
    return {
      base: _state.base,
      rates: _state.rates
    }
  },
  isForexReady: (_state) => {
    return _state.ratesReady
  }
}

const mutations = {
  setBase(_state, _base) {
    _state.base = _base
  },
  setRates(_state, _rates) {
    _state.rates = _rates
  },
  setRatesReady(_state, _ratesReady) {
    _state.ratesReady = _ratesReady
  }
}

const actions = {
  async fetchForexRates(context) {
    context.commit('setRatesReady', false)
    try {
      const rates = await firebase.firestore().collection('forex_rates').doc('latest').get()

      context.commit('setBase', rates.data().base)
      context.commit('setRates', rates.data().rates)
      context.commit('setRatesReady', true)
    } catch (e) {
      console.error(e)
      Sentry?.captureException(e)
      return
    }
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
