import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import { validateData, setManagers } from '../utils/googleSyncHelpers'
import { createPersonId } from '../lib/idCreator'
import { batchWrapper } from '../utils/firebaseHelper'
import { formatDateToStandard } from '../services/date.service'

const actions = {
  async backendSync(context, boardId) {
    const fetchMapping = firebase.functions().httpsCallable('googleSheetSync-sync')
    const ret = await fetchMapping({ boardId })
    return ret.data
  },
  /* Fetching the saved import mappings from the firestore. */
  async fetchSavedImportMappings(context, boardId) {
    const response = await firebase
      .firestore()
      .collection('google_sheet_import_mapping')
      .where('boardId', '==', boardId)
      .get()

    return response.docs[0]?.data()
  },

  /* Sets the Google Sheet Auto Sync Flag */
  async setGoogleSheetsAutoSync(context, { boardId, autoSync }) {
    try {
      console.log(boardId, autoSync, 'boardId, isGoogleSheetsAutoSyncOn')

      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          isGoogleSheetsAutoSyncOn: autoSync
        },
        { merge: true }
      )

      return {
        status: true,
        msg: 'Successfully saved'
      }
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },

  /* Saving the import mapping to the Firestore. */
  async saveImportMappings(context, { boardId, mapping, filePath }) {
    await firebase.firestore().collection('google_sheet_import_mapping').doc(`${boardId}`).set(
      {
        boardId,
        mapping,
        filePath
      },
      { update: true }
    )
  },
  async applyMappingToSheet(context, { filePath, boardId }) {
    const mappingObj = {
      filePath,
      boardId,
      mapping: {
        employeeId: 'Employee ID',
        name: 'Name',
        role: 'Job Title',
        email: 'Email',
        managerId: "Manager's Employee ID",
        employeeStatus: 'Status',
        startDate: 'Start Date',
        department: 'Department',
        officeLocation: 'Location',
        terminationDate: 'Terminated Date',
        photoUrl: 'Photo Link',
        salary: 'Salary',
        performance: 'Performance'
      }
    }

    await firebase
      .firestore()
      .collection('google_sheet_import_mapping')
      .doc(boardId)
      .set(mappingObj)

    return mappingObj
  },

  importGoogleSheet: async function (context, { link, boardId }) {
    const importGoogleSheet = firebase.functions().httpsCallable('googleSheetIntegration-import')
    const ret = await importGoogleSheet({ link, boardId })

    return ret
  },

  async createSheetCopy(ctx, { sourceId, title, userEmail }) {
    const createCopy = firebase.functions().httpsCallable('googleSheetIntegration-createCopy')

    const ret = await createCopy({ sourceId, title, userEmail })

    return ret
  },

  /**
   *
   * @param { Array } peopleArray
   * @param { String } boardId
   * Takes data from `peopleArray` and parses it to make it fit our people document model on firebase
   */
  //TODO: move out of vuex
  convertToAgentnoonData(ctx, { peopleArray, boardId, uid }) {
    const newPeople = []

    peopleArray.forEach((person) => {
      const personObj = {
        boardId,
        personId: createPersonId(),
        tempPersonId: person['Employee ID'],
        tempManagerId: person["Manager's Employee ID"],
        name: person['Name'],
        role: person['Job Title'],
        email: person['Email'] || '',
        avatarImg:
          person['Photo Link'] ||
          'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/open_role.png?alt=media&token=5a831181-2239-482a-93e2-0e795a4af0e5',
        officeLocation: person['Location'] || '',
        subordinates: [],
        managers: [],
        employeeStatus: person['Status'] || '',
        employeeData: {
          holidayDates: [],
          department: person['Department'] || ''
        },
        createdBy: uid,
        updatedBy: uid,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      }

      if (person['Terminated Date']) {
        personObj.employeeData.terminationDate = formatDateToStandard(person['Terminated Date'])
      }

      if (person['Start Date']) {
        personObj.employeeData.startDate = formatDateToStandard(person['Start Date'])
      }

      const compensationObj = {
        personId: personObj.personId,
        boardId,
        payRate: person['Salary'] || '',
        payPer: '',
        payType: 'salary',
        payRateEffectiveDate: '',
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      }

      newPeople.push({
        personObj,
        compensationObj
      })
    })

    validateData(newPeople)
    setManagers(newPeople)
    return newPeople
  },

  async savePeopleData(ctx, { peopleObjs }) {
    const peopleRefs = []
    peopleObjs.forEach((person) => {
      const personRef = firebase
        .firestore()
        .collection('people')
        .doc(`${person.boardId}_${person.personId}`)

      peopleRefs.push(personRef)
    })

    await batchWrapper(peopleRefs, 'set', peopleObjs)
  },

  async saveCompensationData(ctx, { compensationObjs }) {
    const compensationRefs = []
    compensationObjs.forEach((compensation) => {
      const compensationRef = firebase
        .firestore()
        .collection('compensations')
        .doc(`${compensation.boardId}_${compensation.personId}`)

      compensationRefs.push(compensationRef)
    })

    await batchWrapper(compensationRefs, 'set', compensationObjs)
  },

  async updateDocProperties(ctx, { docId, props }) {
    const updateDoc = firebase.functions().httpsCallable('googleSheetIntegration-updateDoc')
    let res
    try {
      res = await updateDoc({ props, docId })
    } catch (e) {
      console.log('err caught in updateDoc')
      let retryCount = 0
      let isDone = false
      while (!isDone && retryCount < 11) {
        try {
          retryCount++
          res = await updateDoc({ props, docId })
          isDone = true
        } catch (err) {
          console.log('Retrying document update', retryCount, err)
        }
      }
    }
    return res
  },

  async givePermission(ctx, { userEmail, docId }) {
    const givePermissionForUser = firebase
      .functions()
      .httpsCallable('googleSheetIntegration-givePermissionForUser')
    const res = await givePermissionForUser({ userEmail, docId })
    return res
  },

  /* Save salary benchmark imported data to collection. */
  async saveSalaryBenchmarkData(ctx, { boardId, data, filePath }) {
    try {
      await data.forEach(async (salary) => {
        await firebase
          .firestore()
          .collection('salary_benchmark_data')
          .doc(`${boardId}_${salary.personId}`)
          .set(salary, { update: true })
      })
      window.mixpanel.track(`import-salary-benchmark-google-sheet-data-fetched`)
    } catch (e) {
      window.console.error(`Error adding salary benchmark data for file: ${filePath}: `, e)
      if (Sentry) Sentry.captureException(e)
    }
  }
}

export default {
  state: {},
  mutations: {},
  getters: {},
  actions,
  modules: {}
}
