import useBoard from '@/hooks/use-board.js'
import { getNavigationConfig } from '@/utils/navigationData'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

/**
 * Hook that encapsulates navigation menu logic
 */
export default function useNavigation() {
  const { boardId } = useBoard()
  const route = useRoute()
  const store = useStore()

  const navigationConfig = computed(() =>
    getNavigationConfig({
      boardId: boardId.value,
      role: store.getters.role(boardId.value),
      route,
      tier: store.getters.tier,
      isVerified: store.getters.user.emailVerified
    })
  )

  return {
    navigationConfig
  }
}
