<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open" @after-leave="handleAfterLeave">
    <DialogUI as="div" class="fixed inset-0 z-50 overflow-y-auto" @close="open = false">
      <div
        class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <div class="space-y-6">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Create new plan</h3>
              <div v-if="selectedOption === NewPlanOptions.Department">
                <label for="scenario-name" class="mb-1 block text-sm font-medium text-gray-700">
                  Select department
                </label>
                <Combobox v-model="scenarioDepartment">
                  <div class="relative h-full">
                    <div class="relative w-full text-left">
                      <ComboboxButton as="div">
                        <ComboboxInput
                          placeholder="Search by department"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          @change="handleDepartmentFilter"
                        />
                      </ComboboxButton>
                    </div>
                    <div>
                      <TransitionRoot
                        leave="transition ease-in duration-100"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                        @after-leave="departmentFilter = ''"
                      >
                        <ComboboxOptions
                          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border bg-white py-1 text-sm shadow-md"
                        >
                          <div
                            v-if="filteredDepartments.length === 0"
                            class="relative cursor-default select-none py-2 px-4 text-gray-700"
                          >
                            Nothing found.
                          </div>
                          <div v-else>
                            <ComboboxOption
                              v-for="department in filteredDepartments"
                              :key="department"
                              v-slot="{ selected, active }"
                              as="template"
                              :value="department"
                            >
                              <li
                                class="relative flex h-11 w-full cursor-pointer flex-row items-center gap-2 py-2 px-3 text-gray-900 transition-colors hover:bg-gray-50 active:bg-bluedark-50"
                                :class="{
                                  'bg-gray-50': active
                                }"
                              >
                                <span
                                  class="block flex-1 truncate"
                                  :class="{
                                    'font-medium': selected,
                                    'font-normal': !selected
                                  }"
                                >
                                  {{ department }}
                                </span>
                                <span v-if="selected" class="flex items-center text-primary-600">
                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                              </li>
                            </ComboboxOption>
                          </div>
                        </ComboboxOptions>
                      </TransitionRoot>
                    </div>
                  </div>
                </Combobox>
              </div>
              <div v-if="isShowTeam">
                <label for="scenario-name" class="mb-1 block text-sm font-medium text-gray-700">
                  Select Manager
                </label>
                <Combobox v-model="scenarioTeamManagerName">
                  <div class="relative h-full">
                    <div class="relative w-full text-left">
                      <ComboboxButton as="div">
                        <ComboboxInput
                          placeholder="Search by manager's name"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          @change="handleTeamFilter"
                        />
                      </ComboboxButton>
                    </div>
                    <div>
                      <TransitionRoot
                        leave="transition ease-in duration-100"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                        @after-leave="teamFilter = ''"
                      >
                        <ComboboxOptions
                          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border bg-white py-1 text-sm shadow-md"
                        >
                          <div
                            v-if="filteredTeams.length === 0"
                            class="relative cursor-default select-none py-2 px-4 text-gray-700"
                          >
                            Nothing found.
                          </div>
                          <div v-else>
                            <ComboboxOption
                              v-for="team in filteredTeams"
                              :key="team.name"
                              v-slot="{ selected, active }"
                              as="template"
                              :value="team.name"
                            >
                              <li
                                class="relative flex h-11 w-full cursor-pointer flex-row items-center gap-2 py-2 px-3 text-gray-900 transition-colors hover:bg-gray-50 active:bg-bluedark-50"
                                :class="{
                                  'bg-gray-50': active
                                }"
                              >
                                <span
                                  class="block flex-1 truncate"
                                  :class="{
                                    'font-medium': selected,
                                    'font-normal': !selected
                                  }"
                                >
                                  {{ team.name }}
                                </span>
                                <span v-if="selected" class="flex items-center text-primary-600">
                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                              </li>
                            </ComboboxOption>
                          </div>
                        </ComboboxOptions>
                      </TransitionRoot>
                    </div>
                  </div>
                </Combobox>
              </div>
              <div v-if="selectedOption === NewPlanOptions.Location">
                <label for="scenario-name" class="mb-1 block text-sm font-medium text-gray-700">
                  Select location
                </label>
                <Combobox v-model="scenarioLocation">
                  <div class="relative h-full">
                    <div class="relative w-full text-left">
                      <ComboboxButton as="div">
                        <ComboboxInput
                          placeholder="Search by location"
                          class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          @change="handleLocationFilter"
                        />
                      </ComboboxButton>
                    </div>
                    <div>
                      <TransitionRoot
                        leave="transition ease-in duration-100"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                        @after-leave="locationFilter = ''"
                      >
                        <ComboboxOptions
                          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border bg-white py-1 text-sm shadow-md"
                        >
                          <div
                            v-if="filteredDepartments.length === 0"
                            class="relative cursor-default select-none py-2 px-4 text-gray-700"
                          >
                            Nothing found.
                          </div>
                          <div v-else>
                            <ComboboxOption
                              v-for="location in filteredLocationss"
                              :key="location"
                              v-slot="{ selected, active }"
                              as="template"
                              :value="location"
                            >
                              <li
                                class="relative flex h-11 w-full cursor-pointer flex-row items-center gap-2 py-2 px-3 text-gray-900 transition-colors hover:bg-gray-50 active:bg-bluedark-50"
                                :class="{
                                  'bg-gray-50': active
                                }"
                              >
                                <span
                                  class="block flex-1 truncate"
                                  :class="{
                                    'font-medium': selected,
                                    'font-normal': !selected
                                  }"
                                >
                                  {{ location }}
                                </span>
                                <span v-if="selected" class="flex items-center text-primary-600">
                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                              </li>
                            </ComboboxOption>
                          </div>
                        </ComboboxOptions>
                      </TransitionRoot>
                    </div>
                  </div>
                </Combobox>
              </div>
              <div v-if="selectedOption === NewPlanOptions.NewDepartment">
                <label
                  for="scenario-new-department"
                  class="block text-sm font-medium text-gray-700"
                >
                  Department Name
                </label>
                <div class="mt-1">
                  <input
                    id="scenario-new-department"
                    v-model="scenarioNewDepartment"
                    type="text"
                    name="scenario-new-department"
                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Enter department name"
                    data-intercom-target="enter-scenario-new-department"
                  />
                </div>
              </div>
              <div>
                <label for="scenario-name" class="block text-sm font-medium text-gray-700">
                  Plan name
                </label>
                <div class="mt-1">
                  <input
                    id="scenario-name"
                    v-model="scenarioName"
                    type="text"
                    name="scenario-name"
                    class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Enter a plan name"
                    data-intercom-target="enter-scenario-name"
                  />
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                :class="[
                  'inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium shadow-sm sm:col-start-2 sm:text-sm',
                  disableCreateButton
                    ? 'cursor-not-allowed rounded bg-blue-500 text-white opacity-50'
                    : 'bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                ]"
                :disabled="isCreating"
                data-intercom-target="save-scenario"
                @click="confirmClone"
              >
                Create plan

                <SpinnerComp v-if="isCreating" color="blue" />
              </button>
              <button
                ref="cancelButtonRef"
                type="button"
                class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                @click="open = false"
              >
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </DialogUI>
  </TransitionRoot>
</template>

<script setup>
import SpinnerComp from '@/components/SpinnerComp.vue'
import { ACCESS_LEVELS_DICT } from '@/lib/Access.js'
import idCreator from '@/lib/idCreator.js'
import NewPlanOptions from '@/lib/NewPlanOptions'
import NodeTypes from '@/lib/NodeTypes'
import { getNewRole } from '@/lib/PersonDataProcessing'
import { cloneBoard } from '@/services/firestore.service'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  DialogOverlay,
  Dialog as DialogUI,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/solid'
import * as Sentry from '@sentry/browser'
import { uniq } from 'lodash-es'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { requestForecastGeneration } from '@/services/forecast-api.service'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
    required: true
  },
  boardObj: {
    type: Object,
    default: null,
    required: false
  },
  selectedOption: {
    type: String,
    default: 'a',
    required: true
  }
})

const emit = defineEmits(['close'])

const store = useStore()
const route = useRoute()
const { push } = useRouter()

const open = ref(props.show)
const scenarioName = ref('')
const scenarioDepartment = ref('')
const scenarioLocation = ref('')

const scenarioTeamManagerName = ref('')
const scenarioNewDepartment = ref('')
const departmentFilter = ref('')
const locationFilter = ref('')
const teamFilter = ref('')

const boardId = computed(() => route.params.boardId)
const myPersonId = computed(() => store.getters.myPersonId(boardId.value))
const people = computed(() => uniq(store.getters['people/people'](boardId.value)))
const ceo = computed(() => store.getters['people/getCEO'](boardId.value))
const user = computed(() => store.getters.user)
const peopleDepartmentsList = computed(() => store.getters['people/departmentList'](boardId.value))
const peopleLocationList = computed(() => store.getters['people/locationList'](boardId.value))
const masterBoardPeopleList = computed(() => store.getters['people/activeEmployees'](boardId.value))
const managersList = computed(() =>
  masterBoardPeopleList.value.filter(
    (person) => person.subordinates.length > 0 && person.name !== '' && person.removed !== false
  )
)
const groupPeopleByDepartment = computed(() =>
  store.getters['people/groupPeopleByDepartment'](boardId.value)
)
const groupPeopleByLocation = computed(() =>
  store.getters['people/groupPeopleByLocation'](boardId.value)
)

const role = computed(() => store.getters.role(boardId.value))
const isShowTeam = computed(
  () =>
    props.selectedOption === NewPlanOptions.Team &&
    (role.value === ACCESS_LEVELS_DICT.ADMIN || role.value === ACCESS_LEVELS_DICT.EXECUTIVE)
)
const filteredDepartments = computed(() =>
  peopleDepartmentsList.value.filter((department) =>
    department?.toLowerCase().includes(departmentFilter?.value?.toLowerCase())
  )
)
const filteredLocationss = computed(() =>
  peopleLocationList.value.filter((location) =>
    location?.toLowerCase().includes(locationFilter?.value?.toLowerCase())
  )
)
const filteredTeams = computed(() =>
  managersList.value.filter((manager) =>
    manager?.name?.toLowerCase().includes(teamFilter?.value?.toLowerCase())
  )
)
const disableCreateButton = computed(
  () =>
    !scenarioName.value ||
    (props.selectedOption === NewPlanOptions.Department && !scenarioDepartment.value) ||
    (props.selectedOption === NewPlanOptions.Team &&
      !scenarioTeamManagerName.value &&
      isShowTeam.value) ||
    (props.selectedOption === NewPlanOptions.NewDepartment && !scenarioNewDepartment.value)
)

const isCreating = ref(false)

/**
 * update scenario department filter value
 * @param event event object
 */
const handleDepartmentFilter = (event) => {
  departmentFilter.value = event.target.value
}

/**
 * update scenario department filter value
 * @param event event object
 */
const handleLocationFilter = (event) => {
  locationFilter.value = event.target.value
}

/**
 * update scenario team filter value
 * @param event event object
 */
const handleTeamFilter = (event) => {
  teamFilter.value = event.target.value
}

/**
 * Reset the current modal data
 */
const closeModal = () => {
  scenarioName.value = ''
  emit('close')
}

/**
 * Create clone data using the current master board object.
 */
const confirmClone = async () => {
  if (disableCreateButton.value) return
  if (!props.boardObj) return

  //TODO: change to personId comparison
  const managerId = isShowTeam.value
    ? people.value.find((person) => person.name === scenarioTeamManagerName.value)?.personId
    : myPersonId.value

  try {
    isCreating.value = true
    let newBoardId = null
    switch (props.selectedOption) {
      case NewPlanOptions.Company:
        window.mixpanel.track('scenario_create_new_scenario_for_company')
        newBoardId = await cloneBoard({
          srcBoardId: boardId.value,
          newBoardName: scenarioName.value
        })

        break
      case NewPlanOptions.Department:
        window.mixpanel.track('scenario_create_new_scenario_for_department')
        newBoardId = await cloneBoard({
          srcBoardId: boardId.value,
          newBoardName: scenarioName.value,
          peopleList: groupPeopleByDepartment.value[scenarioDepartment.value],
          // if peopleList is empty, it will not clone people from the master board
          clonePeople: false
        })
        break
      case NewPlanOptions.Team:
        window.mixpanel.track('scenario_create_new_scenario_for_team')
        newBoardId = await cloneBoard({
          srcBoardId: boardId.value,
          newBoardName: scenarioName.value,
          peopleList: store.getters['people/teamAndManagerInSubtree']({
            boardId: boardId.value,
            personId: managerId
          }),
          // if peopleList is empty, it will not clone people from the master board
          clonePeople: false
        })
        break

      case NewPlanOptions.Location:
        window.mixpanel.track('scenario_create_new_scenario_for_new_location')
        newBoardId = await cloneBoard({
          srcBoardId: boardId.value,
          newBoardName: scenarioName.value,
          peopleList: groupPeopleByLocation.value[scenarioLocation.value],
          // if peopleList is empty, it will not clone people from the master board
          clonePeople: false
        })
        break

      case NewPlanOptions.NewDepartment:
        window.mixpanel.track('scenario_create_new_scenario_for_new_department')
        newBoardId = await handleAddNewDepartment({
          srcBoardId: boardId.value,
          newBoardName: scenarioName.value,
          uid: user.value.uid
        })
        break

      case NewPlanOptions.RolePlanning:
        window.mixpanel.track('scenario_create_new_scenario_for_role_planning')
        newBoardId = await handleAddRoleBasedPlan({
          srcBoardId: boardId.value,
          newBoardName: scenarioName.value,
          uid: user.value.uid
        })
        break

      default:
        break
    }
    closeModal()

    if (newBoardId === 'error') {
      // TODO error message
    }

    if (newBoardId) {
      await store.dispatch('people/fetch', { boardId: newBoardId, force: true })

      await push({
        name: 'Plan View',
        params: { boardId: boardId.value, planId: newBoardId }
      })

      await requestForecastGeneration(newBoardId)
    }
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
  } finally {
    isCreating.value = false
  }
}

const handleAddNewDepartment = async ({ srcBoardId, newBoardName, uid }) => {
  const newPersonId = idCreator.createPersonId()
  //generate the board
  const newBoardId = await cloneBoard({
    srcBoardId,
    newBoardName,
    peopleList: [],
    clonePeople: false
  })
  //generate the new role as HOD
  const newRoleData = {
    ...getNewRole({
      newPersonId,
      managerObj: ceo.value,
      boardId: newBoardId,
      creatorUid: uid
    }),
    role: `Head of ${scenarioNewDepartment.value}`,
    department: scenarioNewDepartment.value,
    scenarioMetaData: { disableDelete: true }
  }

  await store.dispatch('people/addOpenRole', newRoleData)

  return newBoardId
}

const handleAddRoleBasedPlan = async ({ srcBoardId, newBoardName, uid }) => {
  const newPersonId = idCreator.createPersonId()
  //generate the board
  const newBoardId = await cloneBoard({
    srcBoardId,
    newBoardName,
    boardProperties: {
      rolePlanning: true
    }
  })

  const newRoleData = {
    ...getNewRole({
      newPersonId,
      managerObj: ceo.value,
      boardId: newBoardId,
      creatorUid: uid
    }),
    type: NodeTypes.RolePlanningGoal,
    rolePlanning: {
      name: 'New goal',
      ownerId: null
    }
  }

  await store.dispatch('people/addOpenRole', newRoleData)

  return newBoardId
}

/**
 * Run the close modal event after leave this modal
 */
const handleAfterLeave = () => {
  closeModal()
}

watch(
  () => props.show,
  () => {
    if (!props.show) {
      //reset values on form close
      scenarioName.value = ''
      scenarioDepartment.value = ''
      scenarioTeamManagerName.value = ''
      scenarioNewDepartment.value = ''
    }

    if (!props.boardObj) return

    open.value = props.show

    if (open.value) {
      store.dispatch('people/fetch', { boardId: boardId.value, force: false })
    }
  }
)
</script>
