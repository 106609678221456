export default [
  {
    avatarImg:
      'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/users%2Favatars%2Fp_6F5Jap8ETqo7NqVsZ0NDQ.jpeg?alt=media&token=ba14d35c-21cf-4a7a-888f-bc90046f84be',

    createdAt: {
      _nanoseconds: 365000000,
      _seconds: 1675795045
    },
    createdBy: '',
    customFields: [],
    email: '',
    employeeData: {
      department: 'Operations',
      holidayDates: [],
      startDate: '',
      terminationDate: ''
    },
    employeeStatus: '',
    managers: ['p_BIwwiEJmo9ye2A45htDKln'],
    name: 'Jack Selby',
    officeLocation: 'Paradise Valley, Arizona',
    personId: 'p_6F5Jap8ETqo7NqVsZ0NDQ',
    removed: '',
    role: 'Vice President of Corporate and International Development',
    scenarioMetaData: {
      changes: [],
      state: ['Edited']
    },
    sourceEmployeeId: '7',
    subordinates: [],
    tags: [],
    temp: {
      employeeId: '7',
      managerId: '2'
    },
    updatedBy: 'mVQcQ3tTNtampsCrLIid5Udf1EH2'
  },
  {
    avatarImg:
      'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/users%2Favatars%2Fp_6GYXczUaPqGjwK2GANtKw3.jpeg?alt=media&token=0cee7cdc-321b-4b58-8dad-517355520a06',

    createdAt: {
      _nanoseconds: 365000000,
      _seconds: 1675795045
    },
    createdBy: '',
    customFields: [],
    email: '',
    employeeData: {
      department: 'Legal',
      holidayDates: [],
      startDate: '',
      terminationDate: ''
    },
    employeeStatus: '',
    managers: ['p_BIwwiEJmo9ye2A45htDKln'],
    name: 'John Muller',
    officeLocation: 'New York, New York',
    personId: 'p_6GYXczUaPqGjwK2GANtKw3',
    removed: '',
    role: 'General Counsel',
    scenarioMetaData: {
      changes: [],
      state: ['Edited']
    },
    sourceEmployeeId: '12',
    subordinates: [],
    tags: [],
    temp: {
      employeeId: '12',
      managerId: '2'
    },
    updatedBy: 'mVQcQ3tTNtampsCrLIid5Udf1EH2'
  },
  {
    avatarImg:
      'https://storage.googleapis.com/orgraph-d57a6.appspot.com/users/avatars/p_BIwwiEJmo9ye2A45htDKln.jpeg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=firebase-adminsdk-chebn%40orgraph-d57a6.iam.gserviceaccount.com%2F20230209%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20230209T164402Z&X-Goog-Expires=901&X-Goog-SignedHeaders=host&X-Goog-Signature=11fbf6e91594bc05e856a3d8a4f9cb8690f8b0fb33d7744182d82cdc1cbc4fae20b7170834e0b17556d2041498aaf39ed76c84d5b97ae0f9f94136671fb73320c7fcde0f7058c394864b073af5ca379574a94b964aa4e3d3b58a94a027e6d2ecae748e61614b3e875f43d5b1e046397f50b041bf17293f37a395a2ff2dd244fc6366faeb79d9c36c65bc61f45cc1e7a64fe35d5490e4a19ecf685c76220eb53c899b7b6df3b05d660241a9d8ecae42eb4228ed0872480decda66e735dc1b362e2b15bb516acb0f4be652a90f39ebbe747ebe44347c356e0ec4c88af24c12bc463d31f4ba5ee35bc8d594a5814f8c031d1b5e016ca29a99d5aa0f8f8e9c3b0a6c',

    createdAt: {
      _nanoseconds: 365000000,
      _seconds: 1675795045
    },
    createdBy: '',
    customFields: [],
    email: '',
    employeeData: {
      department: 'Management',
      holidayDates: [],
      startDate: '',
      terminationDate: ''
    },
    employeeStatus: '',
    isRoot: true,
    managers: ['p_yx7XYVds8L8ZVqBtaitFh'],
    name: 'Peter Thiel',
    officeLocation: 'San Francisco, California',
    personId: 'p_BIwwiEJmo9ye2A45htDKln',
    removed: '',
    role: 'Chief Operating Officer & President',
    scenarioMetaData: {
      changes: [],
      state: ['Edited']
    },
    sourceEmployeeId: '2',
    subordinates: [
      'p_ZxPAFw6wILLW2X0stOqVDK',
      'p_Ipd5CZLgvANmg7lSlMyJM1',
      'p_H9yE6ApaemZ6n1FET2htM',
      'p_RtNG9XWeU2Xrsf4321Qbt',
      'p_6F5Jap8ETqo7NqVsZ0NDQ',
      'p_TDKoy9vZofFuX4ypANVVawz',
      'p_WWWgyiONMAxrAhwXqZRO5',
      'p_ZkssPYbjZ5vlRS51Zr5Rp',
      'p_DGAUJyqSrLt0GMaSWLIr2',
      'p_6GYXczUaPqGjwK2GANtKw3'
    ],
    tags: [],
    temp: {
      employeeId: '2',
      managerId: '1'
    },
    updatedBy: 'MapVVRBAdfWwyOPPPTem8GMyceo1'
  },
  {
    avatarImg:
      'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/users%2Favatars%2Fp_DGAUJyqSrLt0GMaSWLIr2.jpeg?alt=media&token=03afd601-a611-426b-9512-6d1f6dd43cf1',

    createdAt: {
      _nanoseconds: 365000000,
      _seconds: 1675795045
    },
    createdBy: '',
    customFields: [],
    email: '',
    employeeData: {
      department: 'Operations',
      holidayDates: [],
      startDate: '',
      terminationDate: ''
    },
    employeeStatus: '',
    managers: ['p_BIwwiEJmo9ye2A45htDKln'],
    name: 'Sandeep Lal',
    officeLocation: 'Singapore',
    personId: 'p_DGAUJyqSrLt0GMaSWLIr2',
    removed: '',
    role: 'Senior Vice President of Customer Service and Operations',
    scenarioMetaData: {
      changes: [],
      state: ['Edited']
    },
    sourceEmployeeId: '11',
    subordinates: [],
    tags: [],
    temp: {
      employeeId: '11',
      managerId: '2'
    },
    updatedBy: 'mVQcQ3tTNtampsCrLIid5Udf1EH2'
  },
  {
    avatarImg:
      'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/users%2Favatars%2Fp_H9yE6ApaemZ6n1FET2htM.jpeg?alt=media&token=5646db92-eab1-48eb-b3c3-0077a2c8b8c2',

    createdAt: {
      _nanoseconds: 365000000,
      _seconds: 1675795045
    },
    createdBy: '',
    customFields: [],
    email: '',
    employeeData: {
      department: 'Sales',
      holidayDates: [],
      startDate: '',
      terminationDate: ''
    },
    employeeStatus: '',
    managers: ['p_BIwwiEJmo9ye2A45htDKln'],
    name: 'Reid Hoffman',
    officeLocation: 'United States',
    personId: 'p_H9yE6ApaemZ6n1FET2htM',
    removed: '',
    role: 'Executive Vice President of Business Development',
    scenarioMetaData: {
      changes: [],
      state: ['Edited']
    },
    sourceEmployeeId: '5',
    subordinates: [],
    tags: [],
    temp: {
      employeeId: '5',
      managerId: '2'
    },
    updatedBy: 'mVQcQ3tTNtampsCrLIid5Udf1EH2'
  },
  {
    avatarImg:
      'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/users%2Favatars%2Fp_Ipd5CZLgvANmg7lSlMyJM1.jpeg?alt=media&token=e900bf88-8f01-4636-a9a5-e8965b7267ad',

    createdAt: {
      _nanoseconds: 365000000,
      _seconds: 1675795045
    },
    createdBy: '',
    customFields: [],
    email: '',
    employeeData: {
      department: 'Operations',
      holidayDates: [],
      startDate: '',
      terminationDate: ''
    },
    employeeStatus: '',
    managers: ['p_BIwwiEJmo9ye2A45htDKln'],
    name: 'David Sacks',
    officeLocation: 'San Francisco, California',
    personId: 'p_Ipd5CZLgvANmg7lSlMyJM1',
    removed: '',
    role: 'Chief Operating Officer',
    scenarioMetaData: {
      changes: [],
      state: ['Edited']
    },
    sourceEmployeeId: '4',
    subordinates: [],
    tags: [],
    temp: {
      employeeId: '4',
      managerId: '2'
    },
    updatedBy: 'mVQcQ3tTNtampsCrLIid5Udf1EH2'
  },
  {
    avatarImg:
      'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/users%2Favatars%2Fp_RtNG9XWeU2Xrsf4321Qbt.jpeg?alt=media&token=c3914e2b-0a61-4ee1-8d6b-387c16a0cb5b',

    createdAt: {
      _nanoseconds: 365000000,
      _seconds: 1675795045
    },
    createdBy: '',
    customFields: [],
    email: '',
    employeeData: {
      department: 'Finance',
      holidayDates: [],
      startDate: '',
      terminationDate: ''
    },
    employeeStatus: '',
    managers: ['p_BIwwiEJmo9ye2A45htDKln'],
    name: 'Roelof Botha',
    officeLocation: 'Menlo Park, Califronia',
    personId: 'p_RtNG9XWeU2Xrsf4321Qbt',
    removed: '',
    role: 'Chief Financial Officer',
    scenarioMetaData: {
      changes: [],
      state: ['Edited']
    },
    sourceEmployeeId: '6',
    subordinates: [],
    tags: [],
    temp: {
      employeeId: '6',
      managerId: '2'
    },
    updatedBy: 'mVQcQ3tTNtampsCrLIid5Udf1EH2'
  },
  {
    avatarImg:
      'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/users%2Favatars%2Fp_TDKoy9vZofFuX4ypANVVawz.jpeg?alt=media&token=dcb5c6d1-ae09-436d-9981-96ed419bf3af',

    createdAt: {
      _nanoseconds: 365000000,
      _seconds: 1675795045
    },
    createdBy: '',
    customFields: [],
    email: '',
    employeeData: {
      department: 'Systems',
      holidayDates: [],
      startDate: '',
      terminationDate: ''
    },
    employeeStatus: '',
    managers: ['p_BIwwiEJmo9ye2A45htDKln'],
    name: 'James Templeton',
    officeLocation: 'San Francisco Bay Area',
    personId: 'p_TDKoy9vZofFuX4ypANVVawz',
    removed: '',
    role: 'Senior Vice President of Systems',
    scenarioMetaData: {
      changes: [],
      state: ['Edited']
    },
    sourceEmployeeId: '8',
    subordinates: [],
    tags: [],
    temp: {
      employeeId: '8',
      managerId: '2'
    },
    updatedBy: 'mVQcQ3tTNtampsCrLIid5Udf1EH2'
  },
  {
    avatarImg:
      'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/users%2Favatars%2Fp_WWWgyiONMAxrAhwXqZRO5.jpeg?alt=media&token=27712091-336b-419e-920f-72a232a626a9',

    createdAt: {
      _nanoseconds: 365000000,
      _seconds: 1675795045
    },
    createdBy: '',
    customFields: [],
    email: '',
    employeeData: {
      department: 'Operations',
      holidayDates: [],
      startDate: '',
      terminationDate: ''
    },
    employeeStatus: '',
    managers: ['p_BIwwiEJmo9ye2A45htDKln'],
    name: 'Ryan Downs',
    officeLocation: 'Springfield, Nebraska',
    personId: 'p_WWWgyiONMAxrAhwXqZRO5',
    removed: '',
    role: 'Senior Vice President of Operations',
    scenarioMetaData: {
      changes: [],
      state: ['Edited']
    },
    sourceEmployeeId: '9',
    subordinates: [],
    tags: [],
    temp: {
      employeeId: '9',
      managerId: '2'
    },
    updatedBy: 'mVQcQ3tTNtampsCrLIid5Udf1EH2'
  },
  {
    avatarImg:
      'https://storage.googleapis.com/orgraph-d57a6.appspot.com/users/avatars/p_ZkssPYbjZ5vlRS51Zr5Rp.jpeg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=firebase-adminsdk-chebn%40orgraph-d57a6.iam.gserviceaccount.com%2F20230209%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20230209T164426Z&X-Goog-Expires=901&X-Goog-SignedHeaders=host&X-Goog-Signature=2f378902c7be6a06f2a3b87da22651c3c8ecf2972085b782761927a6b5dd454aa2182716ee2f20ad2474e1d456980ab429f85a5b1dcab697be9e40cacee1fffdca9a181e0923c3bf72846a6c3cba02c35d7ee45a28de93690aa5a9dbe239f14151c42feb4999e5171b858cc48d1dd66d8490b459d5a618897d34ecac1a63c6d118626d5ffb00b2d730f773c2e6926e7f39cf61cbf93abf772ea912d8aad8d2b8695c7ab6129be494b24876e981dbf5c857cbffe68a17d17f1f5d488931833c9b7260f01abf0c7af42cf9d811953dda6f48f16fb0bf969ef68aefb4816598c7ced02df0a64161905838dd2cfddd7613bc5f3272e5fd0d984345ec29151b228896',

    createdAt: {
      _nanoseconds: 365000000,
      _seconds: 1675795045
    },
    createdBy: '',
    customFields: [],
    email: 'integration@agentnoon.com',
    employeeData: {
      department: 'Finance',
      holidayDates: [],
      startDate: '',
      terminationDate: ''
    },
    employeeStatus: '',
    managers: ['p_BIwwiEJmo9ye2A45htDKln'],
    name: 'Todd Pearson',
    officeLocation: 'Denver, Colorado',
    personId: 'p_ZkssPYbjZ5vlRS51Zr5Rp',
    removed: '',
    role: 'Senior Vice President of Financial Services',
    scenarioMetaData: {
      changes: [],
      state: ['Edited']
    },
    sourceEmployeeId: '10',
    subordinates: [],
    tags: [],
    temp: {
      employeeId: '10',
      managerId: '2'
    },
    updatedBy: 'MapVVRBAdfWwyOPPPTem8GMyceo1'
  },
  {
    avatarImg:
      'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/users%2Favatars%2Fp_ZxPAFw6wILLW2X0stOqVDK.jpeg?alt=media&token=f382b3b7-4977-4071-9642-ad814260e7a6',

    createdAt: {
      _nanoseconds: 365000000,
      _seconds: 1675795045
    },
    createdBy: '',
    customFields: [],
    email: '',
    employeeData: {
      department: 'Technology',
      holidayDates: [],
      startDate: '',
      terminationDate: ''
    },
    employeeStatus: '',
    managers: ['p_BIwwiEJmo9ye2A45htDKln'],
    name: 'Max Levchin',
    officeLocation: 'San Francisco, California',
    personId: 'p_ZxPAFw6wILLW2X0stOqVDK',
    removed: '',
    role: 'Chief Technology Officer',
    scenarioMetaData: {
      changes: [],
      state: ['Edited']
    },
    sourceEmployeeId: '3',
    subordinates: [],
    tags: [],
    temp: {
      employeeId: '3',
      managerId: '2'
    },
    updatedBy: 'mVQcQ3tTNtampsCrLIid5Udf1EH2'
  }
]
