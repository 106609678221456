import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import * as Sentry from '@sentry/browser'
import 'firebase/compat/functions'
import { sortBy } from 'lodash-es'

const getDefaultState = () => {
  return {}
}

const state = getDefaultState()

const mutations = {}

const actions = {
  async fetchRequisitionHistory(context, { requestId }) {
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('requisition_request_history')
        .where('requestId', '==', requestId)
        .get()

      const changes = []
      querySnapshot.forEach((doc) => {
        let change = doc.data()
        changes.push(change)
      })

      return sortBy(changes, (c) => {
        if (c.updatedAt) return c.updatedAt.seconds
        return null
      })
    } catch (e) {
      console.error(e)
      if (Sentry) Sentry.captureException(e)
      throw 'Something weng wrong saving'
    }
  }
}

export default {
  state,
  mutations,
  actions,
  modules: {}
}
