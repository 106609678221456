import idCreator from '@/lib/idCreator.js'
import store from '@/store/index.js'
import capitalize from 'lodash-es/capitalize.js'
import demoPeople from '@/lib/demo-people.js'
import { copyGoogleSheet } from '@/services/firebase-functions.service.js'

const sampleSheet = '1hURUfRte9OBlWWKtzVomFoJ7CLn--Q1j50ierv1fPJg'

const getCompanyName = (email) => capitalize(email.split('@')[1].split('.')[0] || '')

const createEmptyGoogleSheet = async ({ boardId, email }) => {
  try {
    const docId = await copyGoogleSheet({
      sourceId: sampleSheet,
      userEmail: email,
      title: `[Agentnoon] ${getCompanyName(email)} Employee Data`
    })

    await store.dispatch('applyMappingToSheet', {
      filePath: `https://docs.google.com/spreadsheets/d/${docId.data}/`,
      boardId
    })
  } catch (e) {
    console.log('error in creating empty google sheet', e)
  }
}

export const importInitialData = async ({ email, uid }) => {
  window.mixpanel.track('create_sample_org')
  const boardId = idCreator.createBoardId()

  try {
    await store.dispatch('createNewBoard', {
      accessArray: [{ accessLevel: 'admin', uid }],
      ownersArray: [uid],
      companyName: 'Sandbox Account',
      boardId
    })

    await store.dispatch('savePeopleData', {
      peopleObjs: demoPeople.map((person) => ({ ...person, boardId }))
    })

    store.dispatch('setBaseCurrency', {
      baseCurrency: 'USD',
      boardId
    })

    createEmptyGoogleSheet({ boardId, email }) // don't await, it can happen in the background

    return boardId
  } catch (e) {
    console.log('error in creating sample org', e)
  }
}
