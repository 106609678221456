import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import 'firebase/compat/analytics'
import firebase from 'firebase/compat/app'
import 'firebase/compat/app-check'
import { createApp, defineCustomElement } from 'vue'
import { dragscrollNext } from 'vue-dragscroll'
import App from './App.vue'
import router from './router'
import store from './store'
// import "firebase/compat/analytics";
import number from '@coders-tm/vue-number-format'
import 'firebase/compat/performance'
import PrimeVue from 'primevue/config'
import Vue3Sanitize from 'vue-3-sanitize'
import Popper from 'vue3-popper'
import OrgNode from './customElements/OrgNode.ce.vue'
import OrgNodeEdit from './customElements/OrgNodeEdit.ce.vue'
import RolePlaningGoalNode from './customElements/RolePlanNode.ce.vue'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import VueApexCharts from 'vue3-apexcharts'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import RolePlaningRoleNode from './customElements/RolePlanRole.ce.vue'
import './index.css'
// when doing some admin or debugging in product mode.
let MODE = 'PRODUCTION'
// if (
//   window.location.hostname.includes('localhost') ||
//   window.location.hostname.includes('.web.app')
// ) {
//   MODE = 'DEV'
// }

window.MODE = MODE

if (window.location.host.includes('app.agentnoon') || MODE === 'PRODUCTION') {
  // Production environment
  const firebaseConfig = {
    apiKey: 'AIzaSyCbJ-27TQG4TMws5BxSf99N4wpnMbXaihc',
    authDomain: 'auth.agentnoon.com',
    databaseURL: 'https://orgraph-d57a6.firebaseio.com',
    projectId: 'orgraph-d57a6',
    storageBucket: 'orgraph-d57a6.appspot.com',
    messagingSenderId: '513813238878',
    appId: '1:513813238878:web:2068fa1d9fdd0e152810cf',
    measurementId: 'G-M959PKHY2G'
  }

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig)

  window.ga4 = firebase.analytics()
  window.logGA4Event = (event, properties) => {
    if (window.ga4) {
      window.ga4.logEvent(event, properties)
    }
  }

  console.log('RUNNING PRODUCTION MODE')
  window.ga4.logEvent('app_init')
} else {
  const firebaseConfig = {
    apiKey: 'AIzaSyDq_MtrRO19v5SjaxPzt2D1E0oGtHDR9VA',
    authDomain: 'staging.auth.agentnoon.com',
    projectId: 'agentnoon-staging',
    storageBucket: 'agentnoon-staging.appspot.com',
    messagingSenderId: '706148019191',
    appId: '1:706148019191:web:3a3a0d023b2b352a089cf2'
  }
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig)
  console.log('RUNNING DEV MODE')
  window.logGA4Event = () => {}
}
firebase.performance()

// Dev environment
if (window.location.hostname.includes('localhost')) {
  // For local serve, the debug token must be set from
  // the Firebase console
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

const appCheck = firebase.appCheck()
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
  '6LffVwAdAAAAAMBD9DEBOEKNphvLXoUsceaYHDMH',
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  true
)

// Remember to disable this when not needed
if (window.location.hostname.includes('localhost')) {
  console.log('====USING EMULATOR====')
  firebase.functions().useEmulator('localhost', 5001)
  // connectFirestoreEmulator()
}

const app = createApp(App)

if (MODE !== 'DEV') {
  // This needs to go right after createApp() call.
  Sentry.init({
    app,
    dsn: 'https://b8bbaeb9f316499aad2b598a051caa32@o970831.ingest.sentry.io/6123697',
    integrations: [
      // new Sentry.Integrations.Breadcrumbs({
      //   console: false
      // }),
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'app.agentnoon.com', /^\//]
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true
  })
}

app.use(router)
app.use(store)
app.use(Vue3Sanitize)
app.use(autoAnimatePlugin)
app.use(VueApexCharts)
app.use(number, { precision: 2 })
app.use(PrimeVue)
app.use(VueVirtualScroller)
app.component('PopperToolip', Popper)
app.directive('dragscroll', dragscrollNext)
app.mount('#app')

// When an error occurs in Vue app, the native error output does not include
// the line number. This allows it.
app.config.errorHandler = (error) => {
  console.error(error)
}

const anOrgNode = defineCustomElement(OrgNode)
const anOrgNodeEdit = defineCustomElement(OrgNodeEdit)
const rolePlanGoalNode = defineCustomElement(RolePlaningGoalNode)
const rolePlanRoleNode = defineCustomElement(RolePlaningRoleNode)

customElements.define('an-org-node', anOrgNode)
customElements.define('an-org-node-edit', anOrgNodeEdit)
customElements.define('an-role-planning-goal-node', rolePlanGoalNode)
customElements.define('an-role-planning-role-node', rolePlanRoleNode)
