import { v4 as uuidv4 } from 'uuid'
import { readonly, ref } from 'vue'

const timeOnScreen = 3000
const messages = ref([])

/**
 * reuseable hook for the handling of toast messages
 * @return an object of helper functions
 */
export default function useToast() {
  /**
   * remove custom message
   * @param messageId message id for the message to be removed
   */
  const removeMessage = (messageId) => {
    messages.value = messages.value.filter((m) => m.id !== messageId)
  }
  /**
   * display custom message in the toast
   * @param message custom message
   * @param options optional parameters for position, background color and time in milliseconds
   */
  const queueMessage = (message, options) => {
    const messageObj = { message, options, id: uuidv4() }
    messages.value.push(messageObj)

    setTimeout(() => {
      removeMessage(messageObj.id)
    }, options?.timeout || timeOnScreen)
  }

  return {
    queueToast: queueMessage,
    messages: readonly(messages),
    removeMessage
  }
}
