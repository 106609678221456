import { nanoid } from 'nanoid'

/**
  Generates an ID for a board document using nanoid
*/
const createBoardId = () => {
  return `bd_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a company using nanoid
*/
const createCompanyId = () => {
  return `company_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a people document using nanoid
*/
const createPersonId = () => {
  return `p_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a requisitions document using nanoid
*/
const createRequisitionRequestId = () => {
  return `hr_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a squad document using nanoid
*/
const createSquadId = () => {
  return `sq_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

const createPlanId = () => {
  return `pl_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a plan_logs document using nanoid
*/
const createPlanLogId = () => {
  return `pl_log_${nanoid().replace(/-/g, 'DK').replace(/_/g, 'AN')}`
}

/**
  Generates an ID for a notification document using nanoid
*/
const createNotificationId = () => {
  return `notif_${nanoid().replace(/-/g, 'DK').replace(/_/, 'AN')}`
}

export {
  createBoardId,
  createCompanyId,
  createPersonId,
  createRequisitionRequestId,
  createSquadId,
  createPlanId,
  createNotificationId,
  createPlanLogId
}

export default {
  createBoardId,
  createCompanyId,
  createPersonId,
  createRequisitionRequestId,
  createSquadId,
  createPlanId,
  createNotificationId,
  createPlanLogId
}
