import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
// import * as Sentry from '@sentry/browser'
import 'firebase/compat/functions'
import _ from 'lodash'

/**
 * Default values & state
 */
const getDefaultState = () => {
  return {
    updatedPeople: []
  }
}

const state = getDefaultState()

const mutations = {
  SET_PEOPLE(_state, people) {
    _state.updatedPeople = _.sortBy(people, (person) => {
      return person.name ? person.name : ''
    })
  },
  RESET_SYNC_MODULE(_state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(_state, getDefaultState())
  }
}

const getters = {
  getUpdatedPeople: (_state) => {
    return _state.updatedPeople
  }
}

const actions = {
  /* Fetch newly added people from the main board. */
  async fetchNewPeopleFromMain(context, data) {
    console.log('fetchNewPeopleFromMain')
    // const destBoardId = data.destBoardId
    const srcBoardId = data.srcBoardId
    const queryFrom = data.from

    const querysanpshot = await firebase
      .firestore()
      .collection('people')
      .where('boardId', '==', srcBoardId)
      .where('createdAt', '>', queryFrom)
      .get()

    const updated = []
    querysanpshot.forEach((doc) => {
      updated.push(doc.data())
    })

    context.commit('SET_PEOPLE', updated)
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  modules: {}
}
