import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import {
  cloneDeep,
  compact,
  countBy,
  each,
  filter,
  find,
  flatten,
  flattenDeep,
  isArray,
  keyBy,
  keys,
  map,
  remove,
  sortBy,
  startCase,
  uniq,
  uniqBy,
  values,
  xor
} from 'lodash-es'
import { DateTime } from 'luxon'
import moment from 'moment/moment.js'
import NodeTypes from './NodeTypes'
import PersonPlanStates from './PersonPlanStates'
import ProfileChanges from './ProfileChanges.js'
import idCreator from './idCreator'

export const sanityCheckPeopleData = (peopleData) => {
  // Check to make sure they are not a manager of themselves or empty string
  each(peopleData, (person) => {
    remove(person?.subordinates || [], (s) => {
      return s === person.personId || s === ''
    })

    remove(person.managers, (m) => {
      return m === person.personId
    })
  })

  // Check if all the subordinate & manager infos are correct.
  let filteredPeople = peopleData.filter((person) => person.removed !== true)

  // No subordinates should have two managers
  let allSubordinates = map(filteredPeople, 'subordinates')
  allSubordinates = flattenDeep(allSubordinates)

  // Only one manager is allowed. Check for duplicates in the subordinates list
  const counts = countBy(allSubordinates)
  // List of the subordinates that need to be fixed
  let toBeFixed = map(counts, (v, k) => {
    if (v > 1) {
      return k
    }
    return null
  })
  toBeFixed = compact(toBeFixed)

  if (toBeFixed.length > 0) {
    peopleData = peopleData.map((person) => {
      if (xor([toBeFixed, person?.subordinates]).length !== 0) {
        // remove the person from the subordinates list and the toBeFixed list
        remove(person?.subordinates || [], person.personId)
        remove(toBeFixed, person.personId)
      }
      return person
    })
  }

  filteredPeople = peopleData.filter((person) => person.removed !== true)
  allSubordinates = map(filteredPeople, 'subordinates')
  allSubordinates = flattenDeep(allSubordinates)

  const hasTwoManagersPersonIdsArray = uniq(
    filter(allSubordinates, (v, i, a) => a.indexOf(v) !== i)
  )
  each(hasTwoManagersPersonIdsArray, (subordinatePersonId) => {
    const managerObj =
      find(peopleData, (person) => {
        return person?.subordinates?.includes(subordinatePersonId)
      }) || {}

    if (!managerObj) return

    if (!managerObj?.subordinates || !isArray(managerObj?.subordinates)) {
      managerObj.subordinates = []
    }

    remove(managerObj?.subordinates || [], (_id) => {
      return _id === subordinatePersonId
    })
  })

  const peopleDict = keyBy(peopleData, 'personId')
  each(peopleData, (person = {}) => {
    const newSubordinateList = []
    each(person?.subordinates || [], (_id) => {
      if (peopleDict[_id]) {
        newSubordinateList.push(_id)
        peopleDict[_id].managers = [person.personId]
      }
    })
    person.subordinates = newSubordinateList

    if (!person.managers) person.managers = []
    // We cannot have more one manager for now.
    if (person.managers.length > 1) {
      person.managers = person.managers[0]
    }
  })

  // Remove from the peopleData of `Removed` users
  const removedPersonIds = peopleData
    .filter((person) => person.employeeStatus === 'Removed')
    .map((person) => person.personId)

  peopleData = peopleData
    .filter((person) => person.employeeStatus !== 'Removed')
    .map((person) => {
      if (!isArray(person?.subordinates)) {
        person.subordinates = []
      }
      if (person?.subordinates?.some((childPersonId) => removedPersonIds.includes(childPersonId))) {
        person.subordinates = person?.subordinates?.filter(
          (childPersonId) => !removedPersonIds.includes(childPersonId)
        )
      }
      return person
    })

  return peopleData
}

/**
 * Some times, the manager field and the subordinate lists are misaligned.
 * This method fixes the misalignment, taking the subordinates list as the ground truth.
 * @param peopleData - an array of objects, each object representing a person.
 */
export const updateManager = (peopleData) => {
  const peopleDict = keyBy(peopleData, 'personId')

  return map(peopleData, (person = {}) => {
    // Ensure the subordinates list do not contain duplicates
    person.subordinates = uniq(person?.subordinates || [])

    each(person.subordinates, (personId) => {
      // For all subordinates of this person,
      // this person has to be the only manager
      if (
        !peopleDict[personId]?.managers ||
        peopleDict[personId]?.managers.length === 0 ||
        peopleDict[personId]?.managers[0] !== person.personId
      )
        peopleDict[personId].managers = [person.personId]
    })
    return person
  })
}

/**
 * Find the nodes without a manager.
 * Move them to a special node for those without a manager
 * TODO: cover with basic unit tests so we can characterize the behavior and control the changes later
 */
export const fixDiscontinuity = (orginalData, peopleIncludingRemoved) => {
  const peopleData = cloneDeep(orginalData)

  const filteredPeople = peopleData.filter((person) => {
    return !isRemoved(person) && person.name
  })

  const peopleDict = keyBy(filteredPeople, 'personId')

  let subordinateList = map(filteredPeople, (person) => {
    return person?.subordinates || []
  })
  subordinateList = flatten(subordinateList)
  subordinateList = uniq(subordinateList)

  const personIdList = map(filteredPeople, 'personId')
  let noManagerList = xor(subordinateList, personIdList)

  let ceoId = noManagerList.find((pID) => isCEO(peopleDict[pID]))

  if (!ceoId) {
    ceoId = noManagerList.find((pID) => hasCEOAttributes(peopleDict[pID]))
  }

  if (!ceoId) {
    Sentry.captureException('Couldnt locate CEO', { tags: { boardId: peopleData[0]?.boardId } })
    return peopleData
  }

  remove(noManagerList, (pID) => pID === ceoId)
  remove(noManagerList, (pID) => peopleDict[pID]?.isRoot)

  // Remove personIds that do not exist
  const _noManagerList = []
  each(noManagerList, (personId) => {
    if (peopleDict[personId]) {
      _noManagerList.push(personId)
    }
  })
  noManagerList = _noManagerList

  // Add the removed person into no manager node
  const removedPeopleIds = peopleIncludingRemoved
    .filter((person) => person?.scenarioMetaData?.state?.includes(PersonPlanStates.Removed))
    .map((person) => person.personId)

  if (noManagerList.length === 0 && removedPeopleIds.length === 0) return peopleData

  // Find the special NO MANAGER node.
  // If it does not exist, create a new one
  let noManagerNode = find(peopleData, (person) => {
    return person.isNoManagerNode
  })

  const boardId = peopleData[0].boardId

  if (noManagerNode) {
    if (!noManagerNode?.subordinates) {
      noManagerNode.subordinates = []
    }

    noManagerNode.subordinates.push(...noManagerList)

    peopleData.push(noManagerNode)
  } else {
    // Create a new no manager node
    noManagerNode = {
      boardId,
      personId: idCreator.createPersonId(),
      avatarImg: '',
      subordinates: [...noManagerList],
      managers: [ceoId],
      employeeStatus: '',
      isNoManagerNode: true,
      name: '',
      role: 'People without a manager',
      employeeData: {
        department: '',
        salary: 0
      },
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }

    if (noManagerNode?.subordinates?.length > 0) {
      peopleDict[ceoId]?.subordinates?.push(noManagerNode.personId)
      noManagerNode?.subordinates.forEach((personId) => {
        try {
          peopleDict[personId]?.managers?.push(noManagerNode.personId)
        } catch (e) {
          console.log(e)
        }
      })

      peopleData.push(noManagerNode)
    }
  }

  return peopleData
}

/**
 * Get new person object with default fields
 */
export const getNewPerson = ({
  boardId,
  newPersonId,
  name,
  role,
  managerObj,
  status,
  createdBy,
  updatedBy,
  officeLocation,
  subordinates,
  department = '',
  scenarioMetaData = {},
  type = null,
  rolePlanning = null,
  sourceEmployeeId = null,
  startDate = null,
  terminationDate = null,
  email = null
}) => ({
  boardId,
  personId: newPersonId,
  name,
  role: role || '',
  email: email || '',
  avatarImg:
    'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/open_role.png?alt=media&token=5a831181-2239-482a-93e2-0e795a4af0e5',
  officeLocation: officeLocation || '',
  subordinates: subordinates || [],
  managers: managerObj?.personId ? [managerObj.personId] : [],
  employeeStatus: status || 'Planned',
  employeeData: {
    holidayDates: [],
    department: department || managerObj?.employeeData?.department || '',
    salary: '',
    startDate: startDate || '',
    terminationDate: terminationDate || ''
  },
  createdBy,
  updatedBy,
  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  scenarioMetaData: {
    ...scenarioMetaData,
    state: [
      ...(scenarioMetaData.state ? [...scenarioMetaData.state] : []),
      PersonPlanStates.NewlyAdded
    ]
  },
  type,
  rolePlanning,
  sourceEmployeeId
})

export const getNewPersonForMainBoard = ({
  boardId,
  newPersonId,
  name,
  role,
  managerObj,
  status,
  createdBy,
  updatedBy,
  officeLocation,
  subordinates,
  department = '',
  type = null,
  rolePlanning = null,
  sourceEmployeeId = null,
  startDate = null,
  terminationDate = null,
  email = null
}) => ({
  boardId,
  personId: newPersonId,
  name,
  role: role || '',
  email: email || '',
  avatarImg:
    'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/open_role.png?alt=media&token=5a831181-2239-482a-93e2-0e795a4af0e5',
  officeLocation: officeLocation || '',
  subordinates: subordinates || [],
  managers: managerObj?.personId ? [managerObj.personId] : [],
  employeeStatus: status || 'Planned',
  employeeData: {
    holidayDates: [],
    department: department || managerObj?.employeeData?.department || '',
    salary: '',
    startDate: startDate || '',
    terminationDate: terminationDate || ''
  },
  createdBy,
  updatedBy,
  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  type,
  rolePlanning,
  sourceEmployeeId
})

/**
 * Get new compensation object with default fields
 */
export const getNewCompensation = ({ personId, boardId, defaultCurrency }) => ({
  personId,
  boardId,
  payPer: '',
  payRate: 100000,
  payRateEffectiveDate: '',
  payType: '',
  currency: defaultCurrency
})

/**
 * Duplicates given compensation object and swaps the personId
 */
export const duplicateCompensation = ({ personId, compensationToDuplicate, defaultCurrency }) => ({
  personId,
  boardId: compensationToDuplicate.boardId,
  currency: compensationToDuplicate.currency || defaultCurrency,
  payPer: compensationToDuplicate.payPer,
  payRate: compensationToDuplicate.payRate,
  payRateEffectiveDate: compensationToDuplicate.payRateEffectiveDate,
  payType: compensationToDuplicate.payType
})

/**
 * Make sure that subordinates list exists on person object
 */
const ensureSubordinatesList = (personObj) => {
  if (!personObj.subordinates || !Array.isArray(personObj.subordinates)) {
    personObj.subordinates = []
  }
}

/**
 * Adds suboridinate to manager object
 */
export const addSubordinate = ({ managerObj, subordinateId }) => {
  if (managerObj && !managerObj.isNoManagerNode) {
    ensureSubordinatesList(managerObj)

    managerObj.subordinates.push(subordinateId)
    managerObj.subordinates = uniq(managerObj.subordinates)
  }
}

/**
 * Get new role object
 */
export const getNewRole = ({ newPersonId, managerObj, boardId, creatorUid }) => {
  return {
    newPersonId,
    managerObj,
    boardId,
    officeLocation: managerObj?.officeLocation || null,
    status: 'Planned',
    name: 'Planned role',
    createdBy: creatorUid,
    updatedBy: creatorUid
  }
}

/**
 * Check if person has status 'Moved' in current scenario
 * @param person
 * @returns {Boolean}
 */
export const isMoved = (person) => person.scenarioMetaData?.state?.includes(PersonPlanStates.Moved)

export const isRemovedManager = (person) =>
  person?.scenarioMetaData?.state?.includes(PersonPlanStates.RemovedManager) || false

export const isRif = (person) =>
  person?.scenarioMetaData?.state?.includes(PersonPlanStates.RIF) || false

export const isNewlyAdded = (person) =>
  person?.scenarioMetaData?.state?.includes(PersonPlanStates.NewlyAdded) || false

export const isRemoved = (person) =>
  person?.removed ||
  person?.employeeStatus === 'Removed' ||
  person?.scenarioMetaData?.state?.includes(PersonPlanStates.Removed)

export const isRolePlanningNode = (person) => person?.rolePlanning

export const isRolePlanningGoal = (person) => person?.type === NodeTypes.RolePlanningGoal
/**
 * Check if person has status 'Edited' in current scenario
 * @param person
 * @returns {Boolean}
 */
export const isEdited = (person) =>
  person?.scenarioMetaData?.state?.includes(PersonPlanStates.Edited)

/**
 * Check if person is on notice peroid / leaver state in current scenario
 * @param person
 * @returns {Boolean}
 */
export const isLeaving = (person) => {
  return (
    (person?.employeeData?.terminationDate &&
      DateTime.fromFormat(person?.employeeData?.terminationDate, 'yyyy-MM-dd').diffNow('days')
        .days > 0) ||
    false
  )
}

/**
 * Checks if person is terminated
 * @param person
 * @returns {Boolean}
 */
export const isTerminated = (person) => {
  return (
    (person?.employeeData?.terminationDate &&
      DateTime.fromFormat(person?.employeeData?.terminationDate, 'yyyy-MM-dd').diffNow('days')
        .days <= 0) ||
    false
  )
}

export const isBackfill = (person) => {
  return person?.scenarioMetaData?.state?.includes(PersonPlanStates.Backfill)
}

export const isBackfilled = (person) => {
  return person?.scenarioMetaData?.state?.includes(PersonPlanStates.Backfilled)
}

/**
 * Get record of last role change
 * @param person
 * @returns {*}
 */
export const getRoleChangedRecord = (person) =>
  person?.scenarioMetaData?.changes?.findLast(
    (change) => change.action === ProfileChanges.RoleChange
  )

/**
 * Check if person has role changed in current scenario
 * @param person
 * @returns {Boolean}
 */
export const isRoleChanged = (person) => getRoleChangedRecord(person) != null

/**
 * Get empty object describing role change
 * @param fromRole
 * @param toRole
 * @returns {{action: string, from, to}}
 */
export const newRoleChangeRecord = (fromRole, toRole) => ({
  action: ProfileChanges.RoleChange,
  from: fromRole,
  to: toRole
})

// A helper function that returns an array of strings.
// The strings are list of custom fields key and value pairs
export const getCustomFieldsAsStrings = (fields) => {
  if (fields.length > 0) {
    const tags = map(fields, (obj) => {
      return `${startCase(keys(obj))} : ${values(obj)}`
    })
    return tags
  }
  return []
}

// A helper function that returns an array of strings.
// The strings are list of custom fields key and value pairs
export const getCustomFieldsDict = (fields) => {
  if (fields && fields.length > 0) {
    let dict = {}

    fields.forEach((obj) => {
      dict = {
        ...dict,
        ...obj
      }
    })

    return dict
  }

  return {}
}

/**
 * Returns person object with "Moved" state and a new manager
 */
export const applyPersonMoved = ({ person, updatedBy, newManagerId }) => {
  const newPerson = ensureMetaProperties(person)

  newPerson.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
  newPerson.email = person.email ? person.email.trim() : ''
  newPerson.updatedBy = updatedBy

  if (newManagerId) {
    newPerson.managers = [newManagerId]
  }
  // Some residues from rendering
  delete newPerson.isDirty

  if (!isNewlyAdded(newPerson) && !isMoved(newPerson)) {
    newPerson.scenarioMetaData?.state.push(PersonPlanStates.Moved)
  }

  return newPerson
}

export const markAsRemoved = ({ doc, updatedBy }) => {
  const newDoc = { ...doc }

  newDoc.removed = true
  newDoc.employeeStatus = 'Removed'

  // Set the status value to display the avatar change status in the scenario editor view
  if (!newDoc?.scenarioMetaData) {
    newDoc.scenarioMetaData = {}
  }
  if (!newDoc?.scenarioMetaData?.state) {
    newDoc.scenarioMetaData.state = []
  }

  newDoc?.scenarioMetaData?.state?.push(PersonPlanStates.Removed)

  newDoc.employeeData.terminationDate = moment().format('YYYY-MM-DD')
  newDoc.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
  if (updatedBy) {
    newDoc.updatedBy = updatedBy
  }

  return newDoc
}

export const isCEO = (person) => {
  return (
    person?.isRoot ||
    person?.role?.toLowerCase() === 'ceo' ||
    person?.role?.toLowerCase().includes('chief executive officer')
  )
}

export const hasCEOAttributes = (person) => {
  return person?.managers?.length === 0 && person?.subordinates?.length > 0
}

export const applyEditedState = ({ oldPersonObject, updatedPersonObject }) => {
  const personObj = { ...updatedPersonObject }

  const isClearingStatus =
    (oldPersonObject?.scenarioMetaData?.state?.length === 1 &&
      personObj?.scenarioMetaData?.state?.length === 0) ||
    false

  const addedRif =
    updatedPersonObject?.scenarioMetaData?.state?.includes(PersonPlanStates.RIF) &&
    !oldPersonObject?.scenarioMetaData?.state?.includes(PersonPlanStates.RIF)

  const removedRif =
    !updatedPersonObject?.scenarioMetaData?.state?.includes(PersonPlanStates.RIF) &&
    oldPersonObject?.scenarioMetaData?.state?.includes(PersonPlanStates.RIF)

  if (
    !personObj?.scenarioMetaData?.state?.includes(PersonPlanStates.NewlyAdded) &&
    !personObj?.scenarioMetaData?.state?.includes(PersonPlanStates.Edited) &&
    !addedRif && //for now don't treat "mark as RIF" as an edit
    !removedRif &&
    !personObj?.scenarioMetaData?.state?.includes(PersonPlanStates.RemovedManager) &&
    !personObj?.scenarioMetaData?.state?.includes(PersonPlanStates.Backfilled) &&
    !isClearingStatus
  ) {
    personObj.scenarioMetaData?.state.push(PersonPlanStates.Edited)
  }

  return personObj
}

export const ensureMetaProperties = (personObject) => {
  const person = { ...personObject }

  if (!person?.scenarioMetaData) {
    person.scenarioMetaData = {}
  }
  if (!person?.scenarioMetaData?.state) {
    person.scenarioMetaData.state = []
  }
  if (!person?.scenarioMetaData?.changes) {
    person.scenarioMetaData.changes = []
  }

  return person
}

export const fixedPeopleDataHelper = ({ peopleData }) => {
  try {
    const sanityCheckedPeopleData = sanityCheckPeopleData(peopleData)
    const managerUpdatedPeopleData = updateManager(sanityCheckedPeopleData)

    let fixedPeopleData = fixDiscontinuity(managerUpdatedPeopleData, peopleData)
    each(fixedPeopleData, (person = {}) => {
      if (!person.avatarImg || person.avatarImg === '') {
        person.avatarImg = `https://ui-avatars.com/api/?name=${person.name}&background=e5e7eb&color=374151`
      }
      person.subordinates = uniq(person?.subordinates || [])
    })

    // Ensure there are no duplicates in the subordinates list
    fixedPeopleData = uniqBy(fixedPeopleData, (e) => {
      return e.personId
    })

    fixedPeopleData = sortBy(fixedPeopleData, 'name')

    return fixedPeopleData
  } catch (err) {
    console.error(err)
    Sentry?.captureException(err)
    return []
  }
}

export const peopleForSharedPlan = ({ peopleData }) => {
  try {
    const sanityCheckedPeopleData = sanityCheckPeopleData(peopleData)
    const managerUpdatedPeopleData = updateManager(sanityCheckedPeopleData)

    let fixedPeopleData = fixDiscontinuity(managerUpdatedPeopleData, peopleData)

    each(fixedPeopleData, (person = {}) => {
      if (!person.avatarImg || person.avatarImg === '') {
        person.avatarImg = `https://ui-avatars.com/api/?name=${person.name}&background=e5e7eb&color=374151`
      }
      person.subordinates = uniq(person?.subordinates || [])
    })

    // Ensure there are no duplicates in the subordinates list
    fixedPeopleData = uniqBy(fixedPeopleData, (e) => {
      return e.personId
    })

    fixedPeopleData = sortBy(fixedPeopleData, 'name')

    return fixedPeopleData
  } catch (err) {
    console.error(err)
    Sentry?.captureException(err)
    return []
  }
}

export const fetchPeopleHelper = async ({ boardId, restriction = true }) => {
  // Reset the state
  const peopleData = []

  try {
    const peopleFetcher = firebase.functions().httpsCallable('people-fetch')
    const ret = await peopleFetcher({ boardId, restriction })

    const people = ret.data

    people.forEach((personData) => {
      // let personData = doc.data()
      delete personData.isDirty
      // Don't add the no manager node. It will be added in the later stage
      if (!personData.isNoManagerNode) peopleData.push(personData)
      // resolveAvatarImage(personData)
      if (
        // need to check dataSource and dataSourcce. As there was a mistake in the Bamboo import module
        personData.employeeData?.dataSource === 'bamboohr' ||
        personData.employeeData?.dataSourcce === 'bamboohr'
      ) {
        // Termination data did not copy over properly. Do it here
        if (
          !personData.employeeData?.terminationDate &&
          personData.importedData?.terminationDate !== '0000-00-00'
        ) {
          personData.employeeData.terminationDate = personData?.importedData?.terminationDate
        }
      }
    })

    return cloneDeep(peopleData)
  } catch (e) {
    console.error(e)
    Sentry?.captureException(e)
    return []
  }
}

/**
 * Validate data before updating people data to Vuex.
 * If there is any weird data, it warns through console.warn and changes the data to avoid the console error in the utils.
 *
 * @param {*} peopleData
 * @returns peopleData
 */
export const validatePeopleData = (peopleData) => {
  const personIdList = peopleData.map((person) => person.personId)

  return peopleData.map((person) => {
    const personObj = { ...person }

    // Check valid subordinates data
    if (
      !personObj.isNoManagerNode &&
      personObj?.subordinates?.length &&
      personObj?.subordinates?.some((personId) => !personIdList.includes(personId))
    ) {
      personObj.subordinates = personObj?.subordinates?.filter((personId) =>
        personIdList.includes(personId)
      )
    }

    /* Add the another condition here */

    return personObj
  })
}
