<template>
  <div class="flex w-full flex-col">
    <div class="flex items-start justify-center gap-2 px-2">
      <PopperToolip
        content="Contact support"
        placement="top"
        hover
        open-delay="300"
        style="border-width: 0; margin: 0"
      >
        <a
          href="mailto:support@agentnoon.com"
          class="group flex h-10 w-10 items-center justify-center rounded-lg transition-colors hover:bg-bluedark-50"
        >
          <MailIcon class="h-6 w-6 origin-center" />
        </a>
      </PopperToolip>

      <PopperToolip
        content="Help center"
        placement="top"
        hover
        open-delay="300"
        style="border-width: 0; margin: 0"
      >
        <a
          href="https://help.agentnoon.com/"
          target="blank"
          class="group flex h-10 w-10 items-center justify-center rounded-lg transition-colors hover:bg-bluedark-50"
        >
          <HelpCircle class="h-6 w-6 origin-center" />
        </a>
      </PopperToolip>

      <PopperToolip
        v-if="
          navigationConfig[NavigationMenuItems.Access]?.show &&
          !navigationConfig[NavigationMenuItems.Access]?.isPremium
        "
        :content="navigationConfig[NavigationMenuItems.Access].name"
        placement="top"
        hover
        open-delay="300"
        style="border-width: 0; margin: 0"
      >
        <router-link
          :to="navigationConfig[NavigationMenuItems.Access].url"
          class="group flex h-10 w-10 items-center justify-center rounded-lg transition-colors hover:bg-bluedark-50"
          :class="{
            'bg-bluedark-50':
              $route.fullPath === navigationConfig[NavigationMenuItems.Access].url &&
              !navigationConfig[NavigationMenuItems.Access]?.isPremium
          }"
        >
          <UserPlus class="h-6 w-6 origin-center" />
        </router-link>
      </PopperToolip>

      <div
        v-if="isDev"
        class="mt-2 rounded-full bg-red-200 px-2 py-1 text-center text-xs font-semibold text-red-900"
      >
        DEV
      </div>
    </div>

    <div v-if="user" class="mt-4 mb-5 flex w-full items-center gap-3">
      <Menu as="div" class="relative flex shrink-0 items-center justify-center">
        <MenuButton
          class="transform rounded-full transition-all hover:scale-110 hover:ring-2 hover:ring-bluedark-200"
        >
          <img
            v-if="userPic"
            :src="userPic"
            class="h-10 w-10 rounded-full"
            :alt="user.displayName"
            @error="
              $event.target.src =
                'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/open_role.png?alt=media&token=5a831181-2239-482a-93e2-0e795a4af0e5'
            "
          />
        </MenuButton>
        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
            class="absolute top-0 left-0 mt-2 w-40 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <MenuItem v-slot="{ active }">
              <button
                :class="[
                  active ? 'bg-gray-100' : '',
                  'block w-full px-2 py-2 text-sm text-gray-700'
                ]"
                @click="logout"
              >
                Sign out
              </button>
            </MenuItem>
          </MenuItems>
        </transition>
      </Menu>
      <div class="flex flex-initial flex-col overflow-hidden truncate">
        <span class="break-all text-sm font-semibold text-gray-900">
          {{ user.displayName }}
        </span>
        <span class="font-sm truncate text-sm text-gray-500">
          {{ user.email }}
        </span>
      </div>
    </div>

    <SOCType2CompliantBanner class="mb-4" />
  </div>
</template>

<script setup>
import HelpCircle from '@/assets/SvgIcons/help-circle.svg'
import MailIcon from '@/assets/SvgIcons/mail-01.svg'
import UserPlus from '@/assets/SvgIcons/user-plus-01.svg'
import SOCType2CompliantBanner from '@/components/SOCType2CompliantBanner.vue'
import useAppMode from '@/hooks/use-app-mode'
import useNavigation from '@/hooks/use-navigation'
import { MenuItems as NavigationMenuItems } from '@/utils/navigationData'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

const { getters, dispatch } = useStore()
const { navigationConfig } = useNavigation()
const { isDev } = useAppMode()

const user = computed(() => getters.user)

//TODO: this fallback could be part of vuex getter
const userPic = computed(
  () =>
    getters.userPic ||
    `https://ui-avatars.com/api/?name=${user.value?.displayName}&background=e5e7eb&color=374151`
)

const logout = async () => {
  await dispatch('logout')
  dispatch('people/reset')

  if (window.Intercom) window.Intercom('shutdown')
  location.href = '/'
}
</script>
