import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import * as Sentry from '@sentry/browser'
import { AccessCheck } from '@/lib/Access'

const state = {
  performances: [],
  terminationReasons: []
}

const mutations = {
  setPerformances(_state, perfs) {
    _state.performances = perfs
  },
  setTerminationReasons(_state, reasons) {
    _state.terminationReasons = reasons
  }
}

const actions = {
  async fetchPerformance(context, { board, user, targetPerson, me, peopleDict }) {
    // If this role is not hired yet, we can't have a performance indicator
    if (!targetPerson.name) return
    if (AccessCheck(board, user, targetPerson, me, peopleDict)) {
      // Check if performanceAccessibleByHROnly
      if (context.rootState.settings.performanceAccessibleByHROnly) {
        // If this is on, then check if the current user has
        // HR lead access
        if (me.personId !== context.rootState.settings.hrLeadPersonId) {
          console.log('access not granted')
          return null
        }
      }

      try {
        const doc = await firebase
          .firestore()
          .collection('performances')
          .doc(`${board.boardId}_${targetPerson.personId}`)
          .get()

        return doc ? doc.data() : null
      } catch (e) {
        // This means the doc does not exist becuase the performance was never set for this employee
        if (e.code === 'permission-denied') return
        Sentry.captureException(e)
        return
      }
    } else {
      // This is an illegal action
      return null
    }
  },

  async fetchTerminationReasons(ctx, { boardId, role }) {
    if (!boardId) return
    if (role !== 'admin' && role !== 'executive') {
      console.log('Insufficient access')
      throw new Error('Insufficient access to analytics page')
    }

    try {
      const fetchPerfs = firebase.functions().httpsCallable('performances-fetchTerminationReasons')

      const perfs = await fetchPerfs({ boardId })
      ctx.commit('setTerminationReasons', perfs.data)

      return perfs.data
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      return
    }
  },

  async storePerformance(context, { performance, board, user, targetPerson, me, peopleDict }) {
    if (AccessCheck(board, user, targetPerson, me, peopleDict)) {
      // Check if performanceAccessibleByHROnly
      if (context.rootState.settings.performanceAccessibleByHROnly) {
        // If this is on, then check if the current user has
        // HR lead access
        if (me.personId !== context.rootState.settings.hrLeadPersonId) {
          console.log('access not granted')
          return null
        }
      }

      try {
        await firebase
          .firestore()
          .collection('performances')
          .doc(`${board.boardId}_${targetPerson.personId}`)
          .set({
            personId: targetPerson.personId,
            boardId: board.boardId,
            performance: performance,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
          })
      } catch (e) {
        console.error(e)
        Sentry.captureException(e)
        return 'error'
      }
    } else {
      // This is an illegal action
    }
  }
}

export default {
  state,
  mutations,
  actions,
  modules: {}
}
