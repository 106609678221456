import { computed } from 'vue'

/**
 * Hook that encapsulates runtime environment related data
 */
export default function useAppMode() {
  const isDev = computed(() => {
    return window.MODE === 'DEV'
  })

  return {
    isDev
  }
}
