import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { createPlanLogId } from '@/lib/idCreator'
import { chunk, groupBy, mapValues, orderBy } from 'lodash-es'

// sort logs in descending order by created time
const sortLogs = (arr) => {
  return orderBy(arr, 'createdAt', 'desc')
}

/**
 * Function to reset state to default values
 */
const getDefaultState = () => {
  return {
    logs: {} // Array of Object with keys as planBoardId
  }
}

const state = getDefaultState()

const getters = {
  logs: (_state) => (planId) => {
    return _state.logs[planId] || []
  }
}

const mutations = {
  setAllPlanLogs(_state, comments) {
    _state.logs = comments
  },
  setPlanLogs(_state, { planBoardId, comments }) {
    _state.logs[planBoardId] = comments
  }
}

const actions = {
  async addLog(context, { planBoardId, comment, actionTaken, personId, level = '' }) {
    if (!planBoardId) throw new Error('Plan board Id is undefined')
    try {
      const newLogId = createPlanLogId()
      const board = context.rootGetters.getBoard(planBoardId)
      const newLog = {
        logId: newLogId,
        planBoardId,
        boardId: board.originalBoardId,
        level,
        comment,
        personId,
        actionTaken,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      }

      try {
        await firebase
          .firestore()
          .collection('plan_logs')
          .doc(`${planBoardId}_${newLogId}`)
          .set(newLog)
      } catch (e) {
        window.console.error('Error adding new log to plan logs firebase: ', e)
        if (Sentry) Sentry.captureException(e)
      }

      // update logs data after adding a new log
      await context.dispatch('fetchPlanLogs', { planBoardId, boardId: board.originalBoardId })
    } catch (err) {
      console.log('error occured in adding new log', err)
    }
  },

  async fetchAllPlanLogs(context, boardId) {
    if (!boardId) throw new Error('BoardId is undefined')
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('plan_logs')
        .where('boardId', '==', boardId)
        .get()

      const result = []
      querySnapshot.forEach((doc) => {
        result.push(doc.data())
      })

      if (result) {
        const comments = result.map((log) => ({
          ...log,
          person: context.rootGetters['people/personById']({ personId: log.personId, boardId })
        }))

        const allCommentsByPlanId = mapValues(groupBy(comments, 'planBoardId'), (comment) =>
          sortLogs(comment)
        )

        context.commit('setAllPlanLogs', allCommentsByPlanId)
      }
    } catch (e) {
      window.console.error('Error fetching all plan logs from firebase: ', e)
      if (Sentry) Sentry.captureException(e)
    }
  },

  async fetchPlanLogs(context, { planBoardId, boardId }) {
    if (!planBoardId) throw new Error('Plan board Id is undefined')
    try {
      const querySnapshot = await firebase
        .firestore()
        .collection('plan_logs')
        .where('planBoardId', '==', planBoardId)
        .where('boardId', '==', boardId)
        .get()

      const result = []
      querySnapshot.forEach((doc) => {
        result.push(doc.data())
      })

      if (result) {
        const comments = sortLogs(
          result.map((log) => ({
            ...log,
            person: context.rootGetters['people/personById']({ personId: log.personId, boardId })
          }))
        )

        context.commit('setPlanLogs', { comments, planBoardId })
      }
    } catch (e) {
      window.console.error('Error fetching plan logs from firebase: ', e)
      if (Sentry) Sentry.captureException(e)
    }
  },

  async deletePlanLogs(context, { planBoardId, boardId }) {
    if (!planBoardId) throw new Error('BoardId is undefined')
    try {
      const snapshot = await firebase
        .firestore()
        .collection('plan_logs')
        .where('planBoardId', '==', planBoardId)
        .where('boardId', '==', boardId)
        .get()
      // Batch request is capped at 500 transactions
      const batches = chunk(snapshot.docs, 500)
      const commitBatchPromises = []

      batches.forEach((batch) => {
        const writeBatch = firebase.firestore().batch()
        batch.forEach((doc) => writeBatch.delete(doc.ref))
        commitBatchPromises.push(writeBatch.commit())
      })

      await Promise.all(commitBatchPromises)
    } catch (e) {
      window.console.error('Error deleting plan logs from firebase: ', e)
      if (Sentry) Sentry.captureException(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {}
}
