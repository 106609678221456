import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import * as Sentry from '@sentry/browser'
import { each, map, isNull } from 'lodash-es'

const state = {
  directory: [],
  afradPersonObjects: []
}

const mutations = {
  setBambooData(state, employeeList) {
    each(employeeList, (employee) => {
      map(employee, (v, k) => {
        if (isNull(v)) {
          // if the value is null, pre-populate it
          employee[k] = ''
        }
      })
    })
    state.directory = employeeList
  },
  pushAfradPersonObjects(state, person) {
    state.afradPersonObjects.push(person)
  }
}

const actions = {
  async importBambooHREmployeeList(context, { apiKey, subdomain }) {
    try {
      var importBambooHREmployeeList = firebase
        .functions()
        .httpsCallable('importBambooHREmployeeList')
      const employeeList = await importBambooHREmployeeList({
        apiKey,
        subdomain
      })
      context.commit('setBambooData', employeeList.data)
    } catch (e) {
      Sentry.captureException(e, { tags: { subdomain } })
      return 'error'
    }
  },

  async syncWithBambooHR(context, { boardId }) {
    try {
      var syncBambooHR = firebase.functions().httpsCallable('syncBambooHR')
      const ret = await syncBambooHR({
        boardId
      })
      return ret
    } catch (e) {
      Sentry.captureException(e, { tags: { boardId } })
      return 'error'
    }
  }
}

export default {
  state,
  mutations,
  actions,
  modules: {}
}
