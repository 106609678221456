const validateData = (peopleData) => {
  const peopleArray = peopleData.map((person) => person.personObj)
  if (peopleArray.length === 0) throw new Error('Sheet cannot be empty')

  peopleArray.forEach((person) => {
    const nullFieldError = hasEmptyFields(person)

    // Returns relevant error message
    const invalidFieldError = hasInvalidFields(person)

    if (nullFieldError) {
      throw new Error('Important fields cannot be empty')
    }

    if (invalidFieldError) {
      throw invalidFieldError
    }
  })
}

const hasEmptyFields = (person) => {
  // Won't check managerId since we support multiple CEO's etc. without managers
  const importantProperties = ['tempPersonId', 'name', 'role']

  return importantProperties.some((prop) => {
    let target = person

    if (typeof prop === 'object') {
      prop.forEach((key) => (target = target[key]))
    } else {
      target = target[prop]
    }

    return target === null || target === ''
  })
}

const hasInvalidFields = (person) => {
  const fields = ['avatarImg']

  fields.forEach((field) => {
    switch (field) {
      case 'avatarImg':
        if (!person[field].startsWith('http') || !person[field].startsWith('https')) {
          throw new Error('Photo URL is invalid')
        }
        break
    }
  })
}

const setManagers = (peopleArray) => {
  const peopleObjs = peopleArray.map((person) => person.personObj)

  peopleObjs.forEach((person) => {
    if (!person.tempManagerId) return

    const manager = peopleObjs.find((target) => target.tempPersonId === person.tempManagerId)

    if (!manager) {
      throw new Error(`Person with the ID of ${person.tempManagerId} doesn't exist`)
    }

    person.managers.push(manager.personId)
    manager.subordinates.push(person.personId)
  })

  peopleObjs.forEach((p) => {
    delete p.tempManagerId
    delete p.tempPersonId
  })

  peopleArray.map((person, idx) => (person.personObj = peopleObjs[idx]))

  return peopleArray
}

export { validateData, setManagers }
