import Options from '@/lib/NewPlanOptions'
import CompanyIcon from '@/assets/SvgIcons/BlueCircleBuildingIcon.svg'
import FreshStartIcon from '@/assets/SvgIcons/BlueCircleDocIcon.svg'
import BusinessIcon from '@/assets/SvgIcons/BlueCircleEditUserIcon.svg'
import TeamIcon from '@/assets/SvgIcons/BlueCircleUserIcon.svg'
import DeptIcon from '@/assets/SvgIcons/BlueCircleBagIcon.svg'
import LocationIcon from '@/assets/SvgIcons/BlueCircleBagIcon.svg'
import DisabledCompanyIcon from '@/assets/SvgIcons/GrayCircleBuildingIcon.svg'
import DisabledFreshStartIcon from '@/assets/SvgIcons/GrayCircleDocIcon.svg'
import DisabledBusinessIcon from '@/assets/SvgIcons/GrayCircleEditUserIcon.svg'
import DisabledTeamIcon from '@/assets/SvgIcons/GrayCircleUserIcon.svg'
import DisabledDeptIcon from '@/assets/SvgIcons/GrayCircleBagIcon.svg'

export default [
  {
    title: 'Company',
    icon: CompanyIcon,
    disabledIcon: DisabledCompanyIcon,
    description: 'Create workforce plans for your whole org in one place',
    type: Options.Company
  },
  {
    title: 'Existing Dept',
    icon: DeptIcon,
    disabledIcon: DisabledDeptIcon,
    description: 'Create a workforce plan for one specific department',
    type: Options.Department
  },
  {
    title: 'Existing Team',
    icon: TeamIcon,
    disabledIcon: DisabledTeamIcon,
    description: 'Create a workforce plan for one specific team',
    type: Options.Team
  },
  {
    title: 'Location',
    icon: LocationIcon,
    disabledIcon: DisabledTeamIcon,
    description: 'Create a workforce plan for one specific location',
    type: Options.Location
  },
  {
    title: 'Fresh Start',
    icon: FreshStartIcon,
    disabledIcon: DisabledFreshStartIcon,
    description: 'Create a new team or department that you want to set up',
    type: Options.NewDepartment
  },
  {
    title: 'Business Goal',
    icon: BusinessIcon,
    disabledIcon: DisabledBusinessIcon,
    description: 'Create role based plans around a specific business goal',
    type: Options.RolePlanning
  }
]
