<template>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="relative z-50" @close="setShow(false)">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center text-center sm:items-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative z-50 flex w-full max-w-5xl transform flex-col overflow-hidden bg-transparent text-left transition-all"
            >
              <div class="calendly-inline-widget h-[660px] min-h-min w-full" data-auto-load="false">
                <div class="calendly-spinner" style="visibility: hidden">
                  <div class="calendly-bounce1"></div>
                  <div class="calendly-bounce2"></div>
                  <div class="calendly-bounce3"></div>
                </div>
                <iframe
                  src="https://meetings.hubspot.com/shayan-amin/agentnoon-intro"
                  width="100%"
                  height="100%"
                  frameborder="0"
                ></iframe>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import useCalendlyModal from '@/hooks/use-calendly-modal.js'

const { show, setShow } = useCalendlyModal()
</script>
