<template>
  <div
    ref="node"
    class="org-node group relative flex h-full max-w-[232px] flex-col rounded-xl bg-white shadow-md ring-1 ring-gray-300 transition-all duration-200 hover:ring-2 hover:ring-bluedark-300"
    :class="{
      'bg-white ring-1 ring-gray-300 hover:bg-bluedark-100 hover:ring-bluedark-300': !bgVariant,
      'bg-moss-25 ring-1 ring-moss-300 hover:bg-moss-100 hover:ring-moss-400': bgVariant === 0,
      'bg-bluedark-25 ring-bluedark-300 hover:bg-bluedark-100 hover:ring-bluedark-400':
        bgVariant === 1,
      'bg-rose-25 ring-rose-300 hover:bg-rose-100 hover:ring-rose-400': bgVariant === 2,
      'bg-orangedark-25 ring-orangedark-300 hover:bg-orangedark-100 hover:ring-orangedark-400':
        bgVariant === 3,
      'bg-greenlight-25 ring-greenlight-300 hover:bg-greenlight-100 hover:ring-greenlight-400':
        bgVariant === 4,
      'bg-blue-25 ring-blue-300 hover:bg-blue-100 hover:ring-blue-400': bgVariant === 5,
      'bg-orange-25 ring-orange-300 hover:bg-orange-100 hover:ring-orange-400': bgVariant === 6,
      'bg-pink-25 ring-pink-300 hover:bg-pink-100 hover:ring-pink-400': bgVariant === 7,
      'bg-bluelight-25 ring-bluelight-300 hover:bg-bluelight-100 hover:ring-bluelight-400':
        bgVariant === 8,
      'bg-indigo-25 ring-indigo-300 hover:bg-indigo-100 hover:ring-indigo-400': bgVariant === 9,
      'bg-fuchsia-25 ring-fuchsia-300 hover:bg-fuchsia-100 hover:ring-fuchsia-400':
        bgVariant === 10,
      'bg-green-25 ring-green-300 hover:bg-green-100 hover:ring-green-400': bgVariant === 11,
      'bg-cyan-25 ring-cyan-300 hover:bg-cyan-100 hover:ring-cyan-400': bgVariant === 12,
      'bg-purple-25 ring-purple-300 hover:bg-purple-100 hover:ring-purple-400': bgVariant === 13,
      'bg-violet-25 ring-violet-300 hover:bg-violet-100 hover:ring-violet-400': bgVariant === 14,
      'bg-yellow-25 ring-yellow-300 hover:bg-yellow-100 hover:ring-yellow-400': bgVariant === 15,
      'bg-teal-25 ring-teal-300 hover:bg-teal-100 hover:ring-teal-400': bgVariant === 16,
      'new-hire bg-green-25 ring-green-300 hover:bg-green-100 hover:ring-green-400':
        mode === modes.hiresLeavers || mode === modes.changes,

      pulseAnimation: pulseAnimation,
      highlightAnimation: highlightAnimation,

      'ring-3 hover:ring-3 shadow-xl': dragging === 'true',
      'shadow-3xl border border-dashed border-gray-600': draggingOver === 'true'
    }"
    :style="{
      opacity: opacity
    }"
  >
    <div v-if="isFieldEnabled(Fields.name)" class="absolute w-full -translate-y-1/2">
      <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-200">
        <PersonIcon class="h-7 w-7" />
      </div>
    </div>

    <template v-if="mode === modes.hiresLeavers || mode === modes.changes">
      <div
        class="font-xs absolute -top-3 right-4 rounded-full bg-green-700 px-2 py-1 font-medium text-white"
      >
        New hire
      </div>
    </template>

    <div class="w-full overflow-hidden">
      <div
        ref="heightSource"
        class="relative z-10 flex w-full cursor-grab gap-4 px-4 pb-4"
        :class="{
          'pt-4': !isFieldEnabled(Fields.name),
          'pt-8': isFieldEnabled(Fields.name)
        }"
      >
        <div class="w-full">
          <div
            class="flex flex-col items-center justify-center transition-colors duration-200"
            :class="cardBg"
          >
            <div class="min-h-fit cursor-pointer text-base font-normal text-gray-900">
              <input
                ref="roleInput"
                class="truncate rounded-lg border-0 bg-transparent py-1 text-center placeholder:text-gray-500 focus:outline-none"
                :value="role"
                type="text"
                placeholder="Job title"
                :disabled="disableEdit === 'true'"
                @change="handleRoleChange"
                @click.stop
                @mousedown.stop
              />
            </div>

            <div
              v-if="isFieldEnabled(Fields.salaryNewRole)"
              class="-mt-2 flex min-h-fit w-full justify-center font-light"
            >
              <NumberDisplay
                :model-value="salary"
                v-bind="numberConfig"
                placeholder="Salary"
                :disabled="disableEdit === 'true'"
                class="focus:border-1 w-[100px] overflow-visible border-0 bg-transparent py-1 pr-1 text-right text-sm font-light placeholder:text-gray-500 focus:outline-none focus:ring-0"
                @update:model-value="handleSalaryChange"
                @mousedown.stop
              />

              <select
                :value="currency"
                :disabled="disableEdit === 'true'"
                class="-ml-2 rounded-lg border-0 bg-transparent py-1 font-inter text-xs transition-all focus:border-0 focus:ring-0"
                @change="handleCurrencyChange"
                @click.stop
              >
                <option v-for="symbol in CurrencySymbols" :key="symbol.id">
                  {{ symbol.code }}
                </option>
              </select>
            </div>

            <input
              v-if="isFieldEnabled(Fields.location)"
              id="officeLocationInput"
              :value="officeLocation"
              :disabled="disableEdit === 'true'"
              list="officeLocations"
              class="mt-2 ml-2 w-fit rounded-full border-0 py-1 pl-2 font-inter text-xs transition-all placeholder:text-white"
              :class="pillStyle"
              placeholder="Enter Location"
              @change="handleLocationChange"
              @click.stop
            />

            <datalist id="officeLocations">
              <option disabled value="Enter Location">Location</option>
              <option
                v-for="location in locationMenuList"
                :key="location.id"
                :value="location.name"
              >
                {{ location.name }}
              </option>
            </datalist>

            <input
              v-if="isFieldEnabled(Fields.plannedHireDate)"
              id="plannedHireDateInputNewNode"
              :value="startDate"
              :disabled="disableEdit === 'true'"
              class="mt-2 ml-2 w-fit rounded-full border-0 py-1 pl-2 font-inter text-xs transition-all placeholder:text-white"
              :class="pillStyle"
              placeholder="Hire Date (YYYY-MM)"
              @change="handleHireDateChange"
              @keydown.enter="handleHireDateChange"
              @click.stop
              @keypress="blockInvalidDateInputInString"
            />

            <div
              v-if="personStateInPlan.length > 0"
              class="mt-2 flex w-full flex-wrap items-center justify-center gap-2 space-x-2"
            >
              <span
                v-for="status in personStateInPlan"
                :key="status"
                class="inline-flex items-center rounded-full border px-2.5 py-0.5 font-inter text-xs font-medium"
                :class="{
                  'border-purple-300 bg-purple-100 text-purple-700':
                    status === PersonPlanStates.NewlyAdded,
                  'border-error-500 bg-error-50 text-error-700':
                    status === PersonPlanStates.Removed,
                  'border-primary-300 bg-primary-100 text-primary-700':
                    status === PersonPlanStates.Edited,
                  'border-success-500 bg-success-50 text-success-700':
                    status === PersonPlanStates.Moved,
                  'bg-blue-50 text-blue-700': status === PersonPlanStates.Backfill
                }"
              >
                <SmallUserPlusIcon
                  v-if="status === PersonPlanStates.NewlyAdded"
                  class="mr-1 h-3 w-3 stroke-purple-700"
                />
                <SmallUserPlusIcon
                  v-if="status === PersonPlanStates.Removed"
                  class="mr-1 h-3 w-3 stroke-error-700"
                />
                <PencilUnderlineIcon
                  v-if="status === PersonPlanStates.Edited"
                  class="mr-1 h-3 w-3 stroke-primary-700"
                />
                <MoveIcon
                  v-if="status === PersonPlanStates.Moved"
                  class="mr-1 h-3 w-3 stroke-success-700"
                />
                {{ status }}
                {{ status === PersonPlanStates.Backfill ? `for: ${backfillForWho}` : '' }}
              </span>
            </div>

            <template v-for="field in selectedFields" :key="field">
              <transition
                enter-active-class="transform transition-all"
                enter-from-class="opacity-0 scale-50"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="transition-all transform"
                leave-from-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-50"
              >
                <div
                  v-if="customFields[field]"
                  class="ml-1 mt-1 flex flex-row items-center truncate rounded-full px-2.5 py-1 text-xs font-normal text-indigo-700 mix-blend-multiply transition-colors duration-300"
                  :class="pillStyle"
                >
                  {{ customFields[field] }}
                </div>
              </transition>
            </template>

            <template v-for="{ formula, name } in formulas" :key="name">
              <transition
                enter-active-class="transform transition-all"
                enter-from-class="opacity-0 scale-50"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="transition-all transform"
                leave-from-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-50"
              >
                <Popper :content="name" placement="top" hover>
                  <div
                    v-if="isFieldEnabled(`formula_${name}`)"
                    class="ml-1 mt-1 flex flex-row items-center truncate rounded-full px-2.5 py-1 text-xs font-normal text-indigo-700 mix-blend-multiply transition-colors duration-300"
                    :class="pillStyle"
                  >
                    {{ formulaResult(formula).value || 'No data' }}
                  </div>
                </Popper>
              </transition>
            </template>

            <button
              v-if="spanOfControl > 0"
              class="mt-2 flex h-5 items-center justify-center truncate rounded-2xl bg-gray-800 px-2 text-xs text-white transition-colors hover:bg-gray-600"
              @click.stop="emit('toggle-collapse')"
              @mousedown.stop
            >
              {{ activeSubordinatesCount }} / {{ spanOfControl }}
              <ChevronDownIcon
                class="ml-1 w-4 transition-all"
                :class="{
                  'rotate-180': isExpanded === 'true'
                }"
              />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- control buttons -->
    <div
      v-if="showHover"
      class="absolute top-0 z-30 flex h-6 w-full translate-y-0 scale-0 justify-center opacity-0 transition-all group-hover:-translate-y-full group-hover:scale-100 group-hover:opacity-100"
      @click.stop
      @mousedown.stop
    >
      <div
        class="overlay-buttons absolute top-0 -translate-y-5 rounded-lg bg-white drop-shadow-lg transition-opacity"
        :class="{
          'opacity-0': dragging === 'true'
        }"
      >
        <Popper content="Edit" placement="top" hover class="top-10">
          <button
            type="button"
            class="items-center rounded-l-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
            @click="emit('edit-user')"
          >
            <EditIcon class="h-5 w-5" />
          </button>
        </Popper>
        <Popper v-if="!isDisableDelete" content="Delete" placement="top" hover class="top-10">
          <button
            type="button"
            class="items-center bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
            @click="emit('delete-user')"
          >
            <TrashIcon class="h-5 w-5" />
          </button>
        </Popper>
        <Popper
          :content="`Duplicate (${getShortcutWithCmdOrCtrl('d')})`"
          placement="top"
          hover
          class="top-10"
        >
          <button
            type="button"
            class="items-center rounded-r-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
            @click="handleDuplicate"
          >
            <CopyIcon class="icon h-5 w-5" />
          </button>
        </Popper>
      </div>
    </div>
    <div
      v-if="showHover"
      class="absolute bottom-0 z-30 flex h-6 w-full translate-y-0 scale-0 justify-center opacity-0 transition-all group-hover:translate-y-full group-hover:scale-100 group-hover:opacity-100"
      @click.stop
      @mousedown.stop
    >
      <Popper content="Add direct report" placement="top" hover class="top-10">
        <button
          class="overlay-buttons flex h-9 w-9 translate-y-2 items-center justify-center rounded-lg bg-white shadow-lg transition-all hover:bg-bluedark-25 active:scale-110 active:bg-bluedark-100"
          :class="{
            'opacity-0': dragging === 'true'
          }"
          @click.stop="handleAddUser"
          @mousedown.stop
        >
          <UserPlus class="h-auto w-5" width="20" height="20" />
        </button>
      </Popper>
    </div>
    <div
      class="absolute bottom-0 z-40 flex h-fit w-full justify-center transition-all"
      :class="{
        'translate-y-0 scale-0 opacity-0': !dragging || dragging === 'false',
        'translate-y-full scale-100 opacity-100': dragging === 'true'
      }"
      @click.stop
      @mousedown.stop
    >
      <div
        class="flex h-auto translate-y-2 rounded-lg bg-white px-3 py-2 font-medium text-gray-700 shadow-lg transition-all"
      >
        <p v-if="dragTarget && dragging === 'true'" class="text-xs">
          Drop here to set
          <span class="font-bold">{{ potentialManager }}</span>
          as manager
        </p>
        <p v-else>Drag over a card</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import CopyIcon from '@/assets/Feather/Copy.svg'
import EditIcon from '@/assets/Feather/Edit.svg'
import UserPlus from '@/assets/Feather/UserPlus01.svg'
import MoveIcon from '@/assets/SvgIcons/MoveIcon.svg'
import PencilUnderlineIcon from '@/assets/SvgIcons/PencilUnderlineIcon.svg'
import PersonIcon from '@/assets/SvgIcons/PersonIcon.svg'
import SmallUserPlusIcon from '@/assets/SvgIcons/SmallUserPlusIcon.svg'
import TrashIcon from '@/assets/SvgIcons/TrashIcon.svg'
import useChartMode, { modes } from '@/hooks/use-chart-mode.js'
import useOrgChartColors from '@/hooks/use-org-chart-colors'
import useOrgNodeHelpers from '@/hooks/use-org-node-helpers'
import useScenarioFields from '@/hooks/use-scenario-fields'
import useToast from '@/hooks/use-toast'
import CurrencySymbols from '@/lib/CurrencySymbolConstants.js'
import { getShortcutWithCmdOrCtrl } from '@/lib/KeyboardShortcuts'
import { getCustomFieldsDict } from '@/lib/PersonDataProcessing.js'
import PersonPlanStates from '@/lib/PersonPlanStates'
import { Fields } from '@/lib/PlanFields'
import { blockInvalidDateInputInString } from '@/services/date.service'
import store from '@/store'
import { number as NumberDisplay } from '@coders-tm/vue-number-format'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { useElementHover, useMagicKeys, useResizeObserver } from '@vueuse/core'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import Popper from 'vue3-popper'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { evaluate, getVariablesInFormula, mapDataToVariables } from '@/services/formula.service.js'

const props = defineProps({
  personId: {
    type: String,
    required: true
  },
  boardId: {
    type: String,
    required: true
  },
  isExpanded: {
    type: [Boolean, String],
    default: false
  },
  opacity: {
    type: Number,
    default: 1
  },
  spanOfControl: {
    type: Number,
    default: 0
  },
  activeSubordinatesCount: {
    type: Number,
    default: 0
  },
  dragging: {
    type: String,
    default: 'false'
  },
  dragTarget: {
    type: String,
    default: ''
  },
  draggingOver: {
    type: String,
    default: ''
  },
  enableHover: {
    type: String,
    default: 'true'
  },
  disableEdit: {
    type: String,
    default: 'true'
  }
})

const emit = defineEmits([
  'toggle-collapse',
  'location-change',
  'hire-date-change',
  'salary-change',
  'role-change',
  'add-user',
  'edit-user',
  'delete-user',
  'duplicate-user',
  'paste-user'
])

const numberConfig = {
  decimal: '.',
  separator: ',',
  prefix: '$ ',
  precision: 2,
  masked: false
}

const heightSource = ref(null)
const pulseAnimation = ref(false)
const highlightAnimation = ref(false)
const node = ref(null)
const isUsingInput = () =>
  node.value?.parentNode?.activeElement?.tagName === 'INPUT' ||
  node.value?.parentNode?.activeElement?.tagName === 'TEXTAREA'

useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (!isUsingInput()) {
      if ((e.metaKey || e.ctrlKey) && e.key === 'v' && e.type === 'keydown' && isHovered.value) {
        window.mixpanel.track('node_keyboard_paste')
        e.preventDefault()
        handlePaste()
      }

      if ((e.metaKey || e.ctrlKey) && e.key === 'c' && e.type === 'keydown' && isHovered.value) {
        window.mixpanel.track('node_keyboard_copy')
        e.preventDefault()
        handleCopy()
      }

      if ((e.metaKey || e.ctrlKey) && e.key === 'd' && e.type === 'keydown' && isHovered.value) {
        window.mixpanel.track('node_keyboard_duplicate')
        e.preventDefault()
        handleDuplicate()
      }
    }
  }
})

const isHovered = useElementHover(node)
const { setCopiedId, updateHeight, highlightedNode } = useOrgNodeHelpers()
const { queueToast } = useToast()
const { mode } = useChartMode()
const { selectedFields, isSelected: isFieldEnabled } = useScenarioFields()
const { backgroundColorVariant } = useOrgChartColors()
const bgVariant = computed(() => backgroundColorVariant(props.personId))

const cardBg = computed(() => ({
  'bg-white group-hover:bg-bluedark-100': !bgVariant.value,
  'bg-moss-25 group-hover:bg-moss-100': bgVariant.value === 0,
  'bg-bluedark-25 group-hover:bg-bluedark-100': bgVariant.value === 1,
  'bg-rose-25 group-hover:bg-rose-100': bgVariant.value === 2,
  'bg-orangedark-25 group-hover:bg-orangedark-100': bgVariant.value === 3,
  'bg-greenlight-25 group-hover:bg-greenlight-100': bgVariant.value === 4,
  'bg-blue-25 group-hover:bg-blue-100': bgVariant.value === 5,
  'bg-orange-25 group-hover:bg-orange-100': bgVariant.value === 6,
  'bg-pink-25 group-hover:bg-pink-100': bgVariant.value === 7,
  'bg-bluelight-25 group-hover:bg-bluelight-100': bgVariant.value === 8,
  'bg-indigo-25 group-hover:bg-indigo-100': bgVariant.value === 9,
  'bg-fuchsia-25 group-hover:bg-fuchsia-100': bgVariant.value === 10,
  'bg-green-25 group-hover:bg-green-100': bgVariant.value === 11,
  'bg-cyan-25 group-hover:bg-cyan-100': bgVariant.value === 12,
  'bg-purple-25 group-hover:bg-purple-100': bgVariant.value === 13,
  'bg-violet-25 group-hover:bg-violet-100': bgVariant.value === 14,
  'bg-yellow-25 group-hover:bg-yellow-100': bgVariant.value === 15,
  'bg-teal-25 group-hover:bg-teal-100': bgVariant.value === 16,
  'new-hire bg-green-25 group-hover:bg-green-100':
    mode.value === modes.hiresLeavers || mode.value === modes.changes
}))

const pillStyle = computed(() => ({
  'bg-indigo-50 text-indigo-700 mix-blend-multiply bg-blend-multiply': !bgVariant.value,
  'bg-moss-700 text-moss-50 group-hover:text-moss-100': bgVariant.value === 0,
  'bg-bluedark-700 text-bluedark-50 group-hover:text-bluedark-100': bgVariant.value === 1,
  'bg-rose-700 text-rose-50 group-hover:text-rose-100': bgVariant.value === 2,
  'bg-orangedark-700 text-orangedark-50 group-hover:text-orangedark-100': bgVariant.value === 3,
  'bg-greenlight-700 text-greenlight-50 group-hover:text-greenlight-100': bgVariant.value === 4,
  'bg-blue-700 text-blue-50 group-hover:text-blue-100': bgVariant.value === 5,
  'bg-orange-700 text-orange-50 group-hover:text-orange-100': bgVariant.value === 6,
  'bg-pink-700 text-pink-50 group-hover:text-pink-100': bgVariant.value === 7,
  'bg-bluelight-700 text-bluelight-50 group-hover:text-bluelight-100': bgVariant.value === 8,
  'bg-indigo-700 text-indigo-50 group-hover:text-indigo-100': bgVariant.value === 9,
  'bg-fuchsia-700 text-fuchsia-50 group-hover:text-fuchsia-100': bgVariant.value === 10,
  'bg-green-700 text-green-50 group-hover:text-green-100': bgVariant.value === 11,
  'bg-cyan-700 text-cyan-50 group-hover:text-cyan-100': bgVariant.value === 12,
  'bg-purple-700 text-purple-50 group-hover:text-purple-100': bgVariant.value === 13,
  'bg-violet-700 text-violet-50 group-hover:text-violet-100': bgVariant.value === 14,
  'bg-yellow-700 text-yellow-50 group-hover:text-yellow-100': bgVariant.value === 15,
  'bg-teal-700 text-teal-50 group-hover:text-teal-100': bgVariant.value === 16
}))

const locationMenuList = computed(() => {
  const locations = new Set([
    ...(store.getters.salaryBenchmarkLocations || []),
    ...store.getters['people/locations'](props.boardId)
  ])

  return Array.from(locations)
    .filter(Boolean)
    .sort()
    .map((menuOption, index) => ({
      name: menuOption,
      id: index,
      selected: props.officeLocation === menuOption
    }))
})

const person = computed(() => personById(props.personId).value)

const personById = (personId) =>
  computed(() => store.getters['people/personById']({ boardId: props.boardId, personId }))

const backfillForWho = computed(
  () => personById(person.value?.scenarioMetaData?.forWho).value?.name
)

const personStateInPlan = computed(() => {
  return person.value?.scenarioMetaData?.state || []
})

const isDisableDelete = computed(() => person.value?.scenarioMetaData?.disableDelete ?? false)

const currency = computed(() => {
  return (
    store.getters.compensationForPerson({ boardId: props.boardId, personId: props.personId })
      ?.currency || store.getters.preferredBaseCurrency
  )
})

const salary = computed(() =>
  store.getters.salaryNumber({ boardId: props.boardId, personId: props.personId })
)

const showHover = computed(() => props.enableHover === 'true')

const role = computed(() => person.value?.role || '')

const officeLocation = computed(() => person.value?.officeLocation || '')

const startDate = computed(() =>
  !isEmpty(person.value?.employeeData?.startDate)
    ? moment(person.value?.employeeData?.startDate).format('YYYY-MM')
    : ''
)

const potentialManager = computed(() => {
  const personData = personById(props.dragTarget).value

  const showRole = personData.name === 'Planned role' || !isFieldEnabled(Fields.name)
  const nameOrRole = showRole ? personData.role : personData.name

  return personData?.scenarioMetaData?.state?.includes(PersonPlanStates.RemovedManager)
    ? 'Missing manager'
    : nameOrRole
})

const customFields = computed(() => getCustomFieldsDict(person.value?.customFields))

const formulas = computed(() => store.getters.formulas)

const formulaResult = (formula) =>
  computed(() => {
    const variables = getVariablesInFormula(formula)
    return evaluate({
      formula,
      variables: mapDataToVariables({
        salaryNumber: salary.value,
        person: person.value,
        variables
      })
    })
  })

/**
 * Handle salary change
 * @param {Event} event
 */
const handleSalaryChange = (value) => {
  emit('salary-change', value)
}

const handleCurrencyChange = async (event) => {
  if (
    !event.target.value ||
    event.target.value === currency.value ||
    !CurrencySymbols.find((symbol) => symbol.code === event.target.value)
  )
    return

  await store.dispatch('updateCompensationCurrency', {
    boardId: person.value.boardId,
    personId: props.personId,
    currency: event.target.value
  })
}

/**
 * Handle location change
 * @param {Event} event
 */
const handleLocationChange = (event) => {
  emit('location-change', event.target.value)
}

/**
 * Handle hire date change
 * @param {Event} event
 */
const handleHireDateChange = (event) => {
  emit('hire-date-change', event.target.value)
}

/**
 * Handle role change
 * @param {Event} event
 */
const handleRoleChange = (event) => {
  emit('role-change', event.target.value)
}

/**
 * Emit the event to add the user
 */
const handleAddUser = () => {
  emit('add-user')
}

const handleDuplicate = () => {
  emit('duplicate-user')
}

let pulseAnimationSwitch = null
const triggerPulseAnimation = () => {
  if (pulseAnimationSwitch) {
    clearTimeout(pulseAnimationSwitch)
    pulseAnimation.value = false
  }

  nextTick(() => {
    pulseAnimation.value = true
    pulseAnimationSwitch = setTimeout(() => {
      pulseAnimation.value = false
    }, 1000)
  })
}

const handleCopy = () => {
  if (!isHovered.value) return

  triggerPulseAnimation()
  setCopiedId(props.personId)
  queueToast(`Copied ${props.role || 'new role'} to clipboard`)
}

const handlePaste = () => {
  if (!isHovered.value) return

  triggerPulseAnimation()
  emit('paste-user')
}

watch(highlightedNode, () => {
  if (highlightedNode.value === props.personId) {
    triggerHighlightAnimation()
  }
})

let highlightAnimationSwitch = null

const triggerHighlightAnimation = () => {
  if (highlightAnimationSwitch) {
    clearTimeout(highlightAnimationSwitch)
    highlightAnimation.value = false
  }

  nextTick(() => {
    highlightAnimation.value = true
    highlightAnimationSwitch = setTimeout(() => {
      highlightAnimation.value = false
    }, 1000)
  })
}

onMounted(() => {
  useResizeObserver(heightSource, (entries) => {
    const entry = entries[0]
    updateHeight({ id: props.personId, height: entry?.target?.clientHeight })
  })
})
</script>

<style scoped>
@import url('../index.css');

.org-node {
  @apply font-inter;
}

.pulseAnimation {
  @apply animate-[pulse_0.3s_ease-in-out];
}

.dragging {
  @apply shadow-xl;
}

.dragging-over {
  @apply scale-110 shadow-lg shadow-bluedark-100;
}

.dragging .overlay-buttons {
  @apply opacity-0;
}

.overlay-buttons svg :deep(path) {
  @apply stroke-gray-900;
}

:deep(.popper) {
  @apply pointer-events-none cursor-pointer whitespace-nowrap rounded-lg bg-black px-3 py-2 text-xs text-white shadow-lg !important;
}

#officeLocationInput::-webkit-calendar-picker-indicator {
  display: none !important;
}

.highlightAnimation {
  @apply ring-4 ring-success-400;
}
</style>
