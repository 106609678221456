import { ACCESS_LEVELS_DICT, ACCESS_LEVELS_GROUPS } from '@/lib/Access.js'
import UserTier from '@/lib/UserTier.js'
import hasBenchmarkAccess from '@/services/benchmarkData.service.js'
import hasSalaryCalculatorAccess from '@/services/salaryCalculator.service.js'

//check if compensation tab needs to be added in the hiring  section
const isCompensationEnabled = (boardId) =>
  hasSalaryCalculatorAccess(boardId) || window.MODE === 'DEV'

//check if benchmark tab needs to be added in the hiring section
const isBenchmarkEnabled = (boardId) => hasBenchmarkAccess(boardId) || window.MODE === 'DEV'

export const MenuItems = {
  OrgPlan: 'Org Chart',
  Analytics: 'Analytics',
  Directory: 'Directory',
  Forecast: 'Forecast',
  Plans: 'Plans',
  Hiring: 'Hiring',
  Headcount: 'Headcount',
  Compensation: 'Compensation',
  Benchmark: 'Benchmark',
  Access: 'Access',
  WorkforcePlan: 'Workforce plan',
  ImpactOfPlannedChanges: 'Impact of planned changes',
  Hub: 'Hub'
}

export const FeatureAccess = {
  [MenuItems.Plans]: ACCESS_LEVELS_GROUPS.ABOVE_IC,
  [MenuItems.OrgPlan]: ACCESS_LEVELS_GROUPS.ALL,
  [MenuItems.Analytics]: ACCESS_LEVELS_GROUPS.ABOVE_IC,
  [MenuItems.Directory]: ACCESS_LEVELS_GROUPS.ABOVE_IC,
  [MenuItems.Forecast]: ACCESS_LEVELS_GROUPS.TOP_LEVEL_FINANCE,
  [MenuItems.Hiring]: ACCESS_LEVELS_GROUPS.ABOVE_IC,
  [MenuItems.Headcount]: ACCESS_LEVELS_GROUPS.TOP_LEVEL,
  [MenuItems.Compensation]: ACCESS_LEVELS_GROUPS.TOP_LEVEL,
  [MenuItems.Benchmark]: [ACCESS_LEVELS_DICT.ADMIN],
  [MenuItems.Access]: ACCESS_LEVELS_GROUPS.TOP_LEVEL,
  [MenuItems.WorkforcePlan]: [ACCESS_LEVELS_DICT.ADMIN],
  [MenuItems.ImpactOfPlannedChanges]: ACCESS_LEVELS_GROUPS.TOP_LEVEL_FINANCE,
  [MenuItems.Hub]: ACCESS_LEVELS_GROUPS.ABOVE_IC
}

export const FeatureTiers = {
  [MenuItems.Plans]: [UserTier.Trial, UserTier.Paid],
  [MenuItems.OrgPlan]: [UserTier.Paid, UserTier.Trial, UserTier.Free],
  [MenuItems.Analytics]: [UserTier.Paid],
  [MenuItems.Directory]: [UserTier.Paid, UserTier.Trial],
  [MenuItems.Forecast]: [UserTier.Paid],
  [MenuItems.Hiring]: [UserTier.Paid],
  [MenuItems.Headcount]: [UserTier.Paid],
  [MenuItems.Compensation]: [UserTier.Paid],
  [MenuItems.Benchmark]: [UserTier.Paid],
  [MenuItems.Access]: [UserTier.Paid],
  [MenuItems.WorkforcePlan]: [UserTier.Paid],
  [MenuItems.ImpactOfPlannedChanges]: [UserTier.Paid, UserTier.Trial, UserTier.Free],
  [MenuItems.Hub]: [UserTier.Paid]
}

const generateConfig = (boardId, tier, role) => ({
  [MenuItems.Plans]: {
    name: 'Plans',
    url: `/plans/${boardId}`,
    show: FeatureAccess[MenuItems.Plans].includes(role),
    isPremium: !FeatureTiers[MenuItems.Plans].includes(tier)
  },
  [MenuItems.OrgPlan]: {
    name: 'Org Chart',
    url: `/board/${boardId}`,
    show: FeatureAccess[MenuItems.OrgPlan].includes(role),
    isPremium: !FeatureTiers[MenuItems.OrgPlan].includes(tier)
  },
  [MenuItems.Analytics]: {
    name: 'Analytics',
    url: `/analytics/${boardId}`,
    show: FeatureAccess[MenuItems.Analytics].includes(role),
    isPremium: !FeatureTiers[MenuItems.Analytics].includes(tier)
  },
  [MenuItems.Directory]: {
    name: 'Directory',
    url: `/table/${boardId}`,
    show: FeatureAccess[MenuItems.Directory].includes(role),
    isPremium: !FeatureTiers[MenuItems.Directory].includes(tier)
  },
  [MenuItems.Forecast]: {
    name: 'Forecast',
    url: `/forecast/${boardId}`,
    show: FeatureAccess[MenuItems.Forecast].includes(role),
    isPremium: !FeatureTiers[MenuItems.Forecast].includes(tier)
  },
  [MenuItems.Hiring]: {
    name: 'Hiring',
    url: `/hiring-plan/${boardId}`,
    show: FeatureAccess[MenuItems.Hiring].includes(role),
    isPremium: !FeatureTiers[MenuItems.Hiring].includes(tier)
  },
  [MenuItems.Headcount]: {
    name: 'Headcount',
    url: `/headcount-plan/${boardId}`,
    show: FeatureAccess[MenuItems.Headcount].includes(role),
    isPremium: !FeatureTiers[MenuItems.Headcount].includes(tier)
  },
  [MenuItems.Compensation]: {
    name: 'Compensation',
    url: `/compensation-benchmark/${boardId}`,
    show: isCompensationEnabled(boardId) && FeatureAccess[MenuItems.Compensation].includes(role),
    isPremium: !FeatureTiers[MenuItems.Compensation].includes(tier)
  },
  [MenuItems.Benchmark]: {
    name: 'Benchmark',
    url: `/benchmark/${boardId}`,
    show: isBenchmarkEnabled(boardId) && FeatureAccess[MenuItems.Benchmark].includes(role),
    isPremium: !FeatureTiers[MenuItems.Benchmark].includes(tier)
  },
  [MenuItems.Access]: {
    name: 'Access control',
    url: `/invite-access/${boardId}`,
    show: FeatureAccess[MenuItems.Access].includes(role),
    isPremium: !FeatureTiers[MenuItems.Access].includes(tier)
  },
  [MenuItems.WorkforcePlan]: {
    name: 'Workforce planning',
    url: `/workforce-plan/${boardId}`,
    show: FeatureAccess[MenuItems.WorkforcePlan].includes(role),
    isPremium: !FeatureTiers[MenuItems.WorkforcePlan].includes(tier)
  },
  [MenuItems.ImpactOfPlannedChanges]: {
    name: 'Impact of planned changes',
    url: `/planned-changes-impact/${boardId}`,
    show: FeatureAccess[MenuItems.ImpactOfPlannedChanges].includes(role),
    isPremium: !FeatureTiers[MenuItems.ImpactOfPlannedChanges].includes(tier)
  },
  [MenuItems.Hub]: {
    name: 'Hub',
    url: `/hub/${boardId}`,
    show: FeatureAccess[MenuItems.Hub].includes(role),
    isPremium: !FeatureTiers[MenuItems.Hub].includes(tier)
  }
})

/**
 * Get configuration for navigation bar
 * @param boardId
 * @returns {[{icon: *, name: string, href: string},{name: string, icon: *, subitems: [{name: string, href: string, routeName: string},{name: string, disabled: boolean, href: string, routeName: string},{name: string, href: string, routeName: string},{name: string, href: string, routeName: string}]},{name: string, icon: *, subitems: [{name: string, href: string},{name: string, href: string},{name: string, href: string}]}]}
 */
export const getNavigationConfig = ({ boardId, route, tier, isVerified, role }) => {
  if (boardId && isVerified && route.name !== 'No Access') {
    return generateConfig(boardId, tier, role)
  }

  return {}
}
