import { formatDistance } from 'date-fns'
import moment from 'moment/moment.js'

/**
 * Return the parsed date string in 'MMM D, YYYY' format
 * @param {Number} seconds
 * @returns {String}
 */
export const formatDate = (seconds) => {
  return !seconds ? '' : moment(seconds * 1000).format('MMM D, YYYY')
}

/**
 * Return the parsed date string in 'MMM D, YYYY' format
 * @param {Date} dateObject
 * @returns {string|string}
 */
export const formatDateObject = (dateObject) => {
  return !dateObject ? '' : moment(dateObject).format('MMM D, YYYY')
}

/**
 * Return the parsed date string in 'MMM D, YYYY' format
 * @param {String} dateObject
 * @returns {string|string}
 */
export const formatDateString = (dateString) => {
  return !dateString ? '' : moment(dateString).format('MMM D, YYYY')
}

/**
 *
 * @param {String | Date} date
 * @returns Date
 *
 * Given a Date object or a Date string (eg. `'12-07-2008'`) returns a Date object in the format of `YYYY-MM-DD`
 */
export const formatDateToStandard = (date) => {
  if (date === '' || date === null) return null

  const newDate = moment(date).format('YYYY-MM-DD')
  if (newDate === 'Invalid date')
    throw new Error("Date(s) invalid. Please ensure you're using the correct format.")

  return newDate
}

/**
 *
 * @param {String | Date} date
 * @param {String} format
 * @returns Date
 *
 * Given a Date object or a Date string (eg. `'12-07-2008'`) returns a Date object in the format of `YYYY-MM-DD`
 */
export const formatDateToCustom = (date, format) => {
  if (date === '' || date === null) return

  const newDate = moment(date).format(format)
  if (newDate === 'Invalid date')
    throw new Error("Date(s) invalid. Please ensure you're using the correct format.")

  return newDate
}

/**
 * check if the date string is in the yyyy-MM-dd format
 * @param dateString date value to be checked
 * @returns Boolean
 */
export const isValidDateFormat = (dateString) => {
  return moment(dateString, 'YYYY-MM-DD', true).isValid()
}

export const convertFormat = (dateString, fromFormat, toFormat) => {
  return moment(dateString, fromFormat).format(toFormat)
}

export const getDateObject = (dateString, format) => {
  //retun standard date object
  return moment(dateString, format).toDate()
}

const unwrapTimestamp = (dateString) => {
  let timestamp = dateString

  if (typeof dateString === 'object') {
    if (dateString.seconds) timestamp = dateString.seconds * 1000
    else if (dateString._seconds) timestamp = dateString._seconds * 1000
  }

  return timestamp
}

export const getRelativeDate = (dateString) => {
  const timestamp = unwrapTimestamp(dateString)

  return formatDistance(new Date(timestamp), new Date(), { addSuffix: true })
}

export const getDate = (dateString) => {
  const timestamp = unwrapTimestamp(dateString)

  return new Date(timestamp).toLocaleString()
}

/**
 * Blocks invalid characters from being inputted in a date string input field.
 *
 * @param {Event} event - The keyboard event object.
 * @returns {void}
 */
export const blockInvalidDateInputInString = (event) => {
  const allowedKeysRegex = /^[0-9-]*$/
  const keyPressed = event.key
  if (!allowedKeysRegex.test(keyPressed)) {
    event.preventDefault()
  }
}
