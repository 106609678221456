import { isNumber } from 'lodash-es'
import numeral from 'numeral'

/**
 * Returns localised string with amount in given currency
 * @param amount
 * @param currency
 * @returns {string}
 */
export const localiseCurrency = (amount, currency = 'USD', maximumFractionDigits = 0) => {
  const locale = navigator.language
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    maximumFractionDigits,
    currency
  }).format(amount)
}

export const localiseCurrencyOptions = (
  amount,
  { currency = 'USD', maximumFractionDigits = 0 }
) => {
  const locale = navigator.language
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    maximumFractionDigits,
    currency
  }).format(amount)
}

//TODO: potentially unsafe when decimal separator is not a dot/comma
export const extractNumber = (stringValue) => {
  if (isNumber(stringValue)) return stringValue

  const nonDigits = /[^0-9.-]+/g
  return stringValue?.length ? Number(stringValue.replace(nonDigits, '')) : 0
}

/**
 * Formats a number by rounding it off and using abbreviations like "M" and "K".
 * @param {number} number - The number to be formatted.
 * @returns {string} The formatted number string.
 */
export const shortenNumber = (number) => {
  return numeral(number).format('0.0 a')
}
