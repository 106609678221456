import { find, map } from 'lodash-es'

const ACCESS_LEVELS = [
  'no_access',
  'admin',
  'executive',
  'manager',
  'individual_contributor',
  'hr',
  'finance'
]

export const ACCESS_LEVELS_DICT = {
  NO_ACCESS: 'no_access',
  ADMIN: 'admin',
  EXECUTIVE: 'executive',
  MANAGER: 'manager',
  INDIVIDUAL_CONTRIBUTOR: 'individual_contributor',
  HR: 'hr',
  FINANCE: 'finance'
}

export const ACCESS_LEVELS_GROUPS = {
  ALL: [
    ACCESS_LEVELS_DICT.INDIVIDUAL_CONTRIBUTOR,
    ACCESS_LEVELS_DICT.MANAGER,
    ACCESS_LEVELS_DICT.ADMIN,
    ACCESS_LEVELS_DICT.EXECUTIVE,
    ACCESS_LEVELS_DICT.HR,
    ACCESS_LEVELS_DICT.FINANCE
  ],
  ABOVE_IC: [
    ACCESS_LEVELS_DICT.MANAGER,
    ACCESS_LEVELS_DICT.ADMIN,
    ACCESS_LEVELS_DICT.EXECUTIVE,
    ACCESS_LEVELS_DICT.HR,
    ACCESS_LEVELS_DICT.FINANCE
  ],
  TOP_LEVEL_FINANCE: [
    ACCESS_LEVELS_DICT.ADMIN,
    ACCESS_LEVELS_DICT.EXECUTIVE,
    ACCESS_LEVELS_DICT.FINANCE
  ],
  TOP_LEVEL: [ACCESS_LEVELS_DICT.ADMIN, ACCESS_LEVELS_DICT.EXECUTIVE]
}

const FORMATTED_ACCESS_LEVEL_DICT = {
  [ACCESS_LEVELS_DICT.NO_ACCESS]: 'No Access',
  [ACCESS_LEVELS_DICT.ADMIN]: 'Admin',
  [ACCESS_LEVELS_DICT.EXECUTIVE]: 'Executive',
  [ACCESS_LEVELS_DICT.MANAGER]: 'Manager',
  [ACCESS_LEVELS_DICT.INDIVIDUAL_CONTRIBUTOR]: 'Individual Contributor',
  [ACCESS_LEVELS_DICT.HR]: 'HR',
  [ACCESS_LEVELS_DICT.FINANCE]: 'Finance'
}

/**
 *
 * @param {*} board board object
 * @param {*} user user object (Auth object by Firebase Auth)
 * @param {*} targetPerson person object of the person data being accessed
 * @param {*} me the current user's person object
 * @param {*} peopleDict people data keyed by personId
 * @returns
 */
const AccessCheck = (board, user, targetPerson, me, peopleDict) => {
  if (!board.accessLevels) return false
  if (!me) return false

  const ret = find(board.accessLevels, (accessObj) => {
    return accessObj.uid === user.uid
  })

  if (ret.accessLevel === 'admin' && targetPerson.personId !== me.personId) {
    // I'm Admin. I can access anything
    // unless it's myself
    return true
  }

  if (targetPerson.personId === me.personId) {
    // I should not be able to see my own performance
    return false
  }
  // I am a manager. I need to check if I am access my own team
  const myTeam = []

  /**
   * Set the myTeam
   *
   * @param {*} target
   */
  const listAllSubordinates = (target) => {
    if (!target) return
    myTeam.push(target.personId)
    map(target.subordinates, (personId) => {
      listAllSubordinates(peopleDict[personId])
    })
  }

  listAllSubordinates(me)
  // Remove myself. Since I should not be allow to see my own performance
  const k = myTeam.indexOf(me.personId)
  if (k > -1) myTeam.splice(k, 1)

  return myTeam.includes(targetPerson.personId)
}

const userHasRouteAccess = ({ role, permittedRoles, blockedRoles, tier, permittedTiers }) => {
  if (!permittedRoles && !blockedRoles && !permittedTiers) return true

  if (permittedTiers && !permittedTiers.includes(tier)) {
    return false
  }

  if (blockedRoles && blockedRoles.includes(role)) {
    console.log("user's role is blocked from this page")
    return false
  }
  if (permittedRoles && !permittedRoles.includes(role)) {
    console.log("user's role does not have access to this page")
    return false
  }

  return true
}

export { AccessCheck, ACCESS_LEVELS, FORMATTED_ACCESS_LEVEL_DICT, userHasRouteAccess }
