// import Vue from 'vue';
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { sortBy } from 'lodash-es'

const state = {
  history: []
}

const mutations = {
  setHistory(state, historyArr) {
    state.history = historyArr
  }
}

const actions = {
  // setUserAuth(context, user) {
  //   context.commit('setUser', user);
  // },

  // Fetch the changes in the last 48 hrs in a board
  async fetchOrgHistory(context, { boardId, hoursAgo }) {
    const current = Date.now()
    let querySnapshot = null

    if (hoursAgo) {
      let compareDate = new Date(current - hoursAgo * 60 * 60 * 1000)
      compareDate = firebase.firestore.Timestamp.fromDate(compareDate)

      querySnapshot = await firebase
        .firestore()
        .collection('people_history')
        .where('boardId', '==', boardId)
        .where('updatedAt', '>', compareDate)
        .get()
    } else {
      querySnapshot = await firebase
        .firestore()
        .collection('people_history')
        .where('boardId', '==', boardId)
        .get()
    }

    let ret = []
    querySnapshot.forEach((doc) => {
      ret.push(doc.data())
    })

    ret = sortBy(ret, (o) => {
      return -1 * o.updatedAt.seconds
    })

    context.commit('setHistory', ret)
  }
}

export default {
  state,
  mutations,
  actions,
  modules: {}
}
