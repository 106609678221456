import { createSquadId } from '@/lib/idCreator'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { chain, cloneDeep, each, find, map } from 'lodash'

const getDefaultState = () => {
  return {
    squads: [],
    isFetchingSquads: false
  }
}

const state = getDefaultState()

const mutations = {
  addNewSquad(_state, squad) {
    _state.squads.push(squad)
  },
  updateSquad(_state, { squadId, key, value }) {
    let clone = cloneDeep(_state.squads)
    each(clone, (obj) => {
      if (obj.squadId === squadId) {
        obj[key] = value
      }
    })
    _state.squads = clone
  },
  resetSquads(_state) {
    Object.assign(_state, getDefaultState())
  },
  setSquadFetchingStatus(_state, status) {
    _state.isFetchingSquads = status
  }
}

const getters = {
  squadCollisionCounts: (_state) => {
    const activeSquads = _state.squads.filter((squad) => !squad?.deletedAt)
    const personIdAssignedSquadCounts = chain(activeSquads)
      .map((squad) => {
        return squad.team.concat(squad.dri)
      })
      .flatten()
      .countBy((pid) => pid)
      .map((v, k) => {
        return { personId: k, count: v }
      })
      .filter((obj) => obj.count > 1)
      .groupBy('count')
      .map((o, count) => {
        return {
          personIds: map(o, 'personId'),
          count
        }
      })
      .value()

    const ret = {}
    each(personIdAssignedSquadCounts, (o) => {
      ret[`Assigned to ${o.count} squads`] = o.personIds
    })
    return ret
  },
  isFetchingSquads: (_state) => {
    return _state.isFetchingSquads
  },
  squads: (_state) => {
    return _state.squads
  }
}

const actions = {
  addNewSquad(context, { boardId }) {
    const squadId = createSquadId()
    context.commit('addNewSquad', {
      squadId,
      name: 'New Squad',
      initiative: 'No Initiative Set',
      boardId,
      dri: '',
      team: []
    })
    return squadId
  },
  updateSquad(context, { squadId, key, value }) {
    context.commit('updateSquad', { squadId, key, value })
  },
  deleteSquad(context, { squadId }) {
    const deletedAt = firebase.firestore.FieldValue.serverTimestamp()
    context.commit('updateSquad', {
      squadId,
      key: 'deletedAt',
      value: deletedAt
    })
  },
  addNewSquad_v2(context, { boardId, data }) {
    const squadId = createSquadId()
    context.commit('addNewSquad', {
      squadId,
      name: data.name,
      initiative: data.goal,
      boardId,
      dri: data.lead,
      team: data.members
    })
    return squadId
  },
  submitSquads(context, { boardId, squadId }) {
    // var batch = firebase.firestore().batch();

    const targetSquad = find(context.state.squads, (squad) => {
      return squad.squadId === squadId
    })

    if (!targetSquad) return
    const ref = firebase.firestore().collection('squads').doc(squadId)

    const _squad = cloneDeep(targetSquad)
    _squad.boardId = boardId
    if (!_squad.createdAt) _squad.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    _squad.updatedAt = firebase.firestore.FieldValue.serverTimestamp()
    _squad.updatedBy = context.rootGetters.uid
    ref.set(_squad)
  },
  async fetchSquads(context, { boardId }) {
    context.commit('resetSquads')

    context.commit('setSquadFetchingStatus', true)

    const querySnapshot = await firebase
      .firestore()
      .collection('squads')
      .where('boardId', '==', boardId)
      .get()

    querySnapshot.forEach((doc) => {
      context.commit('addNewSquad', doc.data())
    })
    context.commit('setSquadFetchingStatus', false)
  },

  resetSquads(context) {
    context.commit('resetSquads')
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
