import { until } from '@vueuse/core'
import { computed, ref, reactive, unref } from 'vue'

const show = ref({})
const selectedPerson = ref(null)
const goToPerson = reactive({})

export default function usePeopleBrowser(boardId) {
  const setShow = (value) => {
    show.value[unref(boardId)] = value
  }

  const getPersonFromBrowser = async () => {
    selectedPerson.value = null
    setShow(true)

    await until(selectedPerson).toMatch((value) => value !== null)

    setShow(false)

    if (selectedPerson.value.canceled) return undefined
    return selectedPerson.value.personId
  }

  const personSelected = (personId) => {
    selectedPerson.value = { personId }
  }

  const selectionCanceled = () => {
    selectedPerson.value = { canceled: true }
  }

  const setPersonToGoTo = (personId) => {
    goToPerson[unref(boardId)] = personId
  }

  const goToFinished = () => {
    goToPerson[unref(boardId)] = null
  }

  return {
    show: computed(() => show.value[unref(boardId)] || false),
    goToPerson: computed(() => goToPerson[unref(boardId)]),
    setShow,
    personSelected,
    getPersonFromBrowser,
    selectionCanceled,
    setPersonToGoTo,
    goToFinished
  }
}
