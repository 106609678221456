/**
 * Checks if the board has access to the salay calculator feature
 * @param boardId boardId the client is using
 * @returns Boolean value
 */
export const hasSalaryCalculatorAccess = (boardId) => {
  const clientBoardIds = [
    'board_yVDKBYhDKO9', // One Ten Inc BoardId (demo org)
    'bd_VduJp3mMm4D7rN1kYBHH5', // Mattermost BoardId
    'bd_5NA6Fd9Bg51TqUwf8DK95D', // Opensea BoardId
    'bd_lzfFGXzJ7ahPmOANTqdANg9' // Pulley Org BoardId
  ]

  return clientBoardIds.includes(boardId)
}

export default hasSalaryCalculatorAccess
