import { concat } from 'lodash-es'

/**
 * Checks if the board has access to the benchmark feature
 * @param boardId boardId the client is using
 * @returns Boolean value
 */
export const hasBenchmarkAccess = (boardId) => {
  const mattermostBoardId = ['bd_VduJp3mMm4D7rN1kYBHH5']
  const salesDemo = ['board_yVDKBYhDKO9']
  return concat(mattermostBoardId, salesDemo).includes(boardId)
}

export default hasBenchmarkAccess
