import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

export const requestForecastGeneration = async (boardId) => {
  const forecastFetch = firebase.functions().httpsCallable('forecast-fetch')
  const response = await forecastFetch({ boardId })
  return response.data === 'Request processed'
}

export const getForecast = async (boardId) => {
  try {
    const forecast = await firebase.firestore().collection('forecast').doc(boardId).get()
    const forecastData = forecast.data()
    if (!forecastData) return null

    const {
      dataHeader = null,
      departmentSummary = null,
      peopleSummary = null,
      updatedAt
    } = forecastData

    const forecastPeople = await firebase
      .firestore()
      .collection(`forecast/${boardId}/people`)
      .where('boardId', '==', boardId)
      .get()

    const people = forecastPeople.docs.map((doc) => doc.data())

    const forecastDepartments = await firebase
      .firestore()
      .collection(`forecast/${boardId}/department`)
      .where('boardId', '==', boardId)
      .get()

    const departments = forecastDepartments.docs.map((doc) => doc.data())

    return { dataHeader, departmentSummary, peopleSummary, people, departments, updatedAt }
  } catch (error) {
    console.log(error)
    return null
  }
}
