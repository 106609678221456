import * as Sentry from '@sentry/browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import { isEmpty } from 'lodash-es'

const getDefaultState = () => ({
  workforceHubByBoardId: {},
  layerAndDepth: {},
  orgMap: {},
  headcountDistribution: {},
  spanOfControl: {},
  icicleChart: {}
})

const state = getDefaultState()

const mutations = {
  setLayerAndDepth(_state, data) {
    _state.layerAndDepth['dataByOptions'] = data
    _state.layerAndDepth['options'] = Object.keys(data)
  },
  setHeadcountDistribution(_state, data) {
    _state.headcountDistribution['dataByOptions'] = data
    _state.headcountDistribution['options'] = Object.keys(data)
  },

  setSpanOfControl(_state, data) {
    _state.spanOfControl['dataByOptions'] = data
    _state.spanOfControl['options'] = Object.keys(data)
  },

  setOrgMap(_state, data) {
    _state.orgMap = data
  },

  setIcicleChart(_state, data) {
    _state.icicleChart = data
  },

  //reset
  resetOrgMap(_state) {
    _state.orgMap = {}
  },
  resetLayerAndDepth(_state) {
    _state.layerAndDepth = {}
  },
  resetHeadcountDistribution(_state) {
    _state.headcountDistribution = {}
  },
  resetSpanOfControl(_state) {
    _state.spanOfControl = {}
  },
  resetIcicleChart(_state) {
    _state.icicleChart = {}
  }
}

const getters = {
  // layer and depth
  getLayerAndDepth:
    (_state) =>
    ({ filter }) =>
      _state.layerAndDepth?.dataByOptions?.[filter] || [],
  getLayerAndDepthOptions: (_state) => _state.layerAndDepth?.options || [],

  //headcount distribution
  getHeadcountDistribution:
    (_state) =>
    ({ filter }) =>
      _state.headcountDistribution?.dataByOptions?.[filter] || {},
  getHeadcountDistributionOptions: (_state) => _state.headcountDistribution?.options || [],

  //span of control
  getSpanOfControl:
    (_state) =>
    ({ filter }) =>
      _state.spanOfControl?.dataByOptions?.[filter] || {},
  getSpanOfControlOptions: (_state) => _state.spanOfControl?.options || [],

  // icicle
  getIcicleChart: (_state) => _state.icicleChart || {},
  isIcicleChartAvailable: (_state, _getters) => !isEmpty(_getters.getIcicleChart),

  //org map
  getOrgMapByOptions:
    (_state) =>
    ({ filter }) =>
      _state.orgMap?.dataset[filter] || [],
  getOrgMapOptions: (_state) => _state.orgMap?.options || [],
  isOrgMapAvailable:
    (_state, _getters) =>
    ({ filter }) => {
      return (
        _getters.getOrgMapOptions?.length > 0 && _getters.getOrgMapByOptions({ filter })?.length > 0
      )
    }
}

const actions = {
  async fetchLayerAndDepth(context, { boardId }) {
    window.mixpanel.track('workforce_hub_fetch_layer_and_depth', { boardId })
    try {
      const headcountData = firebase.functions().httpsCallable('layerAndDepthHub-fetch')
      const result = await headcountData({ boardId })
      context.commit('setLayerAndDepth', result?.data?.data || {})
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchLayerAndDepth err', e)
      context.commit('resetLayerAndDepth')
    }
  },

  async fetchHeadcountDistribution(context, { boardId }) {
    window.mixpanel.track('workforce_hub_fetch_layer_and_depth', { boardId })
    try {
      const headcountData = firebase.functions().httpsCallable('headcountDistributionHub-fetch')
      const result = await headcountData({ boardId })
      // console.log('result', result)
      context.commit('setHeadcountDistribution', result?.data || {})
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchHeadcountDistribution err', e)
      context.commit('resetHeadcountDistribution')
    }
  },

  async fetchOrgMap(context, { boardId }) {
    window.mixpanel.track('workforce_hub_fetch_org_map', { boardId })
    try {
      const orgData = firebase.functions().httpsCallable('orgMap-fetch')
      const result = await orgData({ boardId })
      // console.log('fetchOrgMap', result)
      context.commit('setOrgMap', result?.data || {})
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchOrgMap err', e)
      context.commit('resetOrgMap', boardId)
    }
  },

  async fetchIcicleChart(context, { boardId }) {
    window.mixpanel.track('workforce_hub_fetch_icicle_chart', { boardId })
    try {
      const chartData = firebase.functions().httpsCallable('icicleChart-fetch')
      const result = await chartData({ boardId })
      context.commit('setIcicleChart', result?.data || {})
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchIcicleChart err', e)
      context.commit('resetIcicleChart')
    }
  },

  async fetchSpanOfControl(context, { boardId }) {
    window.mixpanel.track('workforce_hub_fetch_span_of_control', { boardId })
    try {
      const chartData = firebase.functions().httpsCallable('spanOfControlHub-fetch')
      const result = await chartData({ boardId })
      context.commit('setSpanOfControl', result?.data || {})
    } catch (e) {
      Sentry.captureException(e)
      console.error('fetchSpanOfControl err', e)
      context.commit('resetSpanOfControl')
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
