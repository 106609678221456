<template>
  <div class="hidden h-screen w-screen sm:block">
    <router-view v-slot="{ Component }">
      <transition
        appear
        mode="out-in"
        enter-active-class="transition ease-out duration-200"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-200"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="isLoading"
          class="flex h-full w-full flex-col items-center justify-start gap-4 overflow-hidden"
        >
          <img src="@/assets/loader-small.gif" class="mt-20" alt="Loading the app" />
          <section v-show="showLogoutMsg" class="flex flex-col items-center justify-start gap-4">
            <span class="text-base font-medium text-gray-500">
              Having trouble logging in? Log out and try logging in again. Apologies for the
              inconvenience.
            </span>
            <BaseButton color="blue-light" @click="logout">Log out</BaseButton>
          </section>
        </div>
        <div v-else class="flex h-full">
          <Navigation v-if="isLoggedIn && !hideNavigation" />

          <!-- Content area -->
          <div id="content-area" class="flex flex-1 flex-col overflow-hidden">
            <!-- Main content -->
            <div class="flex flex-1 items-stretch overflow-hidden">
              <main class="flex-1 overflow-y-auto">
                <!-- Primary column -->
                <!-- <transition
                  appear
                  mode="out-in"
                  enter-active-class="transition ease-out"
                  enter-class="opacity-0"
                  enter-to-class="opacity-100"
                  leave-active-class="transition ease-in"
                  leave-class="opacity-100"
                  leave-to-class="opacity-0"
                > -->
                <component :is="Component" :key="route.fullPath" />
                <!-- </transition> -->
              </main>
            </div>
          </div>
        </div>
      </transition>
    </router-view>

    <CalendlyModal />
    <PeopleBrowser v-if="boardId" :board-id="boardId" mode="goto" />
  </div>
  <MobileLock class="sm:hidden" />
</template>

<script setup>
import CalendlyModal from '@/components/CalendlyModal.vue'
import BaseButton from '@/components/BaseButton.vue'
import MobileLock from '@/components/MobileLock.vue'
import Navigation from '@/components/SideNavigationCollapsable.vue'
import acceptPlanInvitation from '@/services/acceptPlanInvitationForNewUser.service.js'
import { importInitialData } from '@/services/google-sheet-processing.service.js'
import * as Sentry from '@sentry/browser'
import 'firebase/auth'
import firebase from 'firebase/compat/app'
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import PeopleBrowser from '@/components/PeopleBrowser.vue'
import useBoard from '@/hooks/use-board.js'
import { useMagicKeys } from '@vueuse/core'
import usePeopleBrowser from '@/hooks/use-people-browser.js'

const { state, commit, getters, dispatch } = useStore()
const router = useRouter()
const route = useRoute()
const { boardId } = useBoard()
const { meta, k } = useMagicKeys()
const { setShow } = usePeopleBrowser(boardId)

const isLoading = computed(() => getters.isAppLoading)
const isLoggedIn = computed(() => getters.isLoggedIn)
const showLogoutMsg = ref(false)
const hideNavigation = computed(() => {
  return ['Home', 'WeeeHome', 'SignUp', 'SharedPlan'].includes(route.name)
})
const user = computed(() => getters.user)
let timer = null

/**
 * Starts the loading process by changing the isAppLoading state to true and setting a timer to show a logout message after 5 seconds.
 */
const startLoading = async () => {
  await dispatch('changeIsAppLoading', true)
  timer = setTimeout(() => {
    showLogoutMsg.value = true
  }, 5000)
}

/**
 * Stops the loading process by clearing the timer, changing the isAppLoading state to false, and hiding the logout message.
 */
const stopLoading = async () => {
  clearTimeout(timer)
  await dispatch('changeIsAppLoading', false)
  showLogoutMsg.value = false
}

/**
* Watches the isLoading state and performs actions based on its value.
* If the loading is false, it clears the timer and hides the logout message.
@param {boolean} newValue - The new value of the isLoading state.
*/
watch(isLoading, (newValue) => {
  if (!newValue) {
    clearTimeout(timer)
    showLogoutMsg.value = false
  }
})

/*
 * Logs out the user by dispatching the 'logout' action, stopping the loading process, and resetting the application state.
 * It also redirects the user to the home page.
 */
const logout = async () => {
  window.mixpanel.track('app_logout_due_to_stuck_at_loading')
  await dispatch('logout')
  await stopLoading()
  dispatch('people/reset')

  if (window.Intercom) window.Intercom('shutdown')
  location.href = '/'
}

onMounted(async () => {
  window.mixpanel.track('app_open')
  window.logGA4Event('app_open')
  await startLoading()

  //TODO: move to auth service, app.vue shouldn't care about this
  //TODO: does it run when token is active?
  firebase.auth().onAuthStateChanged(async (userObj) => {
    if (!userObj) {
      try {
        await firebase.auth().getRedirectResult()
      } catch (error) {
        commit('setSignInError', error)
      } finally {
        await stopLoading()
        if (route.meta.requiresAuth) {
          window.location.replace('/')
        }
      }

      return
    }

    await dispatch('setUserAuth', userObj)
    setupTracking(userObj)

    if (!userObj.emailVerified) {
      await stopLoading()
      return router.push({ path: '/verify' })
    }

    if (
      userObj.email?.includes('@sayweee.com') &&
      ![
        'rudy.zhang@sayweee.com',
        'victoria.peng@sayweee.com',
        'xueyan.kehon@sayweee.com',
        'christina.wu@sayweee.com'
      ].includes(userObj.email)
    ) {
      // Reroute Weee users to Weee dedicated home page
      await stopLoading()
      await router.push({ name: 'WeeeHome' })
      return
    }

    try {
      await Promise.allSettled([
        dispatch('fetchAllBoards', userObj.uid),
        dispatch('fetchPendingInvitations', {
          email: userObj.email
        }),
        dispatch('fetchPendingPlanInvitations', {
          email: userObj.email
        })
      ])

      const hasBoards = state.board.boards.filter((board) => board.masterBranch).length > 0
      const hasInvites = getters.hasPendingInvitations
      const hasPlanInvites = getters.hasUserPlanInvites

      if (!hasInvites && !hasBoards && !hasPlanInvites) {
        window.mixpanel.track('new_self_serve_user')
        window.logGA4Event('new_self_serve_user')
        await setupNewAccountWithRedirectToPlan()
      } else if (hasPlanInvites) {
        await acceptPlanInvitation(userObj.email)
      } else if (hasInvites) {
        window.mixpanel.track('new_invited_user')
        window.logGA4Event('new_invited_user')
        await router.push({ name: 'Landing' })
      }

      await refreshClaims(userObj)
    } catch (error) {
      commit('setSignInError', error)
      console.error('error occured in redirection logic', error)
    } finally {
      await stopLoading()
    }
  })
})

const setupNewAccountWithRedirectToPlan = async () => {
  const newBoardId = await importInitialData({ email: user.value.email, uid: user.value.uid })

  //create plan based on the new board
  const planId = await dispatch('clone', {
    sourceId: newBoardId,
    newBoardName: '[Sample] Plan for 2023'
  })

  await dispatch('fetchAllBoards', user.value.uid)

  console.log('new board id', newBoardId)
  await router.push({
    name: 'PlanEditor2',
    params: { boardId: newBoardId },
    query: { plan_board_id: planId }
  })

  // send welcome email
  await firebase.functions().httpsCallable('sendWelcomeEmail')({
    recipient: user.value.email,
    recipientName: user.value.displayName || ''
  })
}

const setupTracking = function (userObj) {
  window.mixpanel.identify(userObj.uid)
  window.mixpanel.people.set({
    $email: userObj.email, // only reserved properties need the $
    creationTime: userObj.metadata.creationTime,
    lastSignInTime: userObj.metadata.lastSignInTime
  })
  window.mixpanel.track('login')
  window.logGA4Event('login')
  window.mixpanel.set_group('company', userObj.email.split('@')[1])
  if (window.ga4) {
    window.ga4.setUserProperties({
      company: userObj.email.split('@')[1],
      email: userObj.email,
      id: userObj.uid
    })
  }

  if (window.smartlook) {
    window.smartlook('identify', userObj.uid, {
      name: userObj.displayName,
      email: userObj.email
    })
  }

  Sentry.configureScope(function (scope) {
    scope.setUser({ email: userObj.email })
    scope.setUser({ username: userObj.displayName })
    scope.setUser({ id: userObj.uid })
  })
}

const refreshClaims = async (userObj) => {
  await userObj.reload()
  await userObj.getIdToken(true)
  const claims = await dispatch('updateClaims')
  if (claims?.tier === undefined) {
    setTimeout(async () => {
      await dispatch('updateClaims')
    }, 3000)
  }
}

watchEffect(() => {
  if (meta.value && k.value) {
    setShow(true)
  }
})
</script>

<style>
body {
  /* without this line, the font resets to default */
  font-family: Inter, 'sans-serif';
}
</style>
