import HiringRequestStatus from '@/lib/HiringRequestStatus'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import {
  headcountFirestoreCollection,
  hiringFirestoreCollection,
  hiringRequestsFirestoreCollection
} from '../lib/HeadcountPlanCollections'

const Mutations = {
  setIsLoading: 'setIsLoading',
  setIsCreating: 'setIsCreating',
  setHeadcountPlan: 'setHeadcountPlan',
  setHiringPlans: 'setHiringPlans',
  setHiringRequests: 'setHiringRequests',
  setHiringPlan: 'setHiringPlan',
  updateHiringRequest: 'updateHiringRequest',
  updateHeadcountPlan: 'updateHeadcountPlan'
}

/**
 * generate default state for the module
 */
const getDefaultState = () => {
  return {
    isLoading: false,
    isCreating: false,
    headcountPlan: null,
    hiringPlans: [],
    hiringRequestsByPlan: {}
  }
}

const state = getDefaultState()

const mutations = {
  [Mutations.setIsLoading](_state, isLoading) {
    _state.isLoading = isLoading
  },
  [Mutations.setIsCreating](_state, isCreating) {
    _state.isCreating = isCreating
  },
  [Mutations.setHeadcountPlan](_state, headcountPlan) {
    _state.headcountPlan = headcountPlan
  },
  [Mutations.setHiringPlans](_state, hiringPlans) {
    hiringPlans.forEach((hiringPlan) => {
      if (!_state.hiringPlans.find((plan) => plan.id === hiringPlan.id))
        _state.hiringPlans.push(hiringPlan)
    })
  },
  [Mutations.setHiringRequests](_state, { hiringPlanId, hiringRequests }) {
    _state.hiringRequestsByPlan[hiringPlanId] = hiringRequests
  },
  [Mutations.setHiringPlan](_state, hiringPlan) {
    const existingPlanIndex = _state.hiringPlans.findIndex((plan) => plan.id === hiringPlan.id)

    if (existingPlanIndex >= 0) {
      _state.hiringPlans[existingPlanIndex] = hiringPlan
    } else {
      _state.hiringPlans.push(hiringPlan)
    }
  },
  [Mutations.updateHiringRequest](_state, hiringRequest) {
    const index = _state.hiringRequestsByPlan[hiringRequest.hiringPlanId].findIndex(
      (req) => req.id === hiringRequest.id
    )

    if (index > -1) {
      Object.assign(_state.hiringRequestsByPlan[hiringRequest.hiringPlanId][index], hiringRequest)
    }
  },
  [Mutations.updateHeadcountPlan](_state, headcountPlan) {
    Object.assign(_state.headcountPlan, headcountPlan)
  }
}

const actions = {
  async fetchHeadcountPlan(context, { boardId }) {
    context.commit(Mutations.setIsLoading, true)

    try {
      const response = await firebase
        .firestore()
        .collection(headcountFirestoreCollection)
        .where('boardId', '==', boardId)
        .get()

      const plan = response.docs[0]?.data()
      if (plan)
        context.commit(Mutations.setHeadcountPlan, {
          ...plan,
          id: response.docs[0].id
        })
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  },
  async fetchHiringPlans(context, { headcountPlanId }) {
    context.commit(Mutations.setIsLoading, true)

    try {
      const response = await firebase
        .firestore()
        .collection(hiringFirestoreCollection)
        .where('headcountPlanId', '==', headcountPlanId)
        .get()

      const plans = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      if (plans) context.commit(Mutations.setHiringPlans, plans)
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  },
  async fetchHiringPlansForBoard(context, { boardId }) {
    context.commit(Mutations.setIsLoading, true)

    try {
      const response = await firebase
        .firestore()
        .collection(hiringFirestoreCollection)
        .where('boardId', '==', boardId)
        .get()

      const plans = response.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      if (plans) context.commit(Mutations.setHiringPlans, plans)
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  },
  async fetchHiringPlan(context, { hiringPlanId }) {
    context.commit(Mutations.setIsLoading, true)

    try {
      const response = await firebase
        .firestore()
        .collection(hiringFirestoreCollection)
        .doc(hiringPlanId)
        .get()

      const plan = response.data()
      if (plan) context.commit(Mutations.setHiringPlan, { ...plan, id: response.id })
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  },
  async fetchHiringRequests(context, { hiringPlanId }) {
    context.commit(Mutations.setIsLoading, true)

    try {
      const response = await firebase
        .firestore()
        .collection(hiringRequestsFirestoreCollection)
        .where('hiringPlanId', '==', hiringPlanId)
        .get()

      const requests = response.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id
      }))

      if (requests)
        context.commit(Mutations.setHiringRequests, {
          hiringPlanId,
          hiringRequests: requests
        })
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  },
  async createPlan(context, { boardId, name, description, budget, managers }) {
    context.commit(Mutations.setIsCreating, true)

    try {
      const response = await firebase.firestore().collection(headcountFirestoreCollection).add({
        boardId,
        name,
        description,
        budget,
        managers,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })

      const plan = await response.get()
      context.commit(Mutations.setHeadcountPlan, {
        ...plan.data(),
        id: plan.id
      })
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsCreating, false)
    }
  },
  async updatePlan(context, { headcountPlanId, data }) {
    context.commit(Mutations.setIsCreating, true)

    try {
      await firebase
        .firestore()
        .collection(headcountFirestoreCollection)
        .doc(headcountPlanId)
        .update({
          ...data
        })

      context.commit(Mutations.updateHeadcountPlan, {
        ...data
      })
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsCreating, false)
    }
  },
  async updateHiringRequest(context, { id, hiringPlanId, status }) {
    context.commit(Mutations.setIsLoading, true)

    try {
      await firebase
        .firestore()
        .collection(hiringRequestsFirestoreCollection)
        .doc(id)
        .update({ status })

      context.commit(Mutations.updateHiringRequest, {
        id,
        hiringPlanId,
        status
      })
    } catch (error) {
      console.error(error)
    } finally {
      context.commit(Mutations.setIsLoading, false)
    }
  }
}

const getters = {
  isLoading: (_state) => _state.isLoading,
  headcountPlan: (_state) => _state.headcountPlan,
  headcountBudget: (_state) => Number(_state.headcountPlan?.budget),
  hiringPlans: (_state) => _state.hiringPlans,
  hiringPlanById: (_state) => (id) => {
    return _state.hiringPlans.find((plan) => plan.id === id)
  },

  hiringRequestsForPlan: (_state) => (hiringPlanId) =>
    _state.hiringRequestsByPlan[hiringPlanId] || [],

  hiringRequestsCost:
    (_state, _getters) =>
    (hiringPlanId, statusToFilterOut = [HiringRequestStatus.Draft]) =>
      _getters
        .hiringRequestsForPlan(hiringPlanId)
        .filter((request) => !statusToFilterOut.includes(request.status))
        .reduce(
          (acc, request) => acc + Math.max(Number(request.maxSalary), Number(request.minSalary)),
          0
        ),

  hiringRequests: (_state) => {
    const requests = []

    Object.keys(_state.hiringRequestsByPlan).forEach((planId) => {
      const hreq = _state.hiringRequestsByPlan[planId]
      hreq.forEach((req) => requests.push(req))
    }, [])

    return requests
  },

  invitedManagers: (_state) => {
    const managers = []

    _state.headcountPlan?.managers.forEach((manager) => {
      managers.push(manager)
    })

    return managers
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {}
}
