const nameSymbols = [
  {
    name: 'Albania Lek - Lek',
    code: 'ALL',
    symbol: 'Lek'
  },
  {
    name: 'Afghanistan Afghani - ؋',
    code: 'AFN',
    symbol: '؋'
  },
  {
    name: 'Argentina Peso - $',
    code: 'ARS',
    symbol: '$'
  },
  {
    name: 'Aruba Guilder - ƒ',
    code: 'AWG',
    symbol: 'ƒ'
  },
  {
    name: 'Australia Dollar - $',
    code: 'AUD',
    symbol: '$'
  },
  {
    name: 'Azerbaijan New Manat - ман',
    code: 'AZN',
    symbol: 'ман'
  },
  {
    name: 'Bahamas Dollar - $',
    code: 'BSD',
    symbol: '$'
  },
  {
    name: 'Barbados Dollar - $',
    code: 'BBD',
    symbol: '$'
  },
  {
    name: 'Belize Dollar - BZ$',
    code: 'BZD',
    symbol: 'BZ$'
  },
  {
    name: 'Bermuda Dollar - $',
    code: 'BMD',
    symbol: '$'
  },
  {
    name: 'Bolivia Boliviano - $b',
    code: 'BOB',
    symbol: '$b'
  },
  {
    name: 'Bosnia and Herzegovina Convertible Marka - KM',
    code: 'BAM',
    symbol: 'KM'
  },
  {
    name: 'Botswana Pula - P',
    code: 'BWP',
    symbol: 'P'
  },
  {
    name: 'Bulgaria Lev - лв',
    code: 'BGN',
    symbol: 'лв'
  },
  {
    name: 'Brazil Real - R$',
    code: 'BRL',
    symbol: 'R$'
  },
  {
    name: 'Brunei Darussalam Dollar - $',
    code: 'BND',
    symbol: '$'
  },
  {
    name: 'Cambodia Riel - ៛',
    code: 'KHR',
    symbol: '៛'
  },
  {
    name: 'Canada Dollar - $',
    code: 'CAD',
    symbol: '$'
  },
  {
    name: 'Cayman Islands Dollar - $',
    code: 'KYD',
    symbol: '$'
  },
  {
    name: 'Chile Peso - $',
    code: 'CLP',
    symbol: '$'
  },
  {
    name: 'China Yuan Renminbi - ¥',
    code: 'CNY',
    symbol: '¥'
  },
  {
    name: 'Colombia Peso - $',
    code: 'COP',
    symbol: '$'
  },
  {
    name: 'Costa Rica Colon - ₡',
    code: 'CRC',
    symbol: '₡'
  },
  {
    name: 'Croatia Kuna - kn',
    code: 'HRK',
    symbol: 'kn'
  },
  {
    name: 'Cuba Peso - ₱',
    code: 'CUP',
    symbol: '₱'
  },
  {
    name: 'Czech Republic Koruna - Kč',
    code: 'CZK',
    symbol: 'Kč'
  },
  {
    name: 'Denmark Krone - kr',
    code: 'DKK',
    symbol: 'kr'
  },
  {
    name: 'Dominican Republic Peso - RD$',
    code: 'DOP',
    symbol: 'RD$'
  },
  {
    name: 'East Caribbean Dollar - $',
    code: 'XCD',
    symbol: '$'
  },
  {
    name: 'Egypt Pound - £',
    code: 'EGP',
    symbol: '£'
  },
  {
    name: 'El Salvador Colon - $',
    code: 'SVC',
    symbol: '$'
  },
  {
    name: 'Euro Member Countries - €',
    code: 'EUR',
    symbol: '€'
  },
  {
    name: 'Falkland Islands (Malvinas) Pound - £',
    code: 'FKP',
    symbol: '£'
  },
  {
    name: 'Fiji Dollar - $',
    code: 'FJD',
    symbol: '$'
  },
  {
    name: 'Gibraltar Pound - £',
    code: 'GIP',
    symbol: '£'
  },
  {
    name: 'Guatemala Quetzal - Q',
    code: 'GTQ',
    symbol: 'Q'
  },
  {
    name: 'Guernsey Pound - £',
    code: 'GGP',
    symbol: '£'
  },
  {
    name: 'Guyana Dollar - $',
    code: 'GYD',
    symbol: '$'
  },
  {
    name: 'Honduras Lempira - L',
    code: 'HNL',
    symbol: 'L'
  },
  {
    name: 'Hong Kong Dollar - $',
    code: 'HKD',
    symbol: '$'
  },
  {
    name: 'Hungary Forint - Ft',
    code: 'HUF',
    symbol: 'Ft'
  },
  {
    name: 'Iceland Krona - kr',
    code: 'ISK',
    symbol: 'kr'
  },
  {
    name: 'India Rupee - ',
    code: 'INR',
    symbol: ''
  },
  {
    name: 'Indonesia Rupiah - Rp',
    code: 'IDR',
    symbol: 'Rp'
  },
  {
    name: 'Iran Rial - ﷼',
    code: 'IRR',
    symbol: '﷼'
  },
  {
    name: 'Isle of Man Pound - £',
    code: 'IMP',
    symbol: '£'
  },
  {
    name: 'Israel Shekel - ₪',
    code: 'ILS',
    symbol: '₪'
  },
  {
    name: 'Jamaica Dollar - J$',
    code: 'JMD',
    symbol: 'J$'
  },
  {
    name: 'Japan Yen - ¥',
    code: 'JPY',
    symbol: '¥'
  },
  {
    name: 'Jersey Pound - £',
    code: 'JEP',
    symbol: '£'
  },
  {
    name: 'Kazakhstan Tenge - лв',
    code: 'KZT',
    symbol: 'лв'
  },
  {
    name: 'Korea (North) Won - ₩',
    code: 'KPW',
    symbol: '₩'
  },
  {
    name: 'Korea (South) Won - ₩',
    code: 'KRW',
    symbol: '₩'
  },
  {
    name: 'Kyrgyzstan Som - лв',
    code: 'KGS',
    symbol: 'лв'
  },
  {
    name: 'Laos Kip - ₭',
    code: 'LAK',
    symbol: '₭'
  },
  {
    name: 'Lebanon Pound - £',
    code: 'LBP',
    symbol: '£'
  },
  {
    name: 'Liberia Dollar - $',
    code: 'LRD',
    symbol: '$'
  },
  {
    name: 'Macedonia Denar - ден',
    code: 'MKD',
    symbol: 'ден'
  },
  {
    name: 'Malaysia Ringgit - RM',
    code: 'MYR',
    symbol: 'RM'
  },
  {
    name: 'Mauritius Rupee - ₨',
    code: 'MUR',
    symbol: '₨'
  },
  {
    name: 'Mexico Peso - $',
    code: 'MXN',
    symbol: '$'
  },
  {
    name: 'Mongolia Tughrik - ₮',
    code: 'MNT',
    symbol: '₮'
  },
  {
    name: 'Mozambique Metical - MT',
    code: 'MZN',
    symbol: 'MT'
  },
  {
    name: 'Namibia Dollar - $',
    code: 'NAD',
    symbol: '$'
  },
  {
    name: 'Nepal Rupee - ₨',
    code: 'NPR',
    symbol: '₨'
  },
  {
    name: 'Netherlands Antilles Guilder - ƒ',
    code: 'ANG',
    symbol: 'ƒ'
  },
  {
    name: 'New Zealand Dollar - $',
    code: 'NZD',
    symbol: '$'
  },
  {
    name: 'Nicaragua Cordoba - C$',
    code: 'NIO',
    symbol: 'C$'
  },
  {
    name: 'Nigeria Naira - ₦',
    code: 'NGN',
    symbol: '₦'
  },
  {
    name: 'Korea (North) Won - ₩',
    code: 'KPW',
    symbol: '₩'
  },
  {
    name: 'Norway Krone - kr',
    code: 'NOK',
    symbol: 'kr'
  },
  {
    name: 'Oman Rial - ﷼',
    code: 'OMR',
    symbol: '﷼'
  },
  {
    name: 'Pakistan Rupee - ₨',
    code: 'PKR',
    symbol: '₨'
  },
  {
    name: 'Panama Balboa - B/.',
    code: 'PAB',
    symbol: 'B/.'
  },
  {
    name: 'Paraguay Guarani - Gs',
    code: 'PYG',
    symbol: 'Gs'
  },
  {
    name: 'Peru Nuevo Sol - S/.',
    code: 'PEN',
    symbol: 'S/.'
  },
  {
    name: 'Philippines Peso - ₱',
    code: 'PHP',
    symbol: '₱'
  },
  {
    name: 'Poland Zloty - zł',
    code: 'PLN',
    symbol: 'zł'
  },
  {
    name: 'Qatar Riyal - ﷼',
    code: 'QAR',
    symbol: '﷼'
  },
  {
    name: 'Romania New Leu - lei',
    code: 'RON',
    symbol: 'lei'
  },
  {
    name: 'Russia Ruble - руб',
    code: 'RUB',
    symbol: 'руб'
  },
  {
    name: 'Saint Helena Pound - £',
    code: 'SHP',
    symbol: '£'
  },
  {
    name: 'Saudi Arabia Riyal - ﷼',
    code: 'SAR',
    symbol: '﷼'
  },
  {
    name: 'Serbia Dinar - Дин.',
    code: 'RSD',
    symbol: 'Дин.'
  },
  {
    name: 'Seychelles Rupee - ₨',
    code: 'SCR',
    symbol: '₨'
  },
  {
    name: 'Singapore Dollar - $',
    code: 'SGD',
    symbol: '$'
  },
  {
    name: 'Solomon Islands Dollar - $',
    code: 'SBD',
    symbol: '$'
  },
  {
    name: 'Somalia Shilling - S',
    code: 'SOS',
    symbol: 'S'
  },
  {
    name: 'South Africa Rand - R',
    code: 'ZAR',
    symbol: 'R'
  },
  {
    name: 'Korea (South) Won - ₩',
    code: 'KRW',
    symbol: '₩'
  },
  {
    name: 'Sri Lanka Rupee - ₨',
    code: 'LKR',
    symbol: '₨'
  },
  {
    name: 'Sweden Krona - kr',
    code: 'SEK',
    symbol: 'kr'
  },
  {
    name: 'Switzerland Franc - CHF',
    code: 'CHF',
    symbol: 'CHF'
  },
  {
    name: 'Suriname Dollar - $',
    code: 'SRD',
    symbol: '$'
  },
  {
    name: 'Syria Pound - £',
    code: 'SYP',
    symbol: '£'
  },
  {
    name: 'Taiwan New Dollar - NT$',
    code: 'TWD',
    symbol: 'NT$'
  },
  {
    name: 'Thailand Baht - ฿',
    code: 'THB',
    symbol: '฿'
  },
  {
    name: 'Trinidad and Tobago Dollar - TT$',
    code: 'TTD',
    symbol: 'TT$'
  },
  {
    name: 'Turkey Lira - ',
    code: 'TRY',
    symbol: ''
  },
  {
    name: 'Ukraine Hryvna - ₴',
    code: 'UAH',
    symbol: '₴'
  },
  {
    name: 'United Kingdom Pound - £',
    code: 'GBP',
    symbol: '£'
  },
  {
    name: 'United States Dollar - $',
    code: 'USD',
    selected: true,
    symbol: '$'
  },
  {
    name: 'Uruguay Peso - $U',
    code: 'UYU',
    symbol: '$U'
  },
  {
    name: 'Uzbekistan Som - лв',
    code: 'UZS',
    symbol: 'лв'
  },
  {
    name: 'Viet Nam Dong - ₫',
    code: 'VND',
    symbol: '₫'
  },
  {
    name: 'Yemen Rial - ﷼',
    code: 'YER',
    symbol: '﷼'
  }
]

export default nameSymbols
