<template>
  <div
    ref="widthSource"
    class="org-node group relative flex h-full max-w-[232px] flex-col rounded-xl bg-white shadow-md ring-1 ring-gray-300 transition-all duration-200 hover:ring-2 hover:ring-bluedark-300"
    :class="{
      'ring-3 hover:ring-3 shadow-xl': dragging === 'true',
      'shadow-3xl border border-dashed border-gray-600': draggingOver === 'true'
    }"
  >
    <div>
      <div class="w-full overflow-hidden">
        <div ref="heightSource" class="relative z-10 flex w-full cursor-grab flex-col gap-4 p-5">
          <div class="w-full">
            <div
              class="flex w-full flex-col items-start justify-center transition-colors duration-200"
            >
              <input
                v-model="name"
                class="z-10 w-full border-0 border-b-[1px] border-transparent px-0 pt-1 pb-0 text-base font-semibold outline-0 ring-0 placeholder:font-normal placeholder:text-gray-400 focus:border-b-[1px] focus:border-bluedark-500 focus:outline-0 focus:ring-0"
                type="text"
                placeholder="Enter goal name"
              />

              <div class="flex gap-x-2">
                <button
                  v-if="rolesInGoal?.length"
                  class="mt-2.5 mb-3 max-w-fit items-center truncate rounded-full bg-bluedark-50 px-2.5 py-1 text-sm font-medium text-bluedark-700 mix-blend-multiply transition-colors hover:bg-bluedark-100 active:bg-bluedark-200"
                  @click.stop="emit('toggle-collapse')"
                >
                  {{ `${rolesInGoal.length} role${rolesInGoal.length > 1 ? 's' : ''}` }} available
                </button>

                <button
                  class="mt-2.5 mb-3 max-w-fit items-center truncate rounded-full bg-bluedark-50 px-2.5 py-1 text-sm font-medium text-bluedark-700 mix-blend-multiply transition-colors hover:bg-bluedark-100 active:bg-bluedark-200"
                  @click.stop="handleAddNewRole"
                >
                  {{ rolesInGoal && rolesInGoal.length > 0 ? '' : 'Add a role' }} +
                </button>
              </div>
              <div
                v-if="hasOwner"
                class="mt-2 flex w-full items-center gap-3 rounded-lg bg-gray-100 p-2"
              >
                <div class="min-w-8 h-8 w-8 flex-shrink-0 rounded-full bg-gray-200">
                  <img
                    :src="owner.avatarImg"
                    :alt="owner.name"
                    class="h-full w-full rounded-full object-cover"
                    @error="
                      $event.target.src =
                        'https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/open_role.png?alt=media&token=5a831181-2239-482a-93e2-0e795a4af0e5'
                    "
                  />
                </div>
                <div class="flex flex-col truncate">
                  <span class="truncate text-sm font-medium">{{ owner.name }}</span>
                  <span class="truncate text-sm font-normal text-gray-500">{{ owner.role }}</span>
                </div>
              </div>
              <div
                v-else
                class="mt-2 flex w-full items-center justify-center gap-3 rounded-lg bg-gray-100 p-2"
              >
                <Popper content="Assign an owner" placement="top" hover>
                  <button
                    class="flex h-10 w-10 transform items-center justify-center rounded-full bg-white text-xl shadow-lg transition-all hover:scale-110 active:scale-125"
                    @click="handleAssignPerson"
                  >
                    +
                  </button>
                </Popper>
              </div>

              <button
                v-if="rolesInGoal && rolesInGoal.length > 0"
                class="mx-auto mt-2 flex items-center justify-center rounded-2xl bg-gray-800 py-1 px-2 text-xs text-white transition-all hover:bg-gray-600"
                @click.stop="emit('toggle-collapse')"
                @mousedown.stop
              >
                <ChevronDownIcon class="w-4 transition-all" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- overlay buttons -->
      <div
        class="absolute top-0 z-30 flex h-6 w-full translate-y-0 scale-0 justify-center opacity-0 transition-all group-hover:-translate-y-full group-hover:scale-100 group-hover:opacity-100"
        @click.stop
        @mousedown.stop
      >
        <div
          class="overlay-buttons absolute top-0 -translate-y-5 rounded-lg bg-white drop-shadow-lg transition-opacity"
          :class="{
            'opacity-0': dragging === 'true'
          }"
        >
          <Popper content="Remove goal" placement="top" hover class="top-10">
            <button
              type="button"
              class="items-center rounded-lg px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="emit('delete-user')"
            >
              <TrashIcon class="h-5 w-5" />
            </button>
          </Popper>
          <Popper content="Remove owner" placement="top" hover class="top-10">
            <button
              v-if="hasOwner"
              type="button"
              class="items-center rounded-lg px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="handleDeleteOwner"
            >
              <UserRemoveIcon class="h-5 w-5" />
            </button>
          </Popper>
          <!-- <Popper
            :content="`Duplicate (${getShortcutWithCmdOrCtrl('d')})`"
            placement="top"
            hover
            class="top-10"
          >
            <button
              type="button"
              class="items-center rounded-r-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="handleDuplicate"
            >
              <CopyIcon class="icon h-5 w-5" />
            </button>
          </Popper> -->
        </div>
      </div>
      <!-- <div
        class="absolute bottom-0 z-30 flex h-6 w-full translate-y-0 scale-0 justify-center opacity-0 transition-all group-hover:translate-y-full group-hover:scale-100 group-hover:opacity-100"
        @click.stop
        @mousedown.stop
      >
        <div
          class="overlay-buttons absolute top-5 -translate-y-2 overflow-hidden rounded-lg bg-white drop-shadow-lg transition-opacity"
          :class="{
            'opacity-0': dragging === 'true'
          }"
        >
          <Popper content="Add direct report" placement="top" hover>
            <button
              class="items-center bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              :class="{
                'opacity-0': dragging === 'true'
              }"
              @click="emit('add-user')"
              @click.stop
              @mousedown.stop
            >
              <UserPlus class="h-auto w-5" />
            </button>
          </Popper>
          <Popper
            :content="isReplaced ? 'Remove replacement' : 'Add replacement'"
            placement="top"
            hover
          >
            <button
              class="items-center rounded-r-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              :class="{
                'opacity-0': dragging === 'true'
              }"
              @click="emit('add-remove-replacement')"
              @click.stop
              @mousedown.stop
            >
              <ReplaceIcon class="h-auto w-5" />
            </button>
          </Popper>
        </div>
      </div> -->
      <!-- end buttons -->
    </div>
    <!-- <div
      class="absolute bottom-0 z-40 flex h-fit w-full justify-center transition-all"
      :class="{
        'translate-y-0 scale-0 opacity-0': !dragging || dragging === 'false',
        'translate-y-full scale-100 opacity-100': dragging === 'true'
      }"
      @click.stop
      @mousedown.stop
    >
      <div
        class="flex h-auto translate-y-2 rounded-lg bg-white px-3 py-2 font-medium text-gray-700 shadow-lg transition-all"
      >
        <p v-if="dragTarget && dragging === 'true'" class="text-xs">
          Drop here to set
          <span class="font-bold">{{ potentialManager }}</span>
          as manager
        </p>
        <p v-else>Drag over a card</p>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import TrashIcon from '@/assets/SvgIcons/TrashIcon.svg'
import useOrgNodeHelpers from '@/hooks/use-org-node-helpers.js'
import usePeopleBrowser from '@/hooks/use-people-browser'
import store from '@/store/index.js'
import { ChevronDownIcon, UserRemoveIcon } from '@heroicons/vue/outline'
import {
  useDebounceFn,
  useElementHover,
  useElementSize,
  useMagicKeys,
  useResizeObserver
} from '@vueuse/core'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import Popper from 'vue3-popper'

const props = defineProps({
  personId: {
    type: String,
    required: true
  },
  boardId: {
    type: String,
    required: true
  },
  dragging: {
    type: String,
    default: 'false'
  },
  dragTarget: {
    type: String,
    default: ''
  },
  draggingOver: {
    type: String,
    default: ''
  },
  enableHover: {
    type: String,
    default: 'true'
  }
})

const emit = defineEmits([
  'toggle-menu',
  'toggle-collapse',
  'add-user',
  'delete-user',
  'paste-user',
  'duplicate-user',
  'new-role'
])

const widthSource = ref(null)
const heightSource = ref(null)
const pulseAnimation = ref(false)
const updatedName = ref(null)

const { updateWidth, updateHeight, setHovered, resetHovered } = useOrgNodeHelpers()
const { getPersonFromBrowser } = usePeopleBrowser(props.boardId)
const isHovered = useElementHover(widthSource)

useMagicKeys({
  passive: false,
  onEventFired(e) {
    if ((e.metaKey || e.ctrlKey) && e.key === 'v' && e.type === 'keydown' && isHovered.value) {
      e.preventDefault()
      handlePaste()
    }

    if ((e.metaKey || e.ctrlKey) && e.key === 'd' && e.type === 'keydown' && isHovered.value) {
      window.mixpanel.track('node_keyboard_duplicate')
      e.preventDefault()
      handleDuplicate()
    }
  }
})

onMounted(() => {
  const { width } = useElementSize(widthSource)

  useResizeObserver(heightSource, (entries) => {
    const entry = entries[0]
    updateHeight({ id: props.personId, height: entry?.target?.clientHeight })
  })

  watch(width, () => {
    updateWidth({ id: props.personId, width: width.value })
  })

  watch(isHovered, () => {
    if (isHovered.value) setHovered(props.personId)
    else resetHovered(props.personId)
  })
})

const person = computed(() =>
  store.getters['people/personById']({ personId: props.personId, boardId: props.boardId })
)

const name = computed({
  get: () => person.value?.rolePlanning?.name,
  set: (value) => {
    updatedName.value = value
    updateName()
  }
})
const hasOwner = computed(() => person.value?.rolePlanning?.ownerId)
const owner = computed(() =>
  store.getters['people/personById']({
    personId: person.value?.rolePlanning?.ownerId,
    boardId: props.boardId
  })
)
const rolesInGoal = computed(() =>
  store.getters['people/rolesInGoal']({ boardId: props.boardId, goalId: props.personId })
)

const updateName = useDebounceFn(() => {
  store.dispatch('people/patchPerson', {
    personId: props.personId,
    boardId: props.boardId,
    patch: {
      rolePlanning: {
        name: updatedName.value
      }
    }
  })
}, 700)

let pulseAnimationSwitch = null
const triggerPulseAnimation = () => {
  if (pulseAnimationSwitch) {
    clearTimeout(pulseAnimationSwitch)
    pulseAnimation.value = false
  }

  nextTick(() => {
    pulseAnimation.value = true
    pulseAnimationSwitch = setTimeout(() => {
      pulseAnimation.value = false
    }, 1000)
  })
}

const handlePaste = () => {
  if (!isHovered.value) return
  emit('paste-user')
  triggerPulseAnimation()
}

const handleDuplicate = () => {
  emit('duplicate-user')
}

const handleAddNewRole = async () => {
  emit('new-role')
}

const handleAssignPerson = async () => {
  const selectedPersonId = await getPersonFromBrowser()

  if (selectedPersonId === undefined) return

  store.dispatch('people/patchPerson', {
    personId: props.personId,
    boardId: props.boardId,
    patch: {
      rolePlanning: {
        ownerId: selectedPersonId
      }
    }
  })
}

const handleDeleteOwner = () => {
  store.dispatch('people/patchPerson', {
    personId: props.personId,
    boardId: props.boardId,
    patch: {
      rolePlanning: {
        ownerId: null
      }
    }
  })
}
</script>

<style scoped>
@import url('../index.css');

.org-node {
  font-family: 'Inter';
}

.dragging-over {
  @apply scale-110 shadow-lg shadow-bluedark-100;
}

.color-icon svg :deep(path) {
  stroke: v-bind(iconColor);
}

.white-icon :deep(path) {
  stroke: white;
}

.overlay-buttons svg :deep(path) {
  @apply stroke-gray-900;
}

:deep(.popper) {
  @apply pointer-events-none cursor-pointer whitespace-nowrap rounded-lg bg-black px-3 py-2 text-xs text-white shadow-lg !important;
}

.pulseAnimation {
  @apply animate-[pulse_0.3s_ease-in-out];
}
</style>
