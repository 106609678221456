export default {
  Draft: 'Draft',
  ToReview: 'To review',
  Approved: 'Approved',
  Declined: 'Declined',
  Hold: 'Hold',
  NeedsEditing: 'Needs editing',
  Filled: 'Filled',
  Offer: 'Offer'
}
