import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'

const getDefaultStates = () => {
  return {
    plannedChangesChange: [],
    plannedChangesDepartment: {},
    plannedChangesTime: {}
  }
}

const state = getDefaultStates()

const mutations = {
  setPlannedChangesChange(_state, data) {
    _state.plannedChangesChange = data
  },
  setPlannedChangesDepartment(_state, data) {
    _state.plannedChangesDepartment = data
  },
  setPlannedChangesTime(_state, data) {
    _state.plannedChangesTime = data
  }
}

const getters = {
  getPlannedChangesChange: (_state) => _state.plannedChangesChange,
  getPlannedChangesDepartment: (_state) => _state.plannedChangesDepartment,
  getPlannedChangesTime: (_state) => _state.plannedChangesTime
}

const actions = {
  async fetchPlannedChanges(context, boardId) {
    // Reset the state before fetch starts
    const defaultState = getDefaultStates()
    context.commit('setPlannedChangesChange', defaultState.plannedChangesChange)
    context.commit('setPlannedChangesDepartment', defaultState.plannedChangesDepartment)
    context.commit('setPlannedChangesTime', defaultState.plannedChangesTime)

    try {
      const data = firebase.functions().httpsCallable('plannedChanges-fetchImpact')
      const result = await data({ boardId })
      context.commit('setPlannedChangesChange', result.data.change)
      context.commit('setPlannedChangesDepartment', result.data.department)
      context.commit('setPlannedChangesTime', result.data.time)
    } catch (e) {
      console.log(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
